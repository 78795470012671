import { mergeAttributes, Node, ReactNodeViewRenderer } from '@tiptap/react';
import { AiWriterView } from './AiWriterView';


export const AiWriter = Node.create({
    name: 'aiWriter',

    group: 'block',

    draggable: true,

    addOptions() {
        return {
          HTMLAttributes: {
            class: `node-${this.name}`,
          },
        }
    },

    addAttributes() {
        return {
          id: {
            default: undefined,
            parseHTML: element => element.getAttribute('data-id'),
            renderHTML: attributes => ({
              'data-id': attributes.id,
            }),
          },
        }
    },

    parseHTML() {
        return [
          {
            tag: `div.node-${this.name}`,
          },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
    },

    addCommands() {
        return {
          setAiWriter:
            () =>
            ({ chain }) =>
              chain().focus().insertContent({ type: this.name, }).run(),
        }
    },

    addNodeView() {
        return ReactNodeViewRenderer(AiWriterView)
    },
    
});