import { Node } from '@tiptap/core';
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import { useEffect, useState } from 'react';

const extractHeadings = (doc) => {
  const headings = [];
  doc.descendants((node, pos) => {
    if (node.type.name.startsWith('heading')) {
      headings.push({
        id: node.attrs.id || `heading-${pos}`, // Ensure each heading has a unique ID
        level: node.attrs.level,
        textContent: node.textContent,
        itemIndex: headings.length + 1,
      });
    }
  });
  return headings;
};

export const TableOfContents = ({ editor, onItemClick }) => {
  const [data, setData] = useState({content: []});

  useEffect(() => {
    const updateTOC = () => {
      const headings = extractHeadings(editor.state.doc);
      setData({ content: headings });
    };

    // Initial extraction
    updateTOC();

    editor.on('update', updateTOC);
    editor.on('selectionUpdate', updateTOC);

    return () => {
      editor.off('update', updateTOC);
      editor.off('selectionUpdate', updateTOC);
    };
  }, [editor]);

  return (
    <>
      <div className="TableOfContentsTitle">
        Table of contents
      </div>
      {(data && data.content.length > 0) ? (
        <div className="TableOfContentsCover">
          {data.content.map(item => (
            <a
              key={item.id}
              href={`#${item.id}`}
              style={{ marginLeft: `${(item.level - 1) * 1}rem` }}
              onClick={onItemClick}
              className={`TableOfContentsItem`}              
            >
              {item.itemIndex}. {item.textContent}
            </a>
          ))}
        </div>
      ) : (
        <div className="TableOfContentsNoData">Start adding headlines to your document …</div>
      )}
    </>
  );
};

const TableOfNodeContent = (props) => {
  const { editor } = props;

  return (
    <NodeViewWrapper>
      <div className="TableOfContentsWrapper" contentEditable={false}>
        <TableOfContents editor={editor} />
      </div>
    </NodeViewWrapper>
  );
};

export const TableOfContentsNode = Node.create({
  name: 'tableOfContentsNode',
  group: 'block',
  atom: true,
  selectable: true,
  draggable: true,
  inline: false,

  parseHTML() {
    return [
      {
        tag: 'div[data-type="table-of-content"]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      {
        ...HTMLAttributes,
        'data-type': 'table-of-content',
        class: 'table-of-contents', // Class for easier identification
      },
    ];
  },

  addCommands() {
    return {
      insertTableOfContents:
        () =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
          });
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(TableOfNodeContent);
  },
});
