import React, { useCallback } from 'react';
import { BubbleMenu as BaseBubbleMenu } from '@tiptap/react';
import isRowGripSelected from './utils';

import icons from '../../../../iconsList.json';


const TableRowMenu = ({ editor, appendTo }) => {
  const shouldShow = useCallback(
    ({ view, state, from }) => {
      if (!state || !from) return false;
      return isRowGripSelected({ editor, view, state, from });
    },
    [editor]
  );

  const onAddRowBefore = useCallback(() => {
    editor.chain().focus().addRowBefore().run();
  }, [editor]);

  const onAddRowAfter = useCallback(() => {
    editor.chain().focus().addRowAfter().run();
  }, [editor]);

  const onDeleteRow = useCallback(() => {
    editor.chain().focus().deleteRow().run();
  }, [editor]);

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey="tableRowMenu"
      updateDelay={0}
      tippyOptions={{
        appendTo: () => appendTo?.current,
        placement: 'left',
        offset: [0, 15],
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
      }}
      shouldShow={shouldShow}
    >
        <div className='tablerow-menu'>
            <button className='tablerow-menu-item' onClick={onAddRowBefore}>
                <div dangerouslySetInnerHTML={{ __html: icons['ArrowUpToLine'] }} />
                Add row before
            </button>

            <button className='tablerow-menu-item' onClick={onAddRowAfter}>
                <div dangerouslySetInnerHTML={{ __html: icons['ArrowDownToLine'] }} />
                Add row after
            </button>

            <button className='tablerow-menu-item' onClick={onDeleteRow}>
                <div dangerouslySetInnerHTML={{ __html: icons['Trash'] }} />
                Delete row
            </button>
        </div>
    </BaseBubbleMenu>
  );
};

export default TableRowMenu;
