import { useEffect, useState } from 'react';
import '.././App.css';
import image from '.././images/notify_img.png'
import copy from '.././images/copy.svg'
import facebook from '.././images/Fb-i.svg'
import twitter from '.././images/X-icon.svg'
import linkedin from '.././images/Linkdin-i.svg'
import whatsapp from '.././images/whatsApp-i.svg'

import { Link } from 'react-router-dom';
import { useRef } from 'react';

function PublishedNotify(props) {

    const ref = useRef();
    const baseUrl = `https://jourmal-next-front.vercel.app/${props.profileSlug}/`;
    const [urlInput, setURLInput] = useState(`${baseUrl}${props.url || props.dbPageTitle}`)

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (props.openPublishPopup && ref.current && !ref.current.contains(e.target)) {
                props.setOpenPublishPopup(false);

            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [props.openPublishPopup]);

    useEffect(() => {
        // Dynamically update the Open Graph meta tags
        const metaTags = [
          { property: "og:title", content: props?.dbPageTitle || "Publication is Live" },
          { property: "og:description", content: props?.description || "Check out this publication!" },
          { property: "og:image", content: props?.featureImage || image },
          { property: "og:url", content: urlInput },
        ];
    
        metaTags.forEach(({ property, content }) => {
          let metaTag = document.querySelector(`meta[property='${property}']`);
          if (!metaTag) {
            metaTag = document.createElement("meta");
            metaTag.setAttribute("property", property);
            document.head.appendChild(metaTag);
          }
          metaTag.setAttribute("content", content);
        });
      }, [urlInput, props.title, props.description, props.featureImage]);

    const [copyText, setCopyText] = useState('Copy')

    
    const handleKeyPress = (e) =>{
        if (e?.key === "Enter") {
            props.setOpenPublishPopup(false)
        }
    }

    const handleFacebookShare = () => {
        const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(urlInput)}`;
        window.open(facebookShareUrl, '_blank');
    }

    const handleTwitterShare = () => {
        const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(urlInput)}`;
        window.open(twitterShareUrl, "_blank");
    };

    const handleLinkedInShare = () => {
        const linkedInShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(urlInput)}`;
        window.open(linkedInShareUrl, "_blank");
    };

    const handleWhatsAppShare = () => {
        const whatsappShareUrl = `https://wa.me/?text=${encodeURIComponent(urlInput)}`;
        window.open(whatsappShareUrl, "_blank");
    };

    return (
        <div className="pubPopupWrapper" >
            <div className={'pubPopup'} ref={ref}>
                <img className='publication-img' src={props?.featureImage || image} alt="Popup Image" />
                <p className="pubPopupTitle">Publication is live!</p>
                <p className="pubPopupDesc">Your publication has been published.</p>

                <div className="shareLink">
                    <p>Share link</p>

                    <div>
                        <input type="text" value={urlInput} onChange={e => setURLInput(e.target.value)} onKeyPress={(e) =>{handleKeyPress(e)}}/>
                        <div className='shareLinkCopyBlock'>
                            <img className='shareLinkCopy' src={copy} alt="Link" onClick={e => {
                                setCopyText('URL Copied!')
                                navigator.clipboard.writeText(urlInput)
                            }} onMouseEnter={e => setCopyText('Copy')} />
                            <button className='infoTooltip '>{copyText}</button>
                        </div>
                    </div>
                </div>

                <div className="shareLink">
                    <p>Share on</p>
                    <div className='shareIcons'>
                        <img src={facebook} alt="Icon" onClick={handleFacebookShare} />
                        <img src={twitter} alt="Icon" onClick={handleTwitterShare} />
                        <img src={linkedin} alt="Icon" onClick={handleLinkedInShare} />
                        <img src={whatsapp} alt="Icon" onClick={handleWhatsAppShare} />
                    </div>
                </div>

                <div className="shareLinkButtons">
                    <Link to={urlInput} target="_blank" className='openPublicationBtn'>
                        <button>Open publication</button>
                    </Link>
                    <button className='blueButton' onClick={e => props.setOpenPublishPopup(false)}>Finish</button>
                </div>
            </div>
        </div>
    );
}

export default PublishedNotify;
