import axios from "axios";
import { useState } from "react";

function DomainDeleteModel({setShowPopup, setDomainName, setDomainVerifictionToken, setDomainVerified, setDomainAdded}) { 
    const [password, setPassword] = useState("");
    const [validatePassword, setValidatePassword] = useState(false);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(isLoading) {
            return; 
        }

        try {
            if (password.length > 0) {
                setIsLoading(true);
                await axios.post(
                    `${process.env.REACT_APP_API_URL}/user/removedomain/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
                    { password: password }
                );                
                setShowPopup(false);
                
                setDomainName('');
                setDomainVerifictionToken('');
                setDomainVerified(false);
                setDomainAdded(false);
            } else {
                setValidatePassword(true)
            }
            setIsLoading(false);
            setPassword("");
        } catch (error) {
            setIsLoading(false);
            if (error.response && error.response.status === 400) {
                setError(true);
                setErrorMessage('Passwords do not match')
            } else {
                console.log(error, "error");
                console.log({error})
                setError(true);
                setErrorMessage(error.response.data.message)
            }
            return false;
        }
    }
    
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        if (e.target.value.length > 0) {
            setValidatePassword(false);
            setError(false);
        } else {
            setValidatePassword(true);
        }
    };
    
    return (
        <form onSubmit={handleSubmit}>
            <div className='welcomeModal domainDeleteModal'>
                <h3 className='welcomeTitle'>Delete Domain</h3>

                <p className='welcomeText'>
                    Are you sure you want to remove your custom domain? Removing your custom domain will:
                </p>
                <ul>
                    <li>Traffic to your custom domain will be redirected to your default site URL.</li>
                    <li>Any established SEO rankings linked to this domain may be affected.</li>
                    <li>Existing links to this domain will no longer function directly.</li>
                </ul>
                <p className='welcomeText' style={{ marginBottom: 0 }}>
                    If you proceed, your domain setup will be cleared from our system. You can reconfigure it anytime by adding the domain again.
                </p>

                <div className='welcomeForm'>
                    <label className='welcomeLabel'>Password</label>
                    <input
                        type="password"
                        name="password"
                        placeholder="Enter your password"
                        className="welcomeInput"
                        autoFocus={true}
                        value={password}
                        onChange={handlePasswordChange} />
                    {validatePassword === true && (
                        <p className="validateError">The field is not filled</p>
                    )}
                    {error === true && (
                        <p className="validateError">{errorMessage}</p>
                    )}
                </div>

                <div className="welcomeModalbtn">
                    <button className="cancel" type="button" onClick={() => setShowPopup(false)}>Cancel</button>
                    <button className="savePicture" type="submit">
                        {isLoading ?
                            <div className="settingPage-loader">
                                <div className="ring-loader"></div>
                            </div>
                            : "Delete"}
                    </button>
                </div>
            </div>
        </form>
    )
}

export default DomainDeleteModel