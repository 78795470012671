import { mergeAttributes } from '@tiptap/core';
import TiptapHeading from '@tiptap/extension-heading';

export const Heading = TiptapHeading.extend({
  renderHTML({ node, HTMLAttributes }) {
    // Convert the node attribute 'level' to a number
    const nodeLevel = parseInt(node.attrs.level, 10);
    // Check if the converted level is included in the allowed levels
    const hasLevel = this.options.levels.includes(nodeLevel);
    // If the level is valid, use it; otherwise, default to the first level in options
    const level = hasLevel ? nodeLevel : this.options.levels[0];

    // Return the HTML structure for the heading
    return [`h${level}`, mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },
});

export default Heading;
