import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { HeroSection } from './HeroSection'
import { PublicationsSection } from './PublicationsSection'
import { PreviewHeader } from '../PreviewHeader/PreviewHeader'
import { PreviewFooter } from '../PreviewFooter/PreviewFooter'
import Error from '../Error'
import './previewHome.css'
export const PreviewHome = (props) => {
    props.setActivePage("Preview Home");
    const { id } = useParams();
    const [publicationData, setPublicationData] = useState([])
    const [heroData, setHeroData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [status, setStatus] = useState("")
    const user_id = JSON.parse(localStorage.getItem("userinfo"))?._id
    const fetchData = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/front/publications/${localStorage.getItem("userProfileSlug")}`,
            )
            const responseHeroData = await axios.get(
                `${process.env.REACT_APP_API_URL}/front/homeherodata/${localStorage.getItem("userProfileSlug")}?preview=true`,
            )
            const result = response?.data
            setPublicationData(result)
            setHeroData(responseHeroData?.data)
        } catch (error) {
            console.log(error, "error");
        }
    }
    useEffect(() => {
        fetchData()
    }, [])
    const handleSend = async () => {
        if (heroData?.feature_image !== "") {
            const updatedData = { ...heroData };
            setIsLoading(true);
            try {
                const res = await axios.post(
                    `${process.env.REACT_APP_API_URL}/user/updateHomepage/${user_id}`,
                    updatedData,
                )

                if (res.status === 200) {
                    setIsLoading(false);
                    setStatus('Published')
                }
            } catch (error) {
                console.error("Failed to send data:", error);
                setIsLoading(false);
            }
        }
    };

    const handleBack = () => {
        window.close();
    };
    return (
        <>
            {
                id === user_id ?
                    <>
                        <div className={`preview-newsletter__header ${props?.userMetaData?.theme === "light" ? 'light-view' : 'dark-view'} ${props?.userMetaData?.theme}`}>
                            <p>You are currently viewing a preview of your home page.</p>
                            <div className={`preview-buttons`}>
                                <div className={`newpageRightButtons newpageRightButtonsPreview ${props?.userMetaData?.theme === "light" ? 'light-newpageRightButtons' : 'dark-newpageRightButtons'}`} onClick={() => {
                                    handleSend()
                                }}>

                                    {
                                        isLoading ?
                                            <div className="updatebutton-loader publish-loader">
                                                <div className="ring-loader"></div>
                                            </div> :
                                            <button>{props?.userMetaData?.theme === 'light' ? <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.86659 14C9.34773 14 9.68854 13.5857 9.9358 12.9442L14.3129 1.51026C14.4332 1.20286 14.5 0.928878 14.5 0.701671C14.5 0.267303 14.2327 0 13.7983 0C13.5711 0 13.2971 0.0668258 12.9897 0.187112L1.4957 4.59093C0.934368 4.80477 0.5 5.14558 0.5 5.63341C0.5 6.24821 0.96778 6.45537 1.60931 6.64916L5.2179 7.74511C5.64558 7.87876 5.88616 7.86539 6.17351 7.59809L13.5043 0.748449C13.5912 0.668258 13.6914 0.681623 13.7582 0.741766C13.8251 0.808592 13.8317 0.908831 13.7516 0.995704L6.92864 8.35322C6.66802 8.62721 6.64797 8.85442 6.77494 9.30215L7.83747 12.8305C8.03795 13.5055 8.24511 14 8.86659 14Z" fill="white" />
                                            </svg> : <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.86659 14C9.34773 14 9.68854 13.5857 9.9358 12.9442L14.3129 1.51026C14.4332 1.20286 14.5 0.928878 14.5 0.701671C14.5 0.267303 14.2327 0 13.7983 0C13.5711 0 13.2971 0.0668258 12.9897 0.187112L1.4957 4.59093C0.934368 4.80477 0.5 5.14558 0.5 5.63341C0.5 6.24821 0.96778 6.45537 1.60931 6.64916L5.2179 7.74511C5.64558 7.87876 5.88616 7.86539 6.17351 7.59809L13.5043 0.748449C13.5912 0.668258 13.6914 0.681623 13.7582 0.741766C13.8251 0.808592 13.8317 0.908831 13.7516 0.995704L6.92864 8.35322C6.66802 8.62721 6.64797 8.85442 6.77494 9.30215L7.83747 12.8305C8.03795 13.5055 8.24511 14 8.86659 14Z" fill="black" />
                                            </svg>}Publish</button>
                                    }

                                </div>
                                <div className="preview-edit">
                                    <button onClick={() => { handleBack() }}>
                                        Back to edit
                                    </button>
                                </div>
                            </div>
                        </div>
                        <PreviewHeader profilePicture={heroData?.profilePictureUrl} displayName={heroData?.displayName} theme={props?.userMetaData?.theme} />
                        <main className={`home main home ${props?.userMetaData?.theme}`}>
                            <div id="hero-and-body">
                                <div className={"section"}>
                                    <HeroSection heroData={heroData} theme={props?.userMetaData?.theme} />
                                </div>

                                <div className='publication-bg-color'>
                                    <PublicationsSection publicationsData={publicationData?.data} profile={props?.userMetaData?.profileSlug} coverimage={publicationData?.coverImage} hasCustomDomain={props?.userMetaData?.customDomain} />
                                </div>
                            </div>
                        </main>
                        <PreviewFooter profilePicture={heroData?.profilePictureUrl} displayName={heroData?.displayName} theme={props?.userMetaData?.theme} />
                    </>
                    :
                    <Error menuDisabled={true} />
            }
        </>
    )
}
