// /src/components/ButtonBubbleMenu.js
import React, { useState, useEffect, useCallback } from 'react';
import { BubbleMenu } from '@tiptap/react';
import { sticky } from 'tippy.js'

const getRenderContainer = (editor, nodeType) => {
    const {
      view,
      state: {
        selection: { from },
      },
    } = editor;
  
    const elements = document.querySelectorAll('.has-focus');
    const elementCount = elements.length;
    const innermostNode = elements[elementCount - 1];
    const element = innermostNode;
  
    if (
      (element && element.getAttribute('data-type') && element.getAttribute('data-type') === nodeType) ||
      (element && element.classList && element.classList.contains(nodeType))
    ) {
      return element;
    }
  
    const node = view.domAtPos(from).node;
    let container = node;
  
    if (!container.tagName) {
      container = node.parentElement;
    }
  
    while (
      container &&
      !(container.getAttribute('data-type') && container.getAttribute('data-type') === nodeType) &&
      !container.classList.contains(nodeType)
    ) {
      container = container.parentElement;
    }
  
    return container;
};

const ColumnBubbleMenu = ({ editor, appendTo }) => {

    const getReferenceClientRect = () => {
        const renderContainer = getRenderContainer(editor, 'columns');
        const rect = renderContainer?.getBoundingClientRect() || new DOMRect(-1000, -1000, 0, 0);
        return rect;
    };

    const [editAttributes, setEditAttributes] = useState({
        layout: 'two-column',
    });

    const shouldShow = useCallback(() => {
        return editor.isActive('columns');
    }, [editor]);

    const handleSidebarLeftClick = () => {
        editor.commands.setLayout('sidebar-left');
    };
    const handleSidebarRightClick = () => {
        editor.commands.setLayout('sidebar-right');
    };
    const handleSidebarTwoColumnClick = () => {
        editor.commands.setLayout('two-column');
    };

    return (
        <BubbleMenu
            editor={editor}
            shouldShow={shouldShow}
            updateDelay={0}
            tippyOptions={{
                zIndex: 99,
                offset: [0, 8],
                popperOptions: {
                    modifiers: [{ name: 'flip', enabled: false }],
                },
                getReferenceClientRect,
                appendTo: () => appendTo?.current,
                plugins: [sticky],
                sticky: 'popper',
            }}
        >
            <div className="bubble-toggle-menu button-box image-bubble-menu column-bubble-menu">
                <div className='image-bubble-block'>
                    <button  className="full-display-button" onClick={handleSidebarLeftClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke={editor.isActive('columns', { layout: 'sidebar-left' }) ? '#0A0A0A' : 'currentColor' } strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-panel-left w-4 h-4"><rect width="18" height="18" x="3" y="3" rx="2"></rect><path d="M9 3v18"></path></svg>
                    </button>
                    <button  className="full-display-button" onClick={handleSidebarTwoColumnClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke={editor.isActive('columns', { layout: 'two-column' }) ? '#0A0A0A' : 'currentColor' } strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-columns2 w-4 h-4"><rect width="18" height="18" x="3" y="3" rx="2"></rect><path d="M12 3v18"></path></svg>
                    </button>
                    <button className="full-display-button" onClick={handleSidebarRightClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke={editor.isActive('columns', { layout: 'sidebar-right' }) ? '#0A0A0A' : 'currentColor' } strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-panel-right w-4 h-4"><rect width="18" height="18" x="3" y="3" rx="2"></rect><path d="M15 3v18"></path></svg>
                    </button>
                </div>
            </div>
        </BubbleMenu>
    );
};

export default ColumnBubbleMenu;
