import { Node, ReactNodeViewRenderer } from '@tiptap/react';
import { NodeViewWrapper } from '@tiptap/react';
import { useState } from 'react';

const SpotifyNodeView = ({ node, editor, getPos }) => {
    const [spotifyUrl, setSpotifyUrl] = useState('');
    const [errorMessage, setErrorMessage] = useState("");
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleInputChange = (e) => {
        const trimmedValue = e.target.value.trim();
        setSpotifyUrl(trimmedValue);
        setErrorMessage("");
    };

    const handleKeyPress = async (e) => {
        if (e.key === 'Enter' && spotifyUrl !== '') {
            const spotifyPattern = /^(https?:\/\/)?(open\.)?spotify\.com\/(track|album|playlist|artist|episode|show)\/.+$/;
            if (spotifyPattern.test(spotifyUrl)) {

                const urlSegments = spotifyUrl.split('/');
                const spotifyType = urlSegments[3]; 
                const spotifyId = urlSegments.pop().split('?')[0];

                setIsLoading(true); // Set loading state


                if (spotifyId) {
                    const spotifysrc = `https://open.spotify.com/embed/${spotifyUrl.split('/')[3]}/${spotifyId}?utm_source=generator&theme=1`;
                    editor.chain().focus().setSpotifyBlock({ spotifyUrl:spotifysrc, spotifyType  }).run();

                    setErrorMessage("");
                    setIsDataFetched(true);
                }
                else {
                    setErrorMessage("Please enter a valid Spotify URL");
                    setIsLoading(false);
                }
                setIsLoading(false);
            } else {
                setErrorMessage("Please enter a valid Spotify URL");
                setIsLoading(false);
            }
        }
    };

    return (
        <NodeViewWrapper>
            <div data-type="spotify" className="spotify-node-wrapper" data-drag-handle>
                <label>
                    <div className='urlInput'>
                        {
                            (isLoading) ?
                                <div  className={`show linkbutton-loader loader-buttonHeight`}>
                                    <div className='ring-loader'></div>
                                </div>
                                :
                                <div className={`${isDataFetched ? 'none' : 'show'}`}>
                                    <svg width='20' height='20' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                        <g clip-path='url(#clip0_4592_10402)'>
                                            <circle cx='6.99978' cy='6.99929' r='6.96023' fill='#1ED760' />
                                            <path d='M9.55351 9.38408C9.44936 9.54508 9.22719 9.59017 9.05362 9.49356C7.68588 8.72072 5.97099 8.54683 3.94368 8.97189C3.74928 9.01054 3.55488 8.90105 3.51322 8.72072C3.47157 8.54039 3.58959 8.36006 3.78399 8.32142C5.99876 7.85127 7.90111 8.05092 9.42854 8.92037C9.60211 9.01698 9.65765 9.22307 9.55351 9.38408ZM10.2617 7.91567C10.1298 8.11533 9.85205 8.17329 9.63682 8.05736C8.07468 7.16215 5.69328 6.90454 3.84648 7.42621C3.60348 7.49061 3.35354 7.36824 3.28411 7.14927C3.21468 6.92386 3.34659 6.69201 3.58959 6.6276C5.70022 6.03509 8.32462 6.31847 10.1228 7.34248C10.3242 7.45841 10.3936 7.71602 10.2617 7.91567ZM10.3242 6.38287C8.44959 5.35241 5.36002 5.25581 3.56877 5.75815C3.28411 5.84188 2.97862 5.69375 2.88837 5.42326C2.79811 5.1592 2.96474 4.87583 3.24939 4.7921C5.30448 4.21247 8.72036 4.32839 10.8727 5.51342C11.1295 5.65511 11.2128 5.96425 11.0601 6.20254C10.9143 6.44727 10.581 6.531 10.3242 6.38287Z' fill='white' />
                                        </g>
                                        <defs>
                                            <clipPath id='clip0_4592_10402'>
                                                <rect width='14' height='14' fill='white' />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <input
                                        className="urlInputLeft"
                                        type="text"
                                        placeholder="Paste Spotify URL to add embedded content"
                                        value={spotifyUrl}
                                        onChange={handleInputChange}
                                        onKeyPress={handleKeyPress}
                                    />
                                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                                </div>
                        }
                        
                    </div>
                </label>
            </div>
        </NodeViewWrapper>
    );
};

export default SpotifyNodeView;

export const SpotifyEmbeds = Node.create({
    name: 'spotify',

    isolating: true,
    defining: true,
    group: 'block',
    draggable: true,
    selectable: true,
    inline: false,

    parseHTML() {
        return [
          {
            tag: `div[data-type="${this.name}"]`,
          },
        ];
    },
    
    renderHTML() {
        return ['div', { 'data-type': this.name, class: 'spotify-block' }]
    },

    addCommands() {
        return {
            setSpotifyEmbeds:
            () =>
            ({ commands }) =>
              commands.insertContent(`<div data-type="${this.name}"></div>`),
        }
    },

    addNodeView() {
        return ReactNodeViewRenderer(SpotifyNodeView);
    },
});
