import { useEffect, useState } from 'react';
import axios from 'axios';
import Nestable from 'react-nestable';
import SocialItemList from '../Components/Settings/SocialItemList';
import plusIcon from '../images/plus.svg';
import '../App.css';
import 'react-nestable/dist/styles/index.css';
import NotificationMessage from '../Components/NotificationMessage';


function Social(props) {

    // Set active page
    props.setActivePage('Social networks');

    // State declarations
    const [socialArray, setSocialArray] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [cancelLoading, setCancelLoading] = useState(false);
    const [notification, setNotification] = useState(false);
    const [subscriberCount, setSubscriberCount]= useState(0)
    const [subscriberNotify, setSubscriberNotify] = useState(false);

    // Function to delete an item from socialArray
    function deleteItem(id) {
        const updatedData = socialArray.filter(item => item.id !== id);
        updatedData.forEach((item, index) => {
            item.id = index + 1;
        });
        setSocialArray(updatedData);
    }

    // Function to clear all data
    function clearAll() {
        setCancelLoading(true);
        fetchData();
    }

    // Function to update the socialArray state
    const updateSocialArray = (itemId, newData) => {
        const updatedArray = socialArray.map(item => {
            if (item.id === itemId) {
                return {
                    ...item,
                    ...newData
                };
            }
            return item;
        });
        setSocialArray(updatedArray);
    };

    // Fetch social data from the API
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/user/${JSON.parse(localStorage.getItem("userinfo"))?._id}`
            );
            const userMeta = response?.data?.result?.userMeta;
            setSubscriberCount(response?.data?.subscriberCount)
            const socialNetwork = userMeta.social_nework.map((item, index) => {
                return {
                    id: index,
                    title: item.title,
                    url: item.url,
                    children: []
                };
            });
            if(response?.data?.subscriberCount > 0){
                setSubscriberNotify(true)
            }

            setSocialArray(socialNetwork);
            setCancelLoading(false);
        } catch (error) {
            setCancelLoading(false);
            console.log(error, "error");
        }
    };

    // Function to add social data
    const addSocialData = async () => {
        try {
            setIsLoading(true);

            const socialNetworkData = {
                social_nework: socialArray.filter(item => item.url !== null && item.url !== '')
            };

            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/user/socialnework/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
                socialNetworkData
            );
            setIsLoading(false);
            setNotification(true)
        } catch (error) {
            setIsLoading(false);
            console.log(error, "Data not added");
        }
    };

    const [messages, setMessages] = useState("");
    const [subscriberAddedNotify, setSubscriberAddedNotify] = useState(false);
    const user_id = JSON.parse(localStorage.getItem("userinfo"))?._id
    useEffect(() => {
        const webSocket = new WebSocket('ws://localhost:8080');
    
        webSocket.onopen = () => {
        //   console.log('Connected to WebSocket server');

        };
    
        webSocket.onmessage = (event) => {
            const parsedData = JSON.parse(event.data);
            if(parsedData?.author_id === user_id){
                if(parsedData?.message !== "" && parsedData?.message !== undefined){
                    setSubscriberAddedNotify(true);
                    setMessages(parsedData?.message);
                }
            }
        };
    
        return () => {
            webSocket.close();
        };
    }, [messages]);

    return (
        <div className="general">

            <div className="generalTop">
                <div className="generalTopLeft">
                    <p className='generalTopLeftTitle'>Social networks</p>
                    <p className='generalTopLeftDesc'>Update your company photo and details here.</p>
                </div>
            </div>

            <div className="settingsMenuList">
                <div className="settingsListWrap">

                    <div className="socialBlock">
                        <div className="socialLeft">
                            <p className='settingsMenuBlockTitle'>My social networks</p>
                            <p className='settingsMenuBlockSubtitle'>This will be displayed on your profile.</p>
                        </div>
                        <div className="socialRight">
                            <div className="socialItem">
                                <Nestable items={socialArray}
                                    renderItem={({ item }) => <SocialItemList socialArray={socialArray} updateSocialArray={updateSocialArray} deleteItem={deleteItem} data={item} name={item.title} link={item.url} />}
                                    maxDepth={0}
                                    onChange={(newData) => {
                                        newData.items.map((item, index) => (item.id = index + 1));
                                        setSocialArray(newData.items);
                                    }}
                                />

                                <button onClick={e => {
                                    var data = socialArray;

                                    setSocialArray([...socialArray, {
                                        id: data.length + 1,
                                        title: null,
                                        url: null,
                                        children: []
                                    }])
                                }} className='addToMenu'><img src={plusIcon} alt="Add new social network" /><p>Add a new social network</p></button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="saveButtons">
                    <button  className={`settingsWhite ${cancelLoading ? 'btn_loading' : ''}`} onClick={clearAll}>
                    {cancelLoading ?
                            <div className="settingPage-cancelLoader ">
                                <div className="ring-loader cancel"></div>
                            </div>
                            : "Cancel"}
                            </button>
                    <button className={`settingsBlue ${isLoading ? 'btn_loading' : ''}`} onClick={addSocialData}>
                        {isLoading ?
                            <div className="settingPage-loader">
                                <div className="ring-loader"></div>
                            </div>
                            : "Save"}</button>
                </div>
            </div>
            {notification &&
                <NotificationMessage  openNotify={notification} setOpenNotify={setNotification} type={'success'} title={`Your social networks has been successfully updated! `} />
            }
            {(subscriberNotify && subscriberCount > 0) && (
                <NotificationMessage  openNotify={subscriberNotify} setOpenNotify={setSubscriberNotify} type={'success'} title={`${(subscriberCount === 1) ? `A subscriber was successfully added!`: `${subscriberCount} subscribers were successfully added!`}`} />
            )}
            {subscriberAddedNotify && (
                <NotificationMessage  openNotify={subscriberAddedNotify} setOpenNotify={setSubscriberAddedNotify} type={'success'} title={messages}  name="liveNotification" />
            )}
        </div>
    );
}

export default Social;
