import { Node, ReactNodeViewRenderer } from '@tiptap/react';
import { NodeViewWrapper } from '@tiptap/react';
import { useState, useRef, useEffect } from 'react';
import UploadMediaPopup from '../../../../Modal/UploadMediaPopup';
import axios from 'axios';

const VideoNodeView = ({ node, editor, getPos }) => {
    const [isImageDragging, setIsImageDragging] = useState(false);
    const [loadingFiles, setLoadingFiles] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [showUploadMediaPopup, setShowUploadMediaPopup] = useState(false);
    const [uploadMediaPopupFor, setUploadMediaPopupFor] = useState('');
    const [uploadMediaPopupTab, setUploadMediaPopupTab] = useState('upload');
    const [selectedVideoFileData, setSelectedVideoFileData] = useState('');
    const [selectMediaLoading, setSelectMediaLoading] = useState(false);
    const [isDragging, setIsDragging] = useState(false);

    useEffect(() => {
        if (selectedVideoFileData !== '') {
            if (uploadMediaPopupFor === 'video-file') {
                setSelectedVideoFileData(selectedVideoFileData)
                setSelectMediaLoading(false);
                setShowUploadMediaPopup(false);
                setUploadMediaPopupFor('');
                editor.chain().focus().setVideoBlock({ videoUrl: selectedVideoFileData?.url, thumbnailurl: '', fullDisplay: false, loop: false ,caption: selectedVideoFileData?.description,videoAlt:selectedVideoFileData?.alt,videoId:selectedVideoFileData?._id,videoKey:selectedVideoFileData?.key}).run();

            }
        }
    }, [selectedVideoFileData]);
    const handleFileDragOver = (e) => {
        e.preventDefault();
        if (!isDragging) {
            setIsImageDragging(true);
        }
    }

    const handleFileDragLeave = (e) => {
        e.preventDefault();
        setIsImageDragging(false);
    };

    const handleVideoDrop = async (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
    
        if (files.length > 1) {
            setErrorMessages(['You can only upload one video at a time.']);
            setIsImageDragging(false); // Reset dragging state
            return; // Exit early if multiple files are dropped
        }
    
        const maxSizeInBytes = 128 * 1024 * 1024; // 128MB
        const allowedVideoTypes = ['video/mp4', 'video/mov', 'video/avi', 'video/wmv', 'video/webm'];
    
        for (const file of files) {
            if (!allowedVideoTypes.includes(file.type)) {
                setErrorMessages([`${file.name} is not an allowed video type.`]);
                setIsImageDragging(false);
                continue;
            }
    
            if (file.size > maxSizeInBytes) {
                setErrorMessages([`${file.name} exceeds the maximum size of 128MB.`]);
                setIsImageDragging(false);
                continue;
            }
    
            const formData = new FormData();
            formData.append('files', file);
    
            try {
                setLoadingFiles(true);
                const res = await axios.post(
                    `${process.env.REACT_APP_API_URL}/user/uploadMedia/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
                    formData,
                    { headers: { 'Content-Type': 'multipart/form-data' } }
                );
                setErrorMessages([])
                editor.chain().focus().setVideoBlock({ videoUrl: res?.data?.result[0]?.url, thumbnailurl: '', fullDisplay: false, loop: false ,caption: res?.data?.result[0]?.description,videoAlt:res?.data?.result[0]?.alt,videoId:res?.data?.result[0]?._id,videoKey:res?.data?.result[0]?.key}).run();
            } catch (error) {
                setErrorMessages(['Failed to upload video.']);
            } finally {
                setLoadingFiles(false);
            }
        }
    
        setIsImageDragging(false);
    };


    return (
        <>
        <NodeViewWrapper>
            <div data-type="video" className={`image-node-wrapper`} data-drag-handle>
                <div>
                    <div className={`newpageLeftRow textBlock uploadImageBlock ${loadingFiles ? 'uploadRunning' : ''}`}>
                        <div
                            className={isImageDragging ? 'dragging fileUploadBox' : 'fileUploadBox'}
                            onClick={() => {
                                setShowUploadMediaPopup(true);
                                setUploadMediaPopupFor('video-file');
                                setUploadMediaPopupTab('upload');
                            }}
                            onDragOver={handleFileDragOver}
                            onDragLeave={handleFileDragLeave}
                            onDrop={handleVideoDrop}
                        >
                            {
                                loadingFiles ?
                                    <div className="progress-wrap">
                                        <div className="progress">
                                            <div className="color"></div>
                                        </div>
                                    </div>
                                    :
                                    <div className="uploadFileBlock" >
                                        <div className="videoMiddleContent">
                                            <svg width="47" height="46" viewBox="0 0 47 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="3.5" y="3" width="40" height="40" rx="20" fill="#F2F4F7"/>
                                                <rect x="3.5" y="3" width="40" height="40" rx="20" stroke="#F9FAFB" strokeWidth="6"/>
                                                <path d="M31.4168 20.0733C31.4168 19.5937 31.4168 19.3539 31.322 19.2428C31.2397 19.1465 31.1163 19.0953 30.9899 19.1053C30.8444 19.1167 30.6748 19.2863 30.3357 19.6254L27.4585 22.5026L30.3357 25.3798C30.6748 25.7189 30.8444 25.8885 30.9899 25.8999C31.1163 25.9099 31.2397 25.8587 31.322 25.7624C31.4168 25.6513 31.4168 25.4115 31.4168 24.9319V20.0733Z" stroke="#475467" strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M15.5835 20.7609C15.5835 19.4308 15.5835 18.7658 15.8424 18.2577C16.0701 17.8108 16.4334 17.4475 16.8803 17.2198C17.3883 16.9609 18.0534 16.9609 19.3835 16.9609H23.6585C24.9886 16.9609 25.6537 16.9609 26.1617 17.2198C26.6086 17.4475 26.9719 17.8108 27.1996 18.2577C27.4585 18.7658 27.4585 19.4308 27.4585 20.7609V24.2443C27.4585 25.5744 27.4585 26.2395 27.1996 26.7475C26.9719 27.1944 26.6086 27.5577 26.1617 27.7854C25.6537 28.0443 24.9886 28.0443 23.6585 28.0443H19.3835C18.0534 28.0443 17.3883 28.0443 16.8803 27.7854C16.4334 27.5577 16.0701 27.1944 15.8424 26.7475C15.5835 26.2395 15.5835 25.5744 15.5835 24.2443V20.7609Z" stroke="#475467" strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                            <p className="newPageImageUploadText">
                                                    <span>Upload</span> or <span
                                                        onClick={(e) => { e.stopPropagation(); setShowUploadMediaPopup(true); setUploadMediaPopupFor('video-file'); setUploadMediaPopupTab('media'); }}
                                                    >Open Video Library</span>
                                                    <br />
                                                    MP4, MOV, AVI, WMV, AVCHD, WebM, FLV (max. 128MB)
                                                </p>
                                            {errorMessages && errorMessages.length > 0 && (
                                                <div className="errorMessages">
                                                    {errorMessages.map((errorMessage, index) => (
                                                        <p key={index} className="error-message">
                                                            {errorMessage}
                                                        </p>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>

                </div>
            </div>
        </NodeViewWrapper>
         {showUploadMediaPopup &&
            <UploadMediaPopup showUploadMediaPopup={showUploadMediaPopup} setShowUploadMediaPopup={setShowUploadMediaPopup} setSelectedProfileImage={setSelectedVideoFileData} selectMediaLoading={selectMediaLoading} uploadMediaPopupFor={uploadMediaPopupFor} uploadMediaPopupTab={uploadMediaPopupTab} />
        }
        </>
    );
};

export const VideoEmbeds = Node.create({
    name: 'video',

    isolating: true,
    defining: true,
    group: 'block',
    draggable: true,
    selectable: true,
    inline: false,

    addAttributes() {
        return {
            videoUrl: {
                default: '',
            },
            thumbnailurl: {
                default:''
            },
            fullDisplay: {
                default: false,
            },
            loop:{
                default: false
            }
        };
    },

    parseHTML() {
        return [
            {
                tag: `div[data-type="${this.name}"]`,
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['div', { 'data-type': this.name, ...HTMLAttributes }];
    },

    addCommands() {
        return {
            setVideoUpload: (videoUrl) => ({ commands }) => {
                return commands.insertContent(`<div data-type="${this.name}" data-image-url="${videoUrl}"></div>`);
            },
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(VideoNodeView);
    },
});

