import React, { useCallback, useEffect, useRef, useState, forwardRef } from 'react'

import icons from '../iconsList.json';

const MenuList = forwardRef((props, ref) => {
    const scrollContainer = useRef(null)
    const activeItem = useRef(null)
    const [selectedGroupIndex, setSelectedGroupIndex] = useState(0)
    const [selectedCommandIndex, setSelectedCommandIndex] = useState(0)

    // Reset selection to the first item when the items change
    useEffect(() => {
        setSelectedGroupIndex(0)
        setSelectedCommandIndex(0)
    }, [props.items])

    const selectItem = useCallback((groupIndex, commandIndex) => {
        const command = props.items[groupIndex].commands[commandIndex]
        props.command(command)
    }, [props])

    useEffect(() => {
        if (activeItem.current && scrollContainer.current) {
            const offsetTop = activeItem.current.offsetTop
            const offsetHeight = activeItem.current.offsetHeight
            scrollContainer.current.scrollTop = offsetTop - offsetHeight
        }
    }, [selectedCommandIndex, selectedGroupIndex])

    const createCommandClickHandler = useCallback((groupIndex, commandIndex) => () => {
        selectItem(groupIndex, commandIndex)
    }, [selectItem])

    // Key down event handling
    const handleKeyDown = (event) => {
        if (!props.items.length) return false

        const commands = props.items[selectedGroupIndex].commands
        let newCommandIndex = selectedCommandIndex
        let newGroupIndex = selectedGroupIndex

        if (event.event.key === 'ArrowDown') {
            newCommandIndex++
            if (newCommandIndex >= commands.length) {
                newCommandIndex = 0
                newGroupIndex++
                if (newGroupIndex >= props.items.length) newGroupIndex = 0
            }
            setSelectedCommandIndex(newCommandIndex)
            setSelectedGroupIndex(newGroupIndex)
            return true
        }

        if (event.event.key === 'ArrowUp') {
            newCommandIndex--
            if (newCommandIndex < 0) {
                newGroupIndex--
                if (newGroupIndex < 0) newGroupIndex = props.items.length - 1
                newCommandIndex = props.items[newGroupIndex].commands.length - 1
            }
            setSelectedCommandIndex(newCommandIndex)
            setSelectedGroupIndex(newGroupIndex)
            return true
        }

        if (event.event.key === 'Enter') {
            selectItem(selectedGroupIndex, selectedCommandIndex)
            return true
        }

        return false
    }

    React.useImperativeHandle(ref, () => ({
        onKeyDown: handleKeyDown
    }))

    if (!props.items.length) return null

    return (
        <div 
            ref={scrollContainer} 
            className="menulist-container" 
            tabIndex={0}  // This makes the div focusable
            onKeyDown={handleKeyDown} // Attach the keydown event listener here
        >
            <div className="menulist-wrapp">
                {props.items.map((group, groupIndex) => (
                    <React.Fragment key={group.title}>
                        <div className="menu-title">
                            {group.title}
                        </div>
                        {group.commands.map((command, commandIndex) => (
                            <div
                                key={command.label}
                                className={`menu-label ${selectedGroupIndex === groupIndex && selectedCommandIndex === commandIndex ? 'active' : ''}`}
                                onClick={createCommandClickHandler(groupIndex, commandIndex)}
                                ref={selectedGroupIndex === groupIndex && selectedCommandIndex === commandIndex ? activeItem : null} // Track the active item
                            >
                                <div dangerouslySetInnerHTML={{ __html: icons[command.iconName] }} />
                                {command.label}
                            </div>
                        ))}
                    </React.Fragment>
                ))}
            </div>
        </div>
    )
})

MenuList.displayName = 'MenuList'

export default MenuList
