import React, { useEffect, useState, useCallback } from 'react'

import { DOMSerializer } from 'prosemirror-model'
import DragHandle from '@tiptap-pro/extension-drag-handle-react'

export const ContentItemMenu = ({editor}) => {
    
    const [menuOpen, setMenuOpen] = useState(false)
    const [currentNode, setCurrentNode] = useState(null)
    const [currentNodePos, setCurrentNodePos] = useState(-1)
    const [showIcons, setShowIcons] = useState(true);

    useEffect(() => {
        editor.commands.setMeta('lockDragHandle', menuOpen)
    }, [editor, menuOpen])
    
    const toggleMenu = () => setMenuOpen(!menuOpen)

    const handleNodeChange = useCallback(
        (data) => {
            if (data.node) {
                setCurrentNode(data.node) // Updating the node state
            }
            setCurrentNodePos(data.pos)  
            if((data?.node?.type?.name === 'imageBlock' && data?.node?.attrs?.layout === 'full') || (data?.node?.type?.name === 'galleryBlock' && data?.node?.attrs?.layout === 'full') || (data?.node?.type?.name === 'VideoBlock' && data?.node?.attrs?.layout === 'wide') || (data?.node?.type?.name === 'logoSliderBlock' && data?.node?.attrs?.layout === 'full')) {
                setShowIcons(false);
            } else {
                setShowIcons(true);
            }
        },
        []
    )

    const resetTextFormatting = () => {
        const chain = editor.chain()
      
        chain.setNodeSelection(currentNodePos).unsetAllMarks()
      
        if (currentNode && currentNode.type && currentNode.type.name !== 'paragraph') {
          chain.setParagraph()
        }
      
        chain.run()
        setMenuOpen(false)
    }

    const duplicateNode = () => {
        editor.commands.setNodeSelection(currentNodePos)
      
        const { $anchor } = editor.state.selection
        const selectedNode = $anchor.node(1) || editor.state.selection.node
      
        editor
          .chain()
          .setMeta('hideDragHandle', true)
          .insertContentAt(currentNodePos + (currentNode?.nodeSize || 0), selectedNode.toJSON())
          .run()

        setMenuOpen(false)
    }

    const copyNodeToClipboard = async () => {
        // Set node selection
        editor.chain().setMeta('hideDragHandle', true).setNodeSelection(currentNodePos).run();
    
        const { $anchor } = editor.state.selection;
        const selectedNode = $anchor.node(1) || editor.state.selection.node;
    
        // Get the text content of the current node
        const nodeContent = selectedNode.textContent; // Get text content
        // const htmlContent = editor.getHTML(currentNodePos, currentNodePos + selectedNode.nodeSize); // Get HTML content

        const serializer = DOMSerializer.fromSchema(editor.schema)                
        const fragment = serializer.serializeFragment(selectedNode.content)        
        const tempDiv = document.createElement('div')
        tempDiv.appendChild(fragment)

        // Get the HTML content
        const htmlContent = tempDiv.innerHTML
    
        // Copy to clipboard using Clipboard API
        try {
            await navigator.clipboard.write([
                new ClipboardItem({
                    'text/html': new Blob([htmlContent], { type: 'text/html' }),
                    'text/plain': new Blob([nodeContent], { type: 'text/plain' }),
                }),
            ]);
            console.log("Copied to clipboard");
        } catch (err) {
            console.error("Failed to copy: ", err);
        }
    
        setMenuOpen(false);
    };
    
    const deleteNode = () => {
        editor.chain().setMeta('hideDragHandle', true).setNodeSelection(currentNodePos).deleteSelection().run()

        setMenuOpen(false)
    }

    const handleAdd = () => {
        if (currentNodePos !== -1) {
          const currentNodeSize = currentNode?.nodeSize || 0
          const insertPos = currentNodePos + currentNodeSize
          const currentNodeIsEmptyParagraph = currentNode?.type.name === 'paragraph' && currentNode?.content?.size === 0
          const focusPos = currentNodeIsEmptyParagraph ? currentNodePos + 2 : insertPos + 2
    
          editor
            .chain()
            .command(({ dispatch, tr, state }) => {
              if (dispatch) {
                if (currentNodeIsEmptyParagraph) {
                  tr.insertText('/', currentNodePos, currentNodePos + 1)
                } else {
                  tr.insert(insertPos, state.schema.nodes.paragraph.create(null, [state.schema.text('/')]))
    
                }
                return dispatch(tr)
              }
              return true
            })
            .focus(focusPos)
            .run()
        }
    }

    return(        
        <div className='DragHandleWrapper'>
            <DragHandle 
                editor={editor}                
                onNodeChange={handleNodeChange}
                tippyOptions={{
                    offset: [-2, 16],
                    zIndex: 99,
                    appendTo: document.body
                }}
                >
                    <div className={`DragHandleItems ${! showIcons && 'd-none'}`}>
                        <div className="DragHandleItem AddElementIcon" onClick={handleAdd}>
                            <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path d='M9.99984 6.66406V13.3307M6.6665 9.9974H13.3332M18.3332 9.9974C18.3332 14.5998 14.6022 18.3307 9.99984 18.3307C5.39746 18.3307 1.6665 14.5998 1.6665 9.9974C1.6665 5.39502 5.39746 1.66406 9.99984 1.66406C14.6022 1.66406 18.3332 5.39502 18.3332 9.9974Z' stroke='#0A2540' strokeWidth='1.7' strokeLinecap='round' strokeLinejoin='round'/>
                            </svg>
                        </div>
                        <div className='DragHandleItem DragHandleIcon' onClick={toggleMenu}>
                            <svg width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.99984 10.8359C10.4601 10.8359 10.8332 10.4628 10.8332 10.0026C10.8332 9.54237 10.4601 9.16927 9.99984 9.16927C9.5396 9.16927 9.1665 9.54237 9.1665 10.0026C9.1665 10.4628 9.5396 10.8359 9.99984 10.8359Z" stroke="#BEBDBC" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M9.99984 5.0026C10.4601 5.0026 10.8332 4.62951 10.8332 4.16927C10.8332 3.70903 10.4601 3.33594 9.99984 3.33594C9.5396 3.33594 9.1665 3.70903 9.1665 4.16927C9.1665 4.62951 9.5396 5.0026 9.99984 5.0026Z" stroke="#BEBDBC" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M9.99984 16.6693C10.4601 16.6693 10.8332 16.2962 10.8332 15.8359C10.8332 15.3757 10.4601 15.0026 9.99984 15.0026C9.5396 15.0026 9.1665 15.3757 9.1665 15.8359C9.1665 16.2962 9.5396 16.6693 9.99984 16.6693Z" stroke="#BEBDBC" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M16.9998 10.8359C17.4601 10.8359 17.8332 10.4628 17.8332 10.0026C17.8332 9.54237 17.4601 9.16927 16.9998 9.16927C16.5396 9.16927 16.1665 9.54237 16.1665 10.0026C16.1665 10.4628 16.5396 10.8359 16.9998 10.8359Z" stroke="#BEBDBC" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M16.9998 5.0026C17.4601 5.0026 17.8332 4.62951 17.8332 4.16927C17.8332 3.70903 17.4601 3.33594 16.9998 3.33594C16.5396 3.33594 16.1665 3.70903 16.1665 4.16927C16.1665 4.62951 16.5396 5.0026 16.9998 5.0026Z" stroke="#BEBDBC" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M16.9998 16.6693C17.4601 16.6693 17.8332 16.2962 17.8332 15.8359C17.8332 15.3757 17.4601 15.0026 16.9998 15.0026C16.5396 15.0026 16.1665 15.3757 16.1665 15.8359C16.1665 16.2962 16.5396 16.6693 16.9998 16.6693Z" stroke="#BEBDBC" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                        { menuOpen &&
                            <div className='DragHandleSubItems'>
                                <div className='DragHandleSubItemsWrapp'>
                                    <div className='DragHandleSubItem' onClick={resetTextFormatting}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"><path d="M4 7V4h16v3"></path><path d="M5 20h6"></path><path d="M13 4 8 20"></path><path d="m15 15 5 5"></path><path d="m20 15-5 5"></path></svg>
                                        Clear formatting
                                    </div>
                                    <div className='DragHandleSubItem' onClick={copyNodeToClipboard}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"><rect width="8" height="4" x="8" y="2" rx="1" ry="1"></rect><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path></svg>
                                        Copy to clipboard
                                    </div>
                                    <div className='DragHandleSubItem' onClick={duplicateNode}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"><rect width="14" height="14" x="8" y="8" rx="2" ry="2"></rect><path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2"></path></svg>
                                        Duplicate
                                    </div>
                                    <div className='sunItemSivider'></div>
                                    <div className='DragHandleSubItem dangerItem' onClick={deleteNode}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"><path d="M3 6h18"></path><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path><line x1="10" x2="10" y1="11" y2="17"></line><line x1="14" x2="14" y1="11" y2="17"></line></svg>
                                        Delete
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
            </DragHandle>
        </div>
    )
}
