import React, { useState } from 'react';
import deleteImage from '../../src/images/delete-backgroung.svg';
import axios from 'axios';
import Cookies from 'js-cookie';


function DeleteAccountModal({ isOpen, onClose,stripeData }) {
    const [password, setPassword] = useState("");
    const [deleteModal, setdeleteModal] = useState(true);
    const [validatePassword, setValidatePassword] = useState(false);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    if (!isOpen) {
        return null;
    }
    const handleSubmit = async (e) => {
        e.preventDefault();

        if(isLoading || ( stripeData?.details_submitted === true && stripeData?.stripeAccountid !== "" )) {
            return; 
        }

        try {
            if (password.length > 0) {
                setIsLoading(true);
                await axios.post(
                    `${process.env.REACT_APP_API_URL}/user/removeaccount/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
                    { password: password }
                );
                setdeleteModal(false);
                document.body.classList.remove('hidden-message');
                localStorage.removeItem("userinfo");
                localStorage.removeItem("isCompleted");
                localStorage.removeItem("isInvited");
                localStorage.removeItem("userProfileSlug");
                sessionStorage.removeItem("userId");
                window.location.href = "/login";
                Cookies.remove('user_id');  
            } else {
                setValidatePassword(true)
            }
            setIsLoading(false);
            setPassword("");
        } catch (error) {
            setIsLoading(false);
            if (error.response && error.response.status === 400) {
                setError(true);
            } else {
                console.log(error, "error");
            }
            return false;
        }
    }


    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        if (e.target.value.length > 0) {
            setValidatePassword(false);
            setError(false);
        } else {
            setValidatePassword(true);
        }
    };
    return (
        <form onSubmit={handleSubmit}>
            {deleteModal && (<div className='welcomeModal'>
                <div className='welcomeFace'>
                    <img src={deleteImage} className="delete-icon" alt="faceSmile-icon" />
                </div>
                <h3 className='welcomeTitle'>Delete your account</h3>
                <p className='welcomeText'>We're sorry to hear that you're considering deleting your account. <br /> <br />
                    Please be aware that deleting your account is a permanent action and cannot be undone. All your profile, data, settings, and preferences will be irreversibly removed from our system. If you are sure about this decision, proceed with caution. Should you ever wish to return in the future, you would need to start anew.
                </p>

                <div className='welcomeForm'>
                    <label className='welcomeLabel'>Password</label>
                    <input
                        type="password"
                        name="password"
                        placeholder="Enter your password"
                        className="welcomeInput"
                        autoFocus={true}
                        value={password}
                        disabled={(stripeData?.details_submitted === true && stripeData?.stripeAccountid !== "") ? true : false}
                        onChange={handlePasswordChange} />
                    {validatePassword === true ? (
                        <p className="validateError">The field is not filled</p>
                    ) : (
                        ""
                    )}
                    {error === true ? (
                        <p className="validateError">Passwords do not match</p>
                    ) : (
                        ""
                    )}
                </div>
                {
                    (stripeData?.details_submitted === true && stripeData?.stripeAccountid !== "") && <p className="validateError">Before delete account you need to disconnect your stipe account.</p>
                }
                <div className="welcomeModalbtn">
                    <button className="cancel" type="button" onClick={onClose}>Cancel</button>
                    <button className={`savePicture ${(stripeData?.details_submitted === true && stripeData?.stripeAccountid !== "") ? `welcomeModalbtnDisabled` :""}`} type="submit" disabled={(stripeData?.details_submitted === true && stripeData?.stripeAccountid !== "") ? true : false}>
                        {isLoading ?
                            <div className="settingPage-loader">
                                <div className="ring-loader"></div>
                            </div>
                            : "Delete"}</button>
                </div>
            </div>)}
        </form>
    )
}
export default DeleteAccountModal