import React, { useState, useEffect, useCallback } from 'react';
import { BubbleMenu } from '@tiptap/react';
import TextmenuCommands from '../../TextMenu/TextmenuCommands';
import { ImageLinkPopover } from './ImageLinkEditorPanel';

const ImageBubbleMenu = ({ editor, appendTo, type }) => {
    const [showEdit, setShowEdit] = useState(false);
    const [isUrl, setUrl] = useState('');
    const [isImageTarget, setImageUrlTarget] = useState(false);
    const [editAttributes, setEditAttributes] = useState({
        href: '#',
        width: 'auto',
        layout: 'default',
        imageLink: '',
        target: false
    });

    const getNodeAttrs = useCallback(() => {
        const nodeAttrs = editor.state.selection.node?.attrs;
        if (nodeAttrs) {
            setEditAttributes((prev) => ({
                ...prev,
                caption: nodeAttrs.caption || '',
                imagelink: nodeAttrs.imagelink || '',
                layout: nodeAttrs.layout || 'default',
                target: nodeAttrs.target || false,
                width: nodeAttrs.width || 'auto',
            }));
        }
    }, [editor]);

    const shouldShow = useCallback(() => {
        if (editor.isActive('imageBlock')) {
            getNodeAttrs();
        }
        return editor.isActive('imageBlock') && editor.isFocused;
    }, [editor, getNodeAttrs]);

    const setButtonAttribute = useCallback((key, value) => {
        setEditAttributes((prev) => {
            const newAttrs = { ...prev, [key]: value };
            editor.chain().focus().updateAttributes('imageBlock', newAttrs).run();
            return newAttrs;
        });
    }, [editor]);

    const commands = TextmenuCommands(editor);
    const [editLinkPopoverOpen, setEditLinkPopoverOpen] = useState(false);

    const toggleEditLinkPopover = () => {
        setEditLinkPopoverOpen(prev => !prev);
    };

    const onUnsetLink = useCallback(() => {
        setButtonAttribute('imagelink', '');
        setButtonAttribute('target', false);
        editor.chain().focus().unsetLink().run();
        setShowEdit(false);
        setEditLinkPopoverOpen(false);
        setUrl('');
    }, [editor, setButtonAttribute]);

    useEffect(() => {
        if (editAttributes.imagelink) {
            setShowEdit(true);
        }
    }, [editAttributes]);

    const changeImage = () => {
        editor.chain().setImageUpload().run();
    };

    const deleteNode = () => {
        editor.chain().setMeta('imageBlock', true).deleteSelection().run();
    };

    return (
        <BubbleMenu
            editor={editor}
            shouldShow={shouldShow}
            updateDelay={0}
            tippyOptions={{
                zIndex: 99,
                popperOptions: {
                    modifiers: [{ name: 'flip', enabled: false }],
                },
                appendTo: () => appendTo?.current,
                onHidden: () => { setEditLinkPopoverOpen(false); setShowEdit(false) }
            }}
        >
            <div className="bubble-toggle-menu button-box image-bubble-menu">
                <div className='image-bubble-block'>
                    { type === 'publication'  && 
                        (editAttributes?.layout === "default" ? 
                        <button onClick={() => setButtonAttribute("layout", "full")} className="full-display-button">
                            <svg width="20" height="20" viewBox="0 0 15 14" stroke='currentColor' fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.75 8.16667V9.45C12.75 10.4301 12.75 10.9201 12.5593 11.2945C12.3915 11.6238 12.1238 11.8915 11.7945 12.0593C11.4201 12.25 10.9301 12.25 9.95 12.25H8.66667M6.33333 1.75H5.05C4.06991 1.75 3.57986 1.75 3.20552 1.94074C2.87623 2.10852 2.60852 2.37623 2.44074 2.70552C2.25 3.07986 2.25 3.56991 2.25 4.55V5.83333M9.25 5.25L12.75 1.75M12.75 1.75H9.25M12.75 1.75V5.25M5.75 8.75L2.25 12.25M2.25 12.25H5.75M2.25 12.25L2.25 8.75" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                        :
                        <button onClick={() => setButtonAttribute("layout", "default")} className="full-display-button">
                            <svg width="20" height="20" viewBox="0 0 15 14" stroke='#0A0A0A' fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.75 8.16667V9.45C12.75 10.4301 12.75 10.9201 12.5593 11.2945C12.3915 11.6238 12.1238 11.8915 11.7945 12.0593C11.4201 12.25 10.9301 12.25 9.95 12.25H8.66667M6.33333 1.75H5.05C4.06991 1.75 3.57986 1.75 3.20552 1.94074C2.87623 2.10852 2.60852 2.37623 2.44074 2.70552C2.25 3.07986 2.25 3.56991 2.25 4.55V5.83333M9.25 5.25L12.75 1.75M12.75 1.75H9.25M12.75 1.75V5.25M5.75 8.75L2.25 12.25M2.25 12.25H5.75M2.25 12.25L2.25 8.75" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                        )
                    }
                    <button onClick={toggleEditLinkPopover} className="full-display-button">
                        <svg width="20" height="20" viewBox="0 0 15 14" stroke={`${isUrl ? '#0A0A0A' : 'currentColor'}`} fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.91291 10.7132L7.08795 11.5382C5.94892 12.6772 4.10219 12.6772 2.96316 11.5382C1.82413 10.3992 1.82413 8.55242 2.96316 7.41339L3.78812 6.58844M11.2127 7.41339L12.0377 6.58844C13.1767 5.44941 13.1767 3.60268 12.0377 2.46365C10.8987 1.32462 9.05194 1.32462 7.91291 2.46365L7.08795 3.2886M5.45876 9.04257L9.54209 4.95924" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                    <button onClick={changeImage} className="full-display-button">
                        <svg width="20" height="20" viewBox="0 0 15 14" stroke='currentColor' fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.83333 2.91406L11.5833 1.16406M11.5833 1.16406L13.3333 2.91406M11.5833 1.16406V4.66406M7.79167 1.7474H5.05C4.06991 1.7474 3.57986 1.7474 3.20552 1.93813C2.87623 2.10591 2.60852 2.37363 2.44074 2.70291C2.25 3.07726 2.25 3.5673 2.25 4.5474V9.4474C2.25 10.4275 2.25 10.9175 2.44074 11.2919C2.60852 11.6212 2.87623 11.8889 3.20552 12.0567C3.57986 12.2474 4.06991 12.2474 5.05 12.2474H10.4167C10.9591 12.2474 11.2304 12.2474 11.4529 12.1878C12.0568 12.0259 12.5286 11.5542 12.6904 10.9503C12.75 10.7278 12.75 10.4565 12.75 9.91406M6.625 4.95573C6.625 5.60006 6.10267 6.1224 5.45833 6.1224C4.814 6.1224 4.29167 5.60006 4.29167 4.95573C4.29167 4.3114 4.814 3.78906 5.45833 3.78906C6.10267 3.78906 6.625 4.3114 6.625 4.95573ZM9.24419 6.94965L4.30984 11.4354C4.03229 11.6877 3.89352 11.8139 3.88125 11.9232C3.87061 12.0179 3.90693 12.1119 3.97852 12.1748C4.06112 12.2474 4.24866 12.2474 4.62375 12.2474H10.0993C10.9388 12.2474 11.3586 12.2474 11.6883 12.1064C12.1022 11.9293 12.4319 11.5996 12.609 11.1857C12.75 10.856 12.75 10.4362 12.75 9.59672C12.75 9.31425 12.75 9.17302 12.7191 9.04148C12.6803 8.87619 12.6059 8.72135 12.5011 8.58779C12.4176 8.48151 12.3074 8.39328 12.0868 8.21683L10.4551 6.91145C10.2343 6.73484 10.1239 6.64654 10.0024 6.61538C9.89524 6.58791 9.7825 6.59147 9.67731 6.62563C9.55797 6.66439 9.45337 6.75948 9.24419 6.94965Z" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                    <button onClick={deleteNode} gallery-fileName="full-display-button">
                        <svg width="20" height="20" viewBox="0 0 15 14" stroke='currentColor' fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.33333 3.4974V3.03073C9.33333 2.37733 9.33333 2.05064 9.20617 1.80107C9.09432 1.58155 8.91584 1.40307 8.69632 1.29122C8.44676 1.16406 8.12006 1.16406 7.46667 1.16406H6.53333C5.87994 1.16406 5.55324 1.16406 5.30368 1.29122C5.08416 1.40307 4.90568 1.58155 4.79383 1.80107C4.66667 2.05064 4.66667 2.37733 4.66667 3.03073V3.4974M5.83333 6.70573V9.6224M8.16667 6.70573V9.6224M1.75 3.4974H12.25M11.0833 3.4974V10.0307C11.0833 11.0108 11.0833 11.5009 10.8926 11.8752C10.7248 12.2045 10.4571 12.4722 10.1278 12.64C9.75347 12.8307 9.26342 12.8307 8.28333 12.8307H5.71667C4.73657 12.8307 4.24653 12.8307 3.87218 12.64C3.5429 12.4722 3.27518 12.2045 3.10741 11.8752C2.91667 11.5009 2.91667 11.0108 2.91667 10.0307V3.4974" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                    
                </div>
            </div>
            <ImageLinkPopover onSetLink={commands.onLink} editLinkPopoverOpen={editLinkPopoverOpen} setEditLinkPopoverOpen={toggleEditLinkPopover} onUnsetLink={onUnsetLink} showEdit={showEdit} setShowEdit={setShowEdit} link={isUrl} target={isImageTarget} setImageUrl={setUrl} setImageUrlTarget={setImageUrlTarget} setButtonAttribute={setButtonAttribute}/>
        </BubbleMenu>
    );
};

export default ImageBubbleMenu;
