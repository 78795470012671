import { Node, NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';

const SoundCloudNodeView = (props) => {
    const { node } = props;

    console.log({node})

    return (
        <NodeViewWrapper className="soundcloud-node-wrapper" data-type='soundCloud'>
            <div className="soundcloudEmbed" data-soundcloud-url={node.attrs.soundCloudUrl} >
                <iframe
                    src={`${node.attrs.soundCloudUrl}?auto_play=false`}
                    width="100%"
                    height="170"
                    frameBorder="0"
                    scrolling="no"
                    allowTransparency="true"
                    title="soundCloud"
                ></iframe>
            </div>
        </NodeViewWrapper>
    );
};


export const SoundCloudBlock = Node.create({
    name: 'soundCloudBlock',

    group: 'block',

    defining: true,

    isolating: true,

    addAttributes() {
        return {
            soundCloudUrl: {
                default: '',
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: `div[data-type="${this.name}"]`,
                getAttrs: (element) => {
                    const soundCloudUrl = element.getAttribute('data-soundcloud-url') || '';
                    return {
                        soundCloudUrl
                    };
                },
            },
        ];
    },

    renderHTML({ node }) {
        if (!node.attrs.soundCloudUrl) {
            return ['div', {}, 'Invalid soundCloud URL'];
        }
        return [
            'div',
            {
                'data-type': this.name,
                'data-soundcloud-url': node.attrs.soundCloudUrl,
                class: 'soundcloud-block soundcloud-embed-wrapper',
            },
            ['iframe', {
                src: `${node.attrs.soundCloudUrl}?auto_play=false`,
                width: '100%',
                height: '170',
                frameborder: '0',
                scrolling: 'no',
                allowtransparency: 'true',
            }]
        ];
    },

    addCommands() {
        return {
            setSoundCloudBlock: (options) => ({ commands }) => {
                if (!options?.soundCloudUrl) return false; // Prevent invalid URLs
                return commands.insertContent({
                    type: this.name,
                    attrs: {
                        soundCloudUrl: options.soundCloudUrl,
                    },
                });
            },            
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(SoundCloudNodeView); 
    },
});
