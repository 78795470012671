import { useRef, useState, useEffect } from 'react';
import '.././App.css';
import link from '.././images/link.svg'
import more from '.././images/more.svg'
import { Link } from 'react-router-dom';
import PageActionDropdown from '../Components/PageActionDropdown';

function PagesListItem(props) {
    let data = props.data;
    let type = props.type;
    let isOpen = props.isOpen;
    let togglePopup = props.togglePopup;

    const ref = useRef();

    const [activateEdit, setActivateEdit] = useState(false)

    const [checkboxActive, setCheckboxActive] = useState(false)

    useEffect(() => {
        if (props.allSelected === true) {
            setCheckboxActive(true)
        } else {
            setCheckboxActive(false)
        }
    }, [props.allSelected])

    var createdAt = data.createdAt;
    var updatedAt = data.updatedAt;

    var createdAtDate = new Date(createdAt);
    var updatedAtDate = new Date(updatedAt);

    var options = { year: 'numeric', month: 'short', day: 'numeric' };

    var formattedCreatedAt = createdAtDate.toLocaleDateString('en-US', options);
    var formattedUpdatedAt = updatedAtDate.toLocaleDateString('en-US', options);

    const handleCheckboxChange = (itemId, itemType) => {
        // if( itemType == 'home' || itemType == 'contact' ) {
        //     return false;
        // }
        props.handleCheckboxChange(itemId);
    };

    function updateActivateEdit(activateEdit) {
        setActivateEdit(activateEdit)
    }

    const changeStatus = (newStatus) => {
        data.status = newStatus;
        props.updateStatus(data._id, newStatus);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (isOpen && (event.target.closest('.pagesTableAction') === null && event.target.closest('.dropdown') === null) && !document.body.classList.contains('hidden-message')) {
                togglePopup();
            }
        }

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isOpen, togglePopup]);

    const profileSlug = localStorage.getItem('userProfileSlug')
    
    const pageUrl = `https://jourmal-next-front.vercel.app/${profileSlug}/${data.url_address}`;

    return (
        <div className="pagesTableItem pagesTableList">
            <div className='pagesTableCheck'><input type="checkbox" checked={props.selectedItems.includes(data._id)} onChange={() => handleCheckboxChange(data._id, data.type)} /> {data.status === "Deleted" ? data?.title : <Link className='editTitle' to={`/dashboard/page/${props.data._id}`}>{data.title}</Link>}</div>
            <div className='pagesTableStatus statusTab'><span className={(data.status === 'Published') ? 'greenStatus' : (data.status === 'Draft') ? 'draftStatus' : (data.status === 'Schedule') ? 'scheduleStatus' : 'deletedStatus'}> {data.status}</span></div>
            <div className='pagesTableFormat'>{formattedCreatedAt}</div>
            <div className='pagesTableUpdate lastUpdated'>{formattedUpdatedAt}</div>
            <div className='pagesTableLink'><Link target={'_blank'} to={`${pageUrl}`}><button className='pagesLinkButton'><img src={link} alt="Link" /> Link</button></Link></div>
            <div className='pagesTableAction' onClick={togglePopup} ref={ref}>
                <img src={more} alt="More" />
            </div>
            {
                (isOpen === true) ? <PageActionDropdown setShowNotification={props?.setShowNotification} togglePopup={togglePopup} changeStatus={changeStatus} updateOriginalData={props.updateOriginalData} updateActivateEdit={updateActivateEdit} type={type} data={data} index={props.data._id} /> : ''
            }
        </div>
    );
}

export default PagesListItem;
