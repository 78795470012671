import { Node, ReactNodeViewRenderer } from '@tiptap/react';
import { ProductBlockView } from './ProductBlockView';

export const Product = Node.create({
    name: 'product',

    group: 'block',

    defining: true,

    isolating: true,

    addAttributes() {
        return {
          image: {
            default: '',
          },
          producatimageAlt:{
            default: '',
          },
          productId: {
            default: '',
          },
          productKey: {
            default: '',
          },
          title: {
            default: '',
          },
          description: {
            default: '',
          },          
          showRating: {
            default: false,
          },
          rating: {
            default: 5,
          },
          showButton: {
            default: false,
          },
          buttonText: {
            default: 'Buy Now',
          },
          buttonUrl: {
            default: '',
          },
        };
    },

    parseHTML() {
        return [
          {
            tag: `div[data-type="${this.name}"]`,
            getAttrs: (dom) => {
              const img = dom.querySelector('img');
              return {
                image: img ? img.getAttribute('src') : '',
                title: dom.getAttribute('data-title') || '',
                description: dom.getAttribute('data-description') || '',
                showRating: dom.getAttribute('data-showRating') || false ,
                rating: dom.getAttribute('data-rating') || 5 ,
                producatimageAlt: dom.getAttribute('data-alt') || '',
                productId: dom.getAttribute('data-id') || '',
                productKey: dom.getAttribute('data-key') || '',
                showButton: dom.getAttribute('data-showButton') || false ,
                buttonText: dom.getAttribute('data-buttonText') || 'Buy Now',
                buttonUrl: dom.getAttribute('data-buttonUrl') || '',
              };
            },
          },
        ];
    },

    renderHTML({ HTMLAttributes }) {
      const {
          image,
          producatimageAlt,
          productId,
          productKey,
          title,
          description,
          showRating,
          rating,
          showButton,
          buttonText,
          buttonUrl,
      } = HTMLAttributes;

      const createStarSVG = () => [
        'svg', 
        {
            width: '18',
            height: '18',
            viewBox: '0 0 18 18',
            fill: 'none',
            xmlns: 'http://www.w3.org/2000/svg',
            stroke: '#F2AE40',
        }, 
        [
            'path', 
            {
                d: 'M17.75 6.69221L11.5925 5.95272L8.99671 0.25L6.40096 5.95272L0.25 6.69221L4.7975 10.9626L3.59491 17.125L8.99671 14.0538L14.4051 17.125L13.2025 10.9626L17.75 6.69221Z'
            }
        ]
      ];

      const totalStars = 5; 
      const stars = Array.from({ length: totalStars }, (_, index) => {
          const isHighlighted = index < rating;
          return [
              'span',
              {
                  class: `rate ${isHighlighted ? 'highlighted' : ''}`,
                  style: 'cursor: pointer;',
              },
              createStarSVG(isHighlighted)
          ];
      });

      const content = [
          'div',
          { 
            'data-type': 'product',
            'data-id' : productId,
            'data-key': productKey,
            'data-alt': producatimageAlt,
            'data-title': title,
            'data-description': description,
            'data-showRating': showRating,
            'data-rating': rating,
            'data-showButton': showButton,
            'data-buttonText' :showButton ? buttonText : '',
            'data-buttonUrl': showButton ? buttonUrl : '',
            class: 'product-block', 
          },
          [
              'figure',
              { class: 'product-figure' },
              image 
                  ? [
                      'img',
                      {
                          class: 'product-image',
                          src: image,
                          alt: producatimageAlt || 'Product Image',
                          style: 'width: 100%; height: auto;',
                      }
                    ]
                  : ['p', {}, 'No image available'],
            ],
            [
              'div',
              { class: 'product-title-wrapper' },
              title ? ['h2', { class: 'product-title' }, title] 
                    : ['h2', { class: 'product-title' }, ''] ,
                  ...(showRating && rating ? [
                    ['div', { class: 'rating' }, ...stars] // Insert the stars array here
                ] : [])
            ],
          description ? ['p', { class: 'product-description' }, description]
                      : ['p', { class: 'product-description' }, ''] ,
          ...(showButton
            ? buttonUrl
              ? [
                  [
                    'a',
                    { class: 'product-btn', href: buttonUrl, target: '_blank' },
                    ['span', { class: 'product-button' }, buttonText],
                  ],
                ]
              : [['span', { class: 'product-button' }, buttonText]]
            : []),
      ];
    
      return content;
    },

    addCommands() {
        return {
            setProduct:
                (options) =>
                ({ commands }) => {
                return commands.insertContent({
                    type: this.name,
                    attrs: {
                        image: options?.image || '',
                        productId: options?.productId || '',
                        productKey: options?.productKey || '',
                        caption: options?.caption || '',
                        producatimageAlt: options?.producatimageAlt || '',
                        title: options?.title || '',
                        description: options?.description || '',
                        showRating: options?.showRating || false,
                        rating: options?.rating || 5,
                        showButton: options?.showButton || false,
                        buttonText: options?.buttonText || '',
                        buttonUrl: options?.buttonUrl || '',
                    },
                });
            },
            updateProduct:
                (options) =>
                ({ commands }) => {
                return commands.updateAttributes(this.name, options);
            },
            isActive: () => ({ commands }) => {
                return commands.isActive(this.name);
            },
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(ProductBlockView);
    },
});