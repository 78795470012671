import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import RegStatus from './RegStatus';

import userAvatar from '../../images/user-avatar.svg';
import BgFormAnimate from '../../Pages/BgFormAnimate';
import logo from "../../images/register-logo.svg"

import '../.././App.css';
import axios from "axios";

function Step2(props) {
    const [displayName, setDisplayName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [title, setTitle] = useState('');
    const [short, setShort] = useState('');
    const [profileImageSrc, setprofileImageSrc] = useState('')
    const [isLoading, setIsLoading] = useState(false);

    const [displayNameValidate, setDisplayNameValidate] = useState(false);
    const [titleValidate, setTitleValidate] = useState(false);
    const [ShortValidate, setShortValidate] = useState(false);

    useEffect(() => {
        var data = props.data[1].data[0]

        var profileData = props.data[3]?.data[0] || ''
        if (profileData) {
            setprofileImageSrc(profileData.profileImageSrc);
        }

        if (data !== undefined && data != []) {
            setDisplayName(data.displayName)
            setFirstName(data.firstName);
            setTitle(data?.title || '')
            setShort(data?.desc || '')
        }
    }, [])

    const handleKeyPress = (e) => {
        if (e?.key === "Enter") {
            e.preventDefault(); 
            handleSubmit(); 
        }
    };

    const handleSubmit = async () => {
        const storedUserInfo = localStorage.getItem("userinfo");
        if (storedUserInfo ) {
            if (ShortValidate === true && titleValidate === true && displayNameValidate === true) {
                return false;
            }
            if (title.length > 0 && short.length > 0 && displayName.length > 0) {
                var copy = [...props.data]

                const displayNameIndex = copy[1].data.findIndex(item => item.hasOwnProperty('displayName'));

                if (displayNameIndex !== -1) {
                    // Update displayName if it exists
                    copy[1].data[displayNameIndex] = {
                        displayName: displayName,
                        title: title,
                        desc: short
                    };
                } else {
                    // Add new entry if displayName doesn't exist
                    copy[1].data.push({
                        displayName: displayName,
                        title: title,
                        desc: short
                    })
                }

                copy[1].status = 'done'
                props.addData(copy)
                props.step(3)
            }
            if (title.length === 0) {
                setTitleValidate(true);
            }
            if (short.length === 0) {
                setShortValidate(true);
            }
            if (displayName.length === 0) {
                setDisplayNameValidate(true);
            }
        } else {
            setIsLoading(true);
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/user/${localStorage.getItem("userId")}`
                );
                const storedUserId = localStorage.getItem("userId");
                const matchedUser = response?.data?.result?.user
                if (response?.data?.result?.user?._id === storedUserId) {
                    const setData = {
                        createdAt: response?.data?.result?.user?.createdAt,
                        token: response?.data?.result?.user?.verifyToken,
                        isCompleted: response?.data?.result?.user?.isCompleted,
                        _id: response?.data?.result?.user?._id,
                        status: true
                    }
                    localStorage.setItem("userinfo", JSON.stringify(setData));
                    localStorage.setItem("isCompleted", "false");
                } else {
                    console.log("No matching user found");
                }
            } catch (error) {
                console.log(error)
            }
            if (ShortValidate === true && titleValidate === true && displayNameValidate === true) {
                return false;
            }
            if (title.length > 0 && short.length > 0 && displayName.length > 0) {
                var copy = [...props.data]

                const displayNameIndex = copy[1].data.findIndex(item => item.hasOwnProperty('displayName'));

                if (displayNameIndex !== -1) {
                    // Update displayName if it exists
                    copy[1].data[displayNameIndex] = {
                        displayName: displayName,
                        title: title,
                        desc: short
                    };
                } else {
                    // Add new entry if displayName doesn't exist
                    copy[1].data.push({
                        displayName: displayName,
                        title: title,
                        desc: short
                    })
                }

                copy[1].status = 'done'
                props.addData(copy)
                props.step(3)
            }
            if (title.length === 0) {
                setTitleValidate(true);
            }
            if (short.length === 0) {
                setShortValidate(true);
            }
            if (displayName.length === 0) {
                setDisplayNameValidate(true);
            }

            setIsLoading(false);
        }

    }
    props.step(2)

    return (
        <>
            <BgFormAnimate />
            <div>
                <div className='step register-content'>
                    <div className="regLogo">
                        <img src={logo} alt="logo" />
                    </div>
                    <div className='step_content step-basic-details'>
                        <div className="step-user-avatar">
                            {/* <div className='user-avatar-wrapper'>
                                <img src={userAvatar} alt="userAvatar" />
                                {profileImageSrc && <img src={profileImageSrc} alt="userAvatar" className="user-avatar" />}
                            </div> */}
                            <h2 className='step_title'>Hey <span className="user-firstName step_title">{firstName}</span>, welcome to Persone!</h2>
                            <p className='step_contentSubtitle'>
                                Enter your display name, job title and a short bio summarizing your professional background.
                            </p>
                        </div>

                        <div className='step_content_wrapp'>
                            <div className='user_email'>
                                <h2 className='label-text'>Display Name</h2>
                                <input
                                    autoFocus={true}
                                    value={displayName}
                                    onChange={e => {
                                        setDisplayName(e.target.value)

                                        if (e.target.value.length > 0) {
                                            setDisplayNameValidate(false)
                                        }
                                    }
                                    }
                                    placeholder='Your Name'
                                    className={(displayNameValidate === true) ? 'first_name inputError' : 'first_name'} type="text" />
                                {/* {
                                    (displayNameValidate === true) ? <p className='validateError'>The field is not filled</p> : ''
                                } */}
                                <p className='step-warning'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                        <g clip-path="url(#clip0_1169_15075)"><path d="M6.99984 9.33366V7.00033M6.99984 4.66699H7.00567M12.8332 7.00033C12.8332 10.222 10.2215 12.8337 6.99984 12.8337C3.77818 12.8337 1.1665 10.222 1.1665 7.00033C1.1665 3.77866 3.77818 1.16699 6.99984 1.16699C10.2215 1.16699 12.8332 3.77866 12.8332 7.00033Z" stroke="#525866" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" /></g>
                                        <defs><clipPath id="clip0_1169_15075"><rect width="14" height="14" fill="white" /></clipPath></defs>
                                    </svg>
                                    Enter your full name or include a professional title (e.g., Dr., J.D., Prof.) if you prefer.
                                </p>
                            </div>
                            <div className='user_email'>
                                <h2 className='label-text'>Job title</h2>
                                <input value={title}
                                    onChange={e => {
                                        setTitle(e.target.value)

                                        if (e.target.value.length > 0) {
                                            setTitleValidate(false)
                                        }
                                    }
                                    } placeholder='CEO, Google' className={(titleValidate === true) ? 'first_name inputError' : 'first_name'} type="text" />
                                {/* {
                                    (titleValidate === true) ? <p className='validateError'>The field is not filled</p> : ''
                                } */}
                            </div>
                            <div className='user_email'>
                                <h2 className='label-text'>Short Bio</h2>
                                <textarea
                                    value={short}
                                    onChange={e => {
                                        if (e.target.value.length > 350) {
                                            e.target.value.substring(0, 350)
                                        } else {
                                            setShort(e.target.value)
                                            if (e.target.value.length > 0) {
                                                setShortValidate(false)
                                            }
                                        }
                                    }
                                    }
                                    onKeyPress={(e) => { handleKeyPress(e) }}
                                    placeholder='Write a short bio about yourself...' className={(ShortValidate === true) ? 'short_text inputError' : 'short_text'} type="text" />

                                <p className='step_charactersLeft'> {short.length} / 350</p>
                                {/* {
                                    (ShortValidate === true) ? <p className='validateError'>The field is not filled</p> : ''
                                } */}
                            </div>

                            <div className="regButtons regBack">
                                <button
                                    onClick={() => { handleSubmit() }} className={`btn_toStep ${title.length === 0 || short.length === 0 || displayName.length === 0 ? 'disabledBtn' : ''}`}
                                >
                                    {isLoading ? (
                                        <div className="settingPage-loader">
                                            <div className="ring-loader"></div>
                                        </div>
                                    ) : (
                                        <>
                                        Continue
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M10.796 9.99956L7.0835 6.28706L8.144 5.22656L12.917 9.99956L8.144 14.7726L7.0835 13.7121L10.796 9.99956Z" fill="white" />
                                        </svg>
                                        </>
                                    )}                                    
                                </button>
                            </div>
                            <div className='register_nav'>
                                <RegStatus statusReg={props.data} setStatusReg={props.addData} activeStep={props.activeStep} setActiveStep={props.step} />
                            </div>
                            {/* <div className='regContact'>
                                <p>
                                    Need help? Feel free to <Link to={"/contact"}> Contact us </Link>
                                </p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default Step2;