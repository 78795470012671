import { useEffect, useRef } from "react";
import { Routes, Route, Link } from 'react-router-dom';
import axios from "axios";

import home from '.././images/sidebar/icon-home.svg';
import plus from '.././images/plus-icon.svg'
import link from ".././images/link.svg"
import avatar from '.././images/profile-user.svg'
import down from ".././images/Settings/down.svg";
import iconDivider from ".././images/chevron-right-light.svg";

import Home from './Home';
import { useState } from 'react';
import Subscribers from './Subscribers';
import MediaGallery from './MediaGallery';
import Newsletter from './Newsletter';
import NewPage from './NewPage';
import NewPublication from './NewPublication';
import Error from './Error';
import Analytics from './Analytics';
import Pages from './Pages';
import Publications from './Publications';
import NewNewsletter from './NewNewsletter';
import Categories from './Categories';
import ProfileAssistant from './ProfileAssistant';
import Settings from '../Pages/Settings';
import MonetizationAnalytics from './MonetizationAnalytics';

import Footer from '../Components/Footer';
import Menu from '../Components/Menu';
import ChangePlan from "../Components/Settings/ChangePlan";

import '.././App.css';
import MenuDropdown from "../Components/MenuDropdown";
import AddNewDropdown from "../Components/AddNewDropdown";
import NotificationMessage from "../Components/NotificationMessage";
import { InvitationModel } from "../Components/Modal/InvitationModel";
import { ChangePlanSuccessModel } from "../Components/Modal/ChangePlanSuccessModel";
import { PreviewNewsletter } from "./PreviewNewsletter/PreviewNewsletter";
import { PreviewPublication } from "./PreviewPublication/PreviewPublication";
import { PreviewPage } from "./PreviewPage/PreviewPage";
import { PreviewHome } from "./PreviewHome/PreviewHome";
import { PreviewContact } from "./PreviewContact/PreviewContact";

function Dashboard() {
    const [activePage, setActivePage] = useState('Home');
    const [menuActive, setMenuActive] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [addTabActive, setAddTabActive] = useState(false)
    const [showStickyMenu, setShowStickyMenu] = useState(false);
    const [profileSlug, setProfileSlug] = useState("");
    const [avatarImage, setAvatarImage] = useState(avatar);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [userData, setuserData] = useState({});
    const [isOpenInvitationPopup, setOpenInvitationPopup] = useState(false)
    const [isLoading, setIsLoading] = useState(false); 
    const [invitateTo, setInvitateTo] = useState('');
    const [invitationError, setInvitationError] = useState(false);
    const [invitationMessage, setInvitationMessage] = useState('');
    const [invitationNotification, setInvitationNotification] = useState(false);
    const [openSuccessPlanPopup, setOpenSuccessPlanPopup] = useState(false)
    const [userMetaData, setUserMetaData] = useState({})
    const [sendInvitation, setSendInvitation] = useState(false)
    const [canSendNewsletter, setCanSendNewsletter] = useState(false)
    const stickymenu = useRef()
    const addRef = useRef()
    
    const toggleMenu = () => {
        const body = document.body;
        if (menuActive) {
          body.classList.remove('hide-menu');
        } else {
          body.classList.add('hide-menu');
        }
        setMenuActive(!menuActive);
    };

    const handleMouseEnter = () => {
        if (menuActive) {
            setIsHovered(true);
        }
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const resetMenuActive = () => {
        setMenuActive(false);
        document.body.classList.remove('hide-menu');
    };

    const sidebarClasses = [
        'sidebar',
        isHovered ? 'hover-menu' : '',
    ].filter(Boolean).join(' ');

    useEffect(() => {
        fetchData();
    }, [activePage]);

    const [billingDetails, setBillingDetails] = useState({});
    const [showUpgradeModel, setShowUpgradeModel] = useState(false);

    const toConvertUppercase = (string) => {
        const words = string?.split(" ");

        const capitalizedWords = words?.map(
            (word) => word?.charAt(0).toUpperCase() + word?.slice(1)
        );
        const capitalizedString = capitalizedWords?.join(" ");
        return capitalizedString;
    };

    const fetchData = async () => {
        try {
            const billingDetailsResponse = await axios.get(
            `${process.env.REACT_APP_API_URL}/user/getBillingDetails/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
            )

            setBillingDetails(billingDetailsResponse?.data?.result);
            setShowUpgradeModel(false);
        } catch (error) {
            console.log(error, "error");
        }
    }
const handleInvitateToChange = (event) => {
        setIsLoading(false);
        setInvitationError(false);
        setInvitationMessage('');
        setInvitateTo(event.target.value);
    }

    const handleInvitateToKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSendInvitation();
        }
    }

    const handleSendInvitation = async () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        setIsLoading(true);

        setInvitationError(false);
        setInvitationMessage('');

        if (!emailPattern.test(invitateTo)) {
            setInvitationError(true)
            setInvitationMessage('Please enter a valid email address.');
            setIsLoading(false);
            return;
        }

        const data = {
            email: invitateTo,
            name: `${firstName}  ${lastName}`,
            invitedFrom: JSON.parse(localStorage.getItem("userinfo"))?._id
        };

        await axios.put(`${process.env.REACT_APP_API_URL}/user/sendinvitation`, data)
            .then((response) => { 
                if( response.status === 200 ) {
                    setInvitationMessage(`<b>Invite Sent!</b> Sent to ${invitateTo}`);
                    setInvitationNotification(true);
                    setOpenInvitationPopup(false);
                    setSendInvitation(true)
                } else {
                    setInvitationError(true)
                    setInvitationMessage(response.data.message);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setInvitationError(true)
                setInvitationMessage(error.messager);
                setIsLoading(false);
            });
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/user/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
                )
                const userData = response?.data?.result?.user;
                const userMediaData = response?.data?.result?.userMediaData;
                const userMeta = response?.data?.result?.userMeta;

                const firstName = toConvertUppercase(userData?.firstName);
                const lastName = toConvertUppercase(userData?.lastName);

                setFirstName(firstName);
                setLastName(lastName);
                setuserData(userData);
                setProfileSlug(userMeta.profileSlug);
                setUserMetaData(userMeta)

                if(userMeta?.profileMedia?.cropedImage) {
                    setAvatarImage(userMediaData.profileMeida.cropedMedia.url)
                }

            } catch (error) {
                console.log(error, "error");
            }
        } 
        fetchData();
    }, [sendInvitation]);

    useEffect (()=>{
        const checkIfClickedOutside = (e) => {
            if (showStickyMenu && stickymenu.current && !stickymenu.current.contains(e.target)) {
                setShowStickyMenu(false);
            }
            
        }
        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };

    },[showStickyMenu])   
    useEffect (()=>{
        const checkIfClickedOutside = (e) => {
            
            if (addRef && addRef.current && !addRef.current.contains(e.target)) {
                setAddTabActive(false);
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };

    },[addTabActive])
    
    return (
        <div className="main-wrapper">
            {
                ((activePage !== "Preview Newsletter") && (activePage !== "Preview Publication") && (activePage !== "Preview Page") && (activePage !== "Preview Home") && (activePage !== "Preview Contact")) &&
                <div
                    className={sidebarClasses}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <Menu activePage={activePage} toggleMenu={toggleMenu} menuActive={menuActive} setHover={setIsHovered} resetMenuActive={resetMenuActive} billingDetails={billingDetails} paymentMethods={billingDetails?.paymentMethods} isStipeConnected={billingDetails.isStipeConnected} updateData={fetchData} isExpired={billingDetails?.permission?.isSubscriptionExpired} planDetails={billingDetails?.subscriptions} />
                </div>
            }
            <div className={`main-content ${((activePage === "Preview Newsletter") || (activePage === "Preview Publication") || (activePage === "Preview Page") || (activePage === "Preview Home") ||  (activePage === "Preview Contact")) ? `preview-main-content` : '' }`}>
            {
                ((activePage !== "Preview Newsletter") && (activePage !== "Preview Publication") && (activePage !== "Preview Page") && (activePage !== "Preview Home") && (activePage !== "Preview Contact")) &&
                <div className={`sticky-menu stickyBar ${menuActive ? 'fullStickyBar' : ''}`}>
                    <div className="breadcrumbs home-breadcrumbs">

                        {((activePage == "New page") || (activePage == "New Publication") || (activePage == "New Newsletter")) ? (
                            <>
                                <Link to={'/dashboard/home'} ><img src={home} className="home-icon" alt="home-icon" /></Link>

                                <img className="divider" src={iconDivider} alt="dropdown" />

                                <Link to={`${activePage == "New page" ? '/dashboard/pages' : (activePage == "New Publication") ? "/dashboard/publications" : (activePage == "New Newsletter") ? "/dashboard/newsletters" : ""}`} >
                                    <span className="">{`${activePage == "New page" ? 'Pages' : (activePage == "New Publication") ? "Publications" : (activePage == "New Newsletter") ? "Newsletters" : ""}`}</span>
                                </Link>

                                <img className="divider" src={iconDivider} alt="dropdown" />
                                <Link to={`${activePage == "New page" ? '/dashboard/page' : (activePage == "New Publication") ? "/dashboard/publication" : (activePage == "New Newsletter") ? "/dashboard/newsletter" : ""}`} >
                                    <span className="">{`${activePage == "New page" ? 'New Page' : (activePage == "New Publication") ? "New Publication" : (activePage == "New Newsletter") ? "New Newsletter" : ""}`}</span>
                                </Link>
                            </>
                        ) :
                            (<>
                                <Link to={'/dashboard/home'} ><img src={home} className="home-icon" alt="home-icon" /></Link>
                                <img className="divider" src={iconDivider} alt="dropdown" />
                                <Link to={
                                    activePage === "ProfileAssistant" ? '/dashboard/profile-assistant' :
                                        activePage === "monetization-analytics" ? '/dashboard/monetization-analytics' :
                                            activePage === "MediaGallery" ? '/dashboard/media-library' :
                                                activePage === "Page" ? '/dashboard/pages' :
                                                    activePage === "Publication" ? '/dashboard/publications' :
                                                        activePage === "Newsletter" ? '/dashboard/newsletters' :
                                                            activePage === "Categories" ? '/dashboard/categories' :
                                                                activePage === "Subscribers" ? '/dashboard/subscribers' :
                                                                    activePage === "Contact-Analytics" ? '/dashboard/analytics' :
                                                                        activePage === "Settings" ? '/dashboard/settings' :
                                                                            activePage === "Preview Newsletter" ? '/dashboard/previewnewsletter' :
                                                                                activePage === "Preview Publication" ? '/dashboard/previewpublication' :
                                                                                    activePage === "Preview Page" ? '/dashboard/previewpage' :
                                                                                        activePage === "Preview Home" ? '/dashboard/previewhome' :
                                                                                            activePage === "Preview Contact" ? '/dashboard/previewcontact' :
                                                                                '/dashboard/home'
                                } ><span className="">{activePage === "ProfileAssistant" ? "Profile Assistant" : activePage === "Contact-Analytics" ? "Content Analytics" : activePage === "monetization-analytics" ? "Monetization Analytics" : activePage === "MediaGallery" ? "Media Library" : activePage}</span></Link>
                            </>)
                        }
                    </div>
                    <div className='dropdownSide'>
                        <div className="addNew addNewBtn" ref={addRef}>
                            <button className={(addTabActive === true) ? 'buttonActive' : ''} onClick={(e) => { setAddTabActive(!addTabActive); }}><img src={plus} alt="Icon" /> <span>&nbsp;&nbsp;Add new</span></button>
                            {
                                (addTabActive === true) ? <AddNewDropdown setClose={setAddTabActive} setActivePage={setActivePage} /> : ''
                            }
                        </div>
                        <Link className='websiteLink' to={`https://jourmal-next-front.vercel.app/${profileSlug}`} target="_blank">
                            <img src={link} alt="link" />
                            <span>Personal Website</span>
                        </Link>
                        <div className='dropDown' ref={stickymenu}>
                            <div className="avatarVisible menuDropdown" onClick={() => { setShowStickyMenu(!showStickyMenu) }} >
                                <div className='sidebar-user'>
                                    <img className='avatar' src={avatarImage} alt={{ firstName }} ref={stickymenu} />
                                </div>
                                <img src={down} alt='down' />
                            </div>
                            {showStickyMenu &&
                                <MenuDropdown userData={userData} lastName={lastName} firstName={firstName} setShowStickyMenu={setShowStickyMenu} toggleMenu={toggleMenu} billingDetails={billingDetails} planDetails={billingDetails?.subscriptions} billingData={fetchData} setShowUpgradeModel={setShowUpgradeModel} setOpenInvitationPopup={setOpenInvitationPopup} setInvitateTo={setInvitateTo} setInvitationMessage={setInvitationMessage} />
                            }
                        </div>
                    </div>
                </div>
            }
                <Routes>
                    <Route path='/' element={<Home setActivePage={setActivePage} toggleMenu={toggleMenu} billingDetails={billingDetails} planDetails={billingDetails?.subscriptions} billingData={fetchData} />}></Route>
                    <Route path='/home' element={<Home setActivePage={setActivePage} toggleMenu={toggleMenu} billingDetails={billingDetails} planDetails={billingDetails?.subscriptions} billingData={fetchData} />}></Route>
                    <Route path="/settings/*" element={<Settings setActivePage={setActivePage} billingDetails={billingDetails} planDetails={billingDetails?.subscriptions} paymentMethods={billingDetails?.paymentMethods} isStipeConnected={billingDetails.isStipeConnected} updateData={fetchData} isExpired={billingDetails?.permission?.isSubscriptionExpired} />}></Route>
                    <Route path='/pages' element={<Pages setActivePage={setActivePage} />}></Route>
                    <Route path='/publications' element={<Publications setActivePage={setActivePage} />}></Route>
                    <Route path='/subscribers' element={<Subscribers setActivePage={setActivePage} billingDetails={billingDetails} userMetaData={userMetaData} />}></Route>
                    <Route path='/media-library' element={<MediaGallery setActivePage={setActivePage} billingDetails={billingDetails} billingData={fetchData} showUpgradeModel={showUpgradeModel} setShowUpgradeModel={setShowUpgradeModel} />}></Route>
                    <Route path='/newsletters' element={<Newsletter setActivePage={setActivePage} />}></Route>
                    <Route path='/page' element={<NewPage setActivePage={setActivePage} setShowUpgradeModel={setShowUpgradeModel} planDetails={billingDetails.subscriptions} userMetaData={userMetaData} />}></Route>
                    <Route path='/page/:id' element={<NewPage setActivePage={setActivePage} setShowUpgradeModel={setShowUpgradeModel} planDetails={billingDetails.subscriptions} userMetaData={userMetaData} />}></Route>
                    <Route path='/publication' element={<NewPublication setActivePage={setActivePage} billingDetails={billingDetails} billingData={fetchData} showUpgradeModel={showUpgradeModel} setShowUpgradeModel={setShowUpgradeModel} userMetaData={userMetaData} />}></Route>
                    <Route path='/publication/:id' element={<NewPublication setActivePage={setActivePage} billingDetails={billingDetails} billingData={fetchData} showUpgradeModel={showUpgradeModel} setShowUpgradeModel={setShowUpgradeModel} userMetaData={userMetaData} />}></Route>
                    <Route path='/newsletter' element={<NewNewsletter setActivePage={setActivePage} billingDetails={billingDetails} billingData={fetchData} showUpgradeModel={showUpgradeModel} setShowUpgradeModel={setShowUpgradeModel} setCanSendNewsletter={setCanSendNewsletter}/>}></Route>
                    <Route path='/newsletter/:id' element={<NewNewsletter setActivePage={setActivePage} billingDetails={billingDetails} billingData={fetchData} showUpgradeModel={showUpgradeModel} setShowUpgradeModel={setShowUpgradeModel} setCanSendNewsletter={setCanSendNewsletter}/>}></Route>
                    <Route path='/categories/' element={<Categories setActivePage={setActivePage} />}></Route>
                    <Route path='/analytics' element={<Analytics setActivePage={setActivePage} />}></Route>
                    {/* <Route path='/monetization-analytics' element={<MonetizationAnalytics setActivePage={setActivePage} />}></Route> */}
                    <Route path='/monetization-analytics' element={billingDetails?.subscriptions?.plan === 'free_plan' ? (<Home setActivePage={setActivePage} toggleMenu={toggleMenu} billingDetails={billingDetails} planDetails={billingDetails?.subscriptions} billingData={fetchData} />) : (<MonetizationAnalytics setActivePage={setActivePage} />)}></Route>
                    <Route path='/profile-assistant' element={<ProfileAssistant setActivePage={setActivePage} />}></Route>
                    <Route path='*' element={<Error setActivePage={setActivePage} menuDisabled={true} />}></Route>
                    <Route path='/previewnewsletter/:id' element={<PreviewNewsletter setActivePage={setActivePage} userMetaData={userMetaData} canSendNewsletter={canSendNewsletter}/>}></Route>
                    <Route path='/previewpublication/:id' element={<PreviewPublication setActivePage={setActivePage} userMetaData={userMetaData} />}></Route>
                    <Route path='/previewpage/:id' element={<PreviewPage setActivePage={setActivePage} userMetaData={userMetaData} />}></Route>
                    <Route path='/previewhome/:id' element={<PreviewHome setActivePage={setActivePage} userMetaData={userMetaData} />}></Route>
                    <Route path='/previewcontact/:id' element={<PreviewContact setActivePage={setActivePage} userMetaData={userMetaData} />}></Route>
                </Routes>
                {
                    (activePage !== "Preview Newsletter" &&  activePage !== "Preview Publication" && activePage !== "Preview Page" && (activePage !== "Preview Home") && (activePage !== "Preview Contact")) &&
                        <div className="container">
                            <Footer menuActive={menuActive} />
                        </div>
                }
            </div>

            {(billingDetails && billingDetails?.permission?.isSubscriptionExpired === 'yes') &&
                <ChangePlan planDetails={billingDetails.subscriptions} paymentMethods={billingDetails.paymentMethods} isStipeConnected={billingDetails.isStipeConnected} updateData={fetchData} isExpired={billingDetails.permission.isSubscriptionExpired} setOpenSuccessPlanPopup={setOpenSuccessPlanPopup} openSuccessPlanPopup={openSuccessPlanPopup} />
            }
            {(showUpgradeModel) &&
                <ChangePlan planDetails={billingDetails.subscriptions} paymentMethods={billingDetails.paymentMethods} isStipeConnected={billingDetails.isStipeConnected} updateData={fetchData} isExpired={billingDetails.permission.isSubscriptionExpired} setIsChangePlanOpen={setShowUpgradeModel} isChangePlanOpen={showUpgradeModel} setOpenSuccessPlanPopup={setOpenSuccessPlanPopup} openSuccessPlanPopup={openSuccessPlanPopup} />
            }
            {isOpenInvitationPopup && <InvitationModel setOpenInvitationPopup={setOpenInvitationPopup} isOpenInvitationPopup={isOpenInvitationPopup} handleInvitateToChange={handleInvitateToChange} handleInvitateToKeyPress={handleInvitateToKeyPress} handleSendInvitation={handleSendInvitation} invitateTo={invitateTo} isLoading={isLoading} invitationError={invitationError} invitationMessage={invitationMessage} setIsLoading={setIsLoading} setInvitateTo={setInvitateTo} setInvitationError={setInvitationError} setInvitationMessage={setInvitationMessage} userMetaData={userMetaData} />}
            {openSuccessPlanPopup &&
                <ChangePlanSuccessModel setOpenSuccessPlanPopup={setOpenSuccessPlanPopup} openSuccessPlanPopup={openSuccessPlanPopup} plan={billingDetails?.subscriptions?.plan} />
            }
            {invitationNotification &&
                <NotificationMessage
                    openNotify={invitationNotification}
                    setOpenNotify={setInvitationNotification}
                    type={'success'}
                    title={`Invitation sent to ${invitateTo}`} />
            }
        </div>
    );
}

export default Dashboard;