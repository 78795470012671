import { useCallback } from 'react';

const ColorButton = ({ color, active, onColorChange }) => {

  const handleClick = useCallback(() => {
    if (onColorChange) {
      onColorChange(color || '');
    }
  }, [onColorChange, color]);

  return (
    <button onClick={handleClick} className={`colorpicker-button ${active ? 'active' : ''}`}>
      <div style={{ backgroundColor: color, color: color }} className={`${active ? 'active' : ''}`}></div>
    </button>
  );
};

export default ColorButton;
