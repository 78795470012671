import '../.././App.css';
import AdSense from '../../images/Settings/adSense.png';
import Stripe from '../../images/Settings/stripe2.png';
import PlusIcon from '../../images/plus.svg';
import deleteIcon from '../../images/delete.svg'
import more from '../../images/more.svg'
import selectDown from "../../images/Settings/select_down.svg"
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { MonetizationModal } from '../../Components/Modal/monetizationModal';
import { Link } from 'react-router-dom';
import BillingItemList from '../../Components/Settings/BillingItemList';
import dateFormatter from '../../utils/dateFormatter';
import Select, { components } from "react-select";

import currencies from '../../utils/currency.json'
import NotificationMessage from '../../Components/NotificationMessage';

export default function Monetization(props) {

    const refAction = useRef();
    
    props.setActivePage("Monetization");
    const [monetization, setMonetization] = useState({
        displayAd: false,
        googleAd: "Disconnect",
        StripeConnected: false,
        StripeConnectedTo: '',
        subsTxt: "",
        subsCancelTxt: "",
        planName: "Free Plan",
        planPrice: "",
        planDescription: "",
        planSuccessText: "",
        planNameOne: "",
        pricePerMonthOne: "",
        pricePerYearOne: "",
        descriptionOne: "",
        successTextOne: "",
        deleteReq: false,
    })

    const [isLoading, setIsLoading] = useState(false);
    const [cancelLoading, setCancelLoading] = useState(false);
    const [isPlanOpen, setPlanOneOpen] = useState(false)
    const [errorMessage, setErrorMessage] = useState();
    const [isDeletePlan, setDeletePlan] = useState(false)
    const [isPriceChanged, setIsPriceChanged] = useState(false)
    const [isPriceChangeOpen, setIsPriceChangeOPen] = useState(false)
    const [isHasPlanOne, setIsHasPlanOne] = useState(false)
    const [isDisconnectStripe, setDisconnectStripe] = useState(false)
    const [shouldCreateNew, isShouldCreateNew] = useState(false)

    const [isSripeLoading, setIsStripeLoading] = useState(false);
    const [stipeConnectError, setStipeConnectError] = useState('');
    const [stripeable, setStripeable] = useState(false);

    const [stripeLoginUrl, setStripeLoginUrl] = useState('');
    const [showPopup, setShowopup] = useState(true);

    const [invoices, setInvoices] = useState([])
    const [payouts, setPayuts] = useState([])
    const [activeTab, setActiveTab] = useState('Transactions')
    const [isActionOpen, setIsActionOpen] = useState(false)
    const [notification, setNotification] = useState(false);
    const [stripeConnectNotify, setStripeConnectNotify] = useState(false);
    const [stripeDisConnectNotify, setStripeDisConnectNotify] = useState(false);
    const [googleConnect, setGoogleConnect] = useState(false);
    const [googleDisConnect, setGoogleDisConnect] = useState(false);
    const [subscriberCount, setSubscriberCount]= useState(0)
    const [subscriberNotify, setSubscriberNotify] = useState(false);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (refAction.current && !refAction.current.contains(e.target) && (e.target.classList.contains('dropdown'))) {
                // setActivateEdit(false);
                setIsActionOpen(false)
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, []);

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/user/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
            )
            const userMeta = response?.data?.result?.userMeta;
            setStripeable(response?.data?.result?.canConnectStipe);
            setStripeLoginUrl(response?.data?.result?.stripeLoginUrl || '#')
            setShowopup(response?.data?.result?.hasPaidSubscribers)
            setSubscriberCount(response?.data?.subscriberCount)

            setMonetization({
                displayAd: userMeta?.googleAds?.displayAd || false,
                googleAd: userMeta?.googleAds?.googleAd || "Disconnect",
                StripeConnected: userMeta?.stripeAccountData?.details_submitted || false,
                StripeConnectedTo: userMeta?.stripeAccountData?.details_submitted ? (userMeta?.stripeAccountData?.email || '') : '',
                subsTxt: userMeta?.subsTxt || "Receive regular updates and insights directly to your email, keeping you informed and engaged.",
                subsCancelTxt: userMeta?.subsCancelTxt || "I’m sorry to see you go! If you decide to cancel your subscription, you will no longer receive regular updates and insights directly to your email.",
                planName: userMeta?.subsPlans?.freePlan?.planName || "Free Plan",
                planPrice: userMeta?.subsPlans?.freePlan?.planPrice || 0,
                planDescription: userMeta?.subsPlans?.freePlan?.planDescription || "",
                planSuccessText: userMeta?.subsPlans?.freePlan?.planSuccessText || "",
                planNameOne: userMeta?.subsPlans?.planOne?.planNameOne || "",
                pricePerMonthOne: userMeta?.subsPlans?.planOne?.pricePerMonthOne || 0,
                pricePerYearOne: userMeta?.subsPlans?.planOne?.pricePerYearOne || 0,
                descriptionOne: userMeta?.subsPlans?.planOne?.descriptionOne || "",
                successTextOne: userMeta?.subsPlans?.planOne?.successTextOne || "",
                deleteReq: userMeta?.stripeAccountData?.deleteReq || false
            });

            if (userMeta?.subsPlans?.planOne?.planNameOne) {
                setPlanOneOpen(true);
                setIsHasPlanOne(true);
                setIsPriceChanged(false)
            }
            if(response?.data?.subscriberCount > 0){
                setSubscriberNotify(true)
            }

            const invoicesData = await axios.get(
                `${process.env.REACT_APP_API_URL}/subscriber/getInvoices/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
            )

            setInvoices(invoicesData?.data?.result?.invoices || [])
            const payoutsData = invoicesData?.data?.result?.invoices.filter(item => item.type === "payout") || []
            setPayuts(payoutsData)

            setCancelLoading(false)
        } catch (error) {
            setCancelLoading(false);
            console.log(error, "error");
        }
    }
    const colourStyles = {
        option: (styles, { isFocused, isSelected, isDisabled }) => {
          return {
            ...styles,
            backgroundColor: isSelected
              ? "#007AFF"
              : isFocused
                ? "#f9f9f9"
                : "transparent",
            fontSize: "14px",
            cursor: "pointer",
          };
        },
        input: (provided, state) => ({
          ...provided,
          height: "40px",
          padding: 0,
          margin: 0,
          borderRadius: "8px",
          fontSize: "14px",
          cursor: "pointer",
        }),
        noOptionsMessage: (provided, state) => ({
          ...provided,
          fontSize: "14px",
        }),
        singleValue: (provided, state) => ({
          ...provided,
          fontSize: "14px",
          color: "#101828",
        }),
        control: (provided, state) => ({
          ...provided,
          border: "none",
          borderRadius: "8px",
          width: "100%",
        }),
        control: (base) => ({
          ...base,
          border: 0,
          boxShadow: "none !important",
          borderRadius: "8px",
          marginRTop: "2px",
        }),
        container: (provided, state) => ({
          ...provided,
          paddingLeft: "0px",
        }),
        placeholder: (styles, { isFocused }) => {
          return {
            ...styles,
            marginBottom: isFocused ? "0px" : "0px",
            fontSize: "14px",
            marginLeft: "0",
            color: "#667085",
          };
        },
        indicatorSeparator: (state) => ({
          display: "none",
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          borderRadius: "8px",
          outline: "none",
          marginRight: "5px",
        }),
        valueContainer: (provided, state) => ({
          ...provided,
          paddingLeft: "15px",
          paddingRight: "15px",
        }),
    };

    const handleChange = (e) => {
        setMonetization((prevMonetization) => ({ ...prevMonetization, [e.target.name]: e.target.value }));
        setErrorMessage((prevErrorMessage) => ({ ...prevErrorMessage, [e.target.name]: '' }));
    };

    const handlePriceChange = (e) => {
        if (isHasPlanOne) {
            setIsPriceChanged(true);
        }

        setMonetization((prevMonetization) => ({ ...prevMonetization, [e.target.name]: e.target.value }));
        setErrorMessage((prevErrorMessage) => ({ ...prevErrorMessage, [e.target.name]: '' }));
    }

    const handleSubmit = async () => {
        const errors = {
            planName: "",
            planPrice: "",
            subsTxt: '',
            subsCancelTxt: "",
            planDescription: "",
            planSuccessText: "",
            planNameOne: "",
            pricePerMonthOne: "",
            pricePerYearOne: "",
            descriptionOne: "",
            successTextOne: "",
        };
        if (monetization.planName === '') {
            errors.planName = "Plan Name is required";
        }
        if (monetization.subsTxt === "") {
            errors.subsTxt = "Subscription Text is required";
        }
        if (monetization.subsCancelTxt === "") {
            errors.subsCancelTxt = "Cancel Subscription Text is required";
        }
        if (monetization?.planDescription === "") {
            errors.planDescription = "Plan Description is required";
        }
        if (monetization.planSuccessText === "") {
            errors.planSuccessText = "Plan Success Text is required";
        }
        if (isPlanOpen == true) {
            if (!monetization.planNameOne) {
                errors.planNameOne = "Plan Name One is required";
            }
            if (!monetization.pricePerMonthOne || isNaN(monetization.pricePerMonthOne)) {
                errors.pricePerMonthOne = "Price Per Month is required and must be a number";
            }
            if (!monetization.pricePerYearOne || isNaN(monetization.pricePerYearOne)) {
                errors.pricePerYearOne = "Price Per Year is required and must be a number";
            }
            if (!monetization.descriptionOne) {
                errors.descriptionOne = "Description One is required";
            }
            if (!monetization.successTextOne) {
                errors.successTextOne = "Success Text One is required";
            }
        }
        const hasErrors = Object.values(errors).some(error => error !== "");
        if (hasErrors) {
            setErrorMessage(errors);
        } else {
            if (isPriceChanged && showPopup) {
                setIsPriceChangeOPen(true)
            } else {
                await handleSaveData();
                setNotification(true)
            }            
        }
    }

    const handleSaveData = async () => {        
        try {
            setIsLoading(true);
            
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/user/updateMonetization/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
                {
                    googleAds: {
                        displayAd: monetization?.displayAd,
                        googleAd: monetization?.googleAd || "Disconnect"
                    },
                    stripeConnected: monetization?.StripeConnected ||false,
                    subsTxt: monetization?.subsTxt || "",
                    subsCancelTxt: monetization?.subsCancelTxt || "",
                    subsPlans: {
                        freePlan: {
                            planName: monetization?.planName || "Free Plan",
                            planPrice: Number(monetization?.planPrice) || 0,
                            planDescription: monetization?.planDescription || "",
                            planSuccessText: monetization?.planSuccessText || "",
                        },
                        planOne: {
                            planNameOne: (isPlanOpen) ? (monetization?.planNameOne || "") : "",
                            pricePerMonthOne: (isPlanOpen) ? (Number(monetization?.pricePerMonthOne) || 0) : "",
                            pricePerYearOne: (isPlanOpen) ? (Number(monetization?.pricePerYearOne) || 0) : "",
                            descriptionOne: (isPlanOpen) ? (monetization?.descriptionOne || "") : "",
                            successTextOne: (isPlanOpen) ? (monetization?.successTextOne || "") : "",
                        }
                    }
                }
            )
            if (response?.status === 200) {
                fetchData()
                setIsPriceChangeOPen(false)
            }
            setIsLoading(false);
            setIsPriceChanged(false);

        } catch (error) {
            setIsLoading(false);
            console.log(error, "error");
        }
    }

    const handleDeletePlan = (plan) => {        
        if(! showPopup) {
            setMonetization(prevState => {
                const { planNameOne, pricePerMonthOne, pricePerYearOne, descriptionOne, successTextOne, ...newState } = prevState;
                return newState;
            });
            setPlanOneOpen(false)
            setDeletePlan(false)
        } else if (plan === 'planOne') {
            setDeletePlan(true)
        }
    }

    const handleStipeConnect = async () => {
        if(monetization?.deleteReq) {
            return;
        }

        if(! showPopup && monetization?.StripeConnected) {
            setIsStripeLoading(true);

            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/user/disconnectStripe/${JSON.parse(localStorage.getItem("userinfo"))?._id}`
            );

            setMonetization(prevState => {
                const { planNameOne, pricePerMonthOne, pricePerYearOne, descriptionOne, successTextOne, ...newState } = prevState;
                return { ...newState, deleteReq: true };
            });

            setPlanOneOpen(false);

            if(response?.data?.result?.canDisconnect) {                    
                setDisconnectStripe(false);
                isShouldCreateNew(true);  
                setStripeDisConnectNotify(true);
            }

            setIsStripeLoading(false);

            return false;            
        }

        try {
            setStipeConnectError('')
            setIsStripeLoading(true);

            if(monetization?.StripeConnected) {
                setDisconnectStripe(true);
            } else {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/user/createStripeAccount/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
                    {
                        createNew: shouldCreateNew,
                    }
                );
                if (response.data.status) {
                    const stripeAccountLinkUrl = response.data.result.stripeAccountLink.url;
                    window.open(stripeAccountLinkUrl, '_blank');
                    setStripeConnectNotify(true)
                } else {
                    setStipeConnectError(response.data.message);
                    console.error("Failed to connect to Stripe account:", response.data.message);
                }

                setDisconnectStripe(false);
            }

            setIsStripeLoading(false);
            
        } catch (error) {
            setIsStripeLoading(false);
            console.log(error, "error");
        }

    }

    const handleAgreeBtn = async (action) => {        
        try {
            if (action === 'deletePlan') {
                await axios.post(
                    `${process.env.REACT_APP_API_URL}/user/deletePlan/${JSON.parse(localStorage.getItem("userinfo"))?._id}`
                );

                setMonetization(prevState => {
                    const { planNameOne, pricePerMonthOne, pricePerYearOne, descriptionOne, successTextOne, ...newState } = prevState;
                    return newState;
                });

                setPlanOneOpen(false)
                setDeletePlan(false)
            }

            if (action === 'disconnectStipe') {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/user/disconnectStripe/${JSON.parse(localStorage.getItem("userinfo"))?._id}`
                );

                setMonetization(prevState => {
                    const { planNameOne, pricePerMonthOne, pricePerYearOne, descriptionOne, successTextOne, ...newState } = prevState;
                    return { ...newState, deleteReq: true };
                });

                setPlanOneOpen(false);

                if(response?.data?.result?.canDisconnect) {                    
                    setDisconnectStripe(false);
                    isShouldCreateNew(true);    
                } else {
                    return 'Your account cannot be disconnected until the balance is zero. Please settle any negative balance or transfer all funds to your account. Once the balance is zero, the account will be automatically disconnected.';
                }
                return false;
            }

            if (action === 'priceChanged') {
                await handleSaveData();
            }
        } catch (error) {
            console.log({error:error, message: error.message})
        }
    }

    function clearAll() {
        setCancelLoading(true);
        fetchData();
    }

    const toggleAction = () => {
        setIsActionOpen(!isActionOpen)
    }
    

    const [messages, setMessages] = useState("");
    const [subscriberAddedNotify, setSubscriberAddedNotify] = useState(false);
    const user_id = JSON.parse(localStorage.getItem("userinfo"))?._id
    useEffect(() => {
        const webSocket = new WebSocket('ws://localhost:8080');
    
        webSocket.onopen = () => {
        //   console.log('Connected to WebSocket server');

        };
    
        webSocket.onmessage = (event) => {
            const parsedData = JSON.parse(event.data);
            if(parsedData?.author_id === user_id){
                if(parsedData?.message !== "" && parsedData?.message !== undefined){
                    setSubscriberAddedNotify(true);
                    setMessages(parsedData?.message);
                }
            }
        };
    
        return () => {
            webSocket.close();
        };
    }, [messages]);
    return (
        <>
            <div className='monetization'>
                <div className="generalTop">
                    <div className="generalTopLeft">
                        <p className="generalTopLeftTitle">Monetization</p>
                        <p className="generalTopLeftDesc">
                            Update your company photo and details here.
                        </p>
                    </div>
                </div>
                <div className="generalRow">
                    <div className="generalRowDesc">
                        <p className='settingsMenuBlockTitle'>Display Ads</p>
                        <p className='generalTopLeftDesc'>Do you want to display ads in your publications?</p>
                    </div>
                    <div className="generalBlockItems">
                        <div>
                            <div className="bigSwitch">
                                <div className={(monetization?.displayAd === true) ? 'switch switchToggleActive' : 'switch'} name="displayAd" onClick={() => setMonetization((prevMonetization) => ({ ...prevMonetization, displayAd: !monetization?.displayAd }))} value={monetization?.displayAd}>
                                    <div className='switchToggle'></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {
                    monetization?.displayAd === true &&
                    <div className="generalRow">
                        <div className="generalRowDesc">
                            <p className="settingsMenuBlockTitle">Google AdSense</p>
                            <p className="generalTopLeftDesc">This will be displayed on your profile.</p>
                        </div>
                        <div className="generalBlockItems">
                            <div>
                                <div className="connectAdSense" onClick={() => setMonetization((prevMonetization) => ({ ...prevMonetization, googleAd: monetization?.googleAd === 'Disconnect' ? 'Connect' : 'Disconnect' }))}>
                                    <img src={AdSense} alt="AdSense" />
                                    <p className='senseText'>{monetization?.googleAd === 'Disconnect' ? 'Connect Google AdSense' : 'Disconnect Google AdSense'}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div  className={`generalRow ${monetization?.StripeConnected ? 'paddingRemoveRow' : ''}`}>
                    <div className="generalRowDesc">
                        <p className="settingsMenuBlockTitle">Stripe Connect</p>
                        <p className="generalTopLeftDesc">This will be displayed on your profile.</p>
                    </div>
                    <div className={`generalBlockItems ${monetization?.StripeConnected ?'marginRemove' : ""}`}>
                        <div className='marginRemove'>
                            {stripeable ?  
                                <div className={`connectAdSense ${monetization?.deleteReq && 'disabled'} ${monetization?.StripeConnected ? 'disconnectStipe' : 'connectStipe'}`} onClick={() => { handleStipeConnect() }}>
                                    { isSripeLoading ? 
                                        <div className="settingPage-loader">
                                            <div className="ring-loader dark"></div>
                                        </div>
                                        :
                                        <>                                    
                                            <img src={Stripe} alt="Stripe" className='stripeSize' />
                                            <p className='senseText'>{monetization?.StripeConnected ? 'Disconnect Stripe' : 'Connect Stripe'}</p>

                                        </>
                                    }
                                </div>
                                :
                                <div className="tooltip-container">
                                    <div className={`disabled connectAdSense ${monetization?.StripeConnected ? '' : 'connectStipe'}`}>                            
                                        <img src={Stripe} alt="Stripe" className='stripeSize' />
                                        <p className='senseText'>{monetization?.StripeConnected ? 'Disconnect Stripe' : 'Connect Stripe'}</p>
                                    </div>
                                    <p className='planNote'>
                                        <samp>Note: </samp>
                                        Unfortunately, your country is not currently supported by Stripe. You might consider using  <a href='https://stripe.com/atlas' target='_black'>Stripe Atlas</a>, which assists in setting up a company and bank account in the US, specifically designed to integrate with Stripe. Please check periodically for updates on the supported countries by Stripe <a href='https://docs.stripe.com/connect/cross-border-payouts' target='_blank'>here</a>.
                                    </p>
                                </div>
                            }
                            {monetization?.StripeConnected && <p className='senseSubText'><span className='changeColorStripe'>Connected to:</span> <Link to={stripeLoginUrl} target="_blank" className='senseSubText'>{monetization?.StripeConnectedTo}</Link> </p>}
                        </div>
                        {monetization?.deleteReq && 
                            <p className='planNote disconnectStipeNote'>
                                <samp>Note: </samp>
                                Your account cannot be disconnected until the balance is zero. Please settle any negative balance or transfer all funds to your account. Once the balance is zero, the account will be automatically disconnected.
                            </p>
                        }
                        {stipeConnectError && <div className='error-message'>{stipeConnectError}</div> }
                    </div>
                </div>
                <div className=" generalTwo">
                    <div className='generalItem'>
                        <div className="generalRowDesc">
                            <p>General text about your subscription</p>
                            <p>This will be displayed on your profile.</p>
                        </div>
                        <div className="generalBlockItems">
                            <textarea className='generalArea' onChange={(e) => { handleChange(e) }} value={monetization?.subsTxt}  name="subsTxt"></textarea>
                            { errorMessage?.subsTxt &&
                                <div className='error-message'>{errorMessage?.subsTxt}</div>
                            }                            
                        </div>
                    </div>
                    <div className='generalItem'>
                        <div className="generalRowDesc">
                            <p>Cancel subscription text</p>
                            <p>This will be displayed on your profile.</p>
                        </div>
                        <div className="generalBlockItems">
                            <textarea className='generalArea' onChange={(e) => { handleChange(e) }} value={monetization?.subsCancelTxt} name="subsCancelTxt"></textarea>
                            { errorMessage?.subsCancelTxt &&
                                <div className='error-message'>{errorMessage?.subsCancelTxt}</div>
                            } 
                        </div>
                    </div>
                </div>

                {/* if  props?.billingDetails?.subscriptions?.plan is not free_plan than show below portion*/}
                {props?.billingDetails?.subscriptions?.plan !== "free_plan" &&
                    <>
                        <div className=" generalTwo generalBorder">
                            <div className='generalItem'>
                                <div className="generalRowDesc">
                                    <p>Free Subscription</p>
                                    <p>This will be displayed on your profile.</p>
                                </div>
                                <div className='freeSubscription'>
                                    <div className="generalBlockItems">
                                        <div className="generalBlockInputs">
                                            <div className='monetizationPlan'>
                                                <p className="field-label">Plan Name</p>
                                                <input type="text" placeholder='Free Plan' name="planName" onChange={(e) => { handleChange(e) }} value={monetization?.planName} />                                                
                                                { errorMessage?.planName &&
                                                    <div className='error-message'>{errorMessage?.planName}</div>
                                                } 
                                            </div>
                                        </div>
                                    </div>
                                    <div className="generalBlockItems">
                                        <p className="field-label">Description</p>
                                        <textarea className='generalArea' placeholder="Describe this subscription plan..." name="planDescription" onChange={(e) => { handleChange(e) }} value={monetization?.planDescription}></textarea>                                        
                                        { errorMessage?.planDescription &&
                                            <div className='error-message'>{errorMessage?.planDescription}</div>
                                        } 
                                    </div>
                                    <div className="generalBlockItems">
                                        <p className="field-label">Success text</p>
                                        <textarea className='generalArea' placeholder="Thank you for choosing to subscribe to my newsletter. With your subscription, you will receive regular updates and insights directly to your email, keeping you informed and engaged." name="planSuccessText" onChange={(e) => { handleChange(e) }} value={monetization?.planSuccessText}></textarea>                                        
                                        { errorMessage?.planSuccessText &&
                                            <div className='error-message'>{errorMessage?.planSuccessText}</div>
                                        }
                                    </div>
                                    <div className={`${(isPlanOpen === true || isDisconnectStripe) ? "generalBlockItemsRemove" : "generalBlockItems"}`}>
                                        <p className='planNote'>
                                            <samp>Note: </samp>
                                            If you change the price of an existing subscription only new subscribers will be subject to the new rate once the price change has taken effect. Your current subscribers will continue with the plan they initially subscribed to.
                                        </p>
                                    </div>
                                    <div className={`${(isPlanOpen === true || isDisconnectStripe || ! monetization?.StripeConnected || monetization?.deleteReq ) ? "generalBlockItemsRemove" : "generalBlockItems"}`}>
                                        <button className="connectAdSense" onClick={() => { setPlanOneOpen(true) }}>
                                            <img src={PlusIcon} alt="AdSense" />
                                            <p className='senseText'>Add paid subscription</p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {((isPlanOpen === true || monetization?.planNameOne) && monetization?.StripeConnected ) &&
                            <div className="generalTwo">
                                <div className='generalItem'>
                                    <div className='mobilescreen'>
                                        <div className="generalRowDesc">
                                            <p>Plan 1</p>
                                            <p>This will be displayed on your profile.</p>
                                        </div>
                                        <div className='mobile-btn' onClick={() => handleDeletePlan('planOne')}>
                                            <img src={deleteIcon} alt="deleteIcon" />
                                        </div>
                                    </div>
                                    <div className='planOne'>
                                        <div className="generalBlockItems">
                                            <div className='monetizationPlan'>
                                                <p className="field-label">Plan Name</p>
                                                <input type="text" placeholder='Plan name...' name="planNameOne" onChange={(e) => { handleChange(e) }} value={monetization?.planNameOne} />
                                                { errorMessage?.planNameOne &&
                                                    <div className='error-message'>{errorMessage?.planNameOne}</div>
                                                }
                                            </div>
                                        </div>
                                        <div className="generalBlockItems">
                                            <div className='monetizationPlan pricepermonth'>
                                                <div className='setWidth'>
                                                    <p className="field-label">Monthly Subscription</p>
                                                </div>
                                                <div className='setFlex'>
                                                    <input type="text" name="pricePerMonthOne" placeholder="0.00" className='firstInput' onChange={(e) => { handlePriceChange(e); }} value={monetization?.pricePerMonthOne} />
                                                    <input type="text" name="pricePerMonthOne" className='secondInput' placeholder="USD" readOnly />
                                                </div>
                                            </div>
                                            { errorMessage?.pricePerMonthOne &&
                                                <div className='error-message'>{errorMessage?.pricePerMonthOne}</div>
                                            }
                                        </div>
                                        <div className="generalBlockItems">
                                            <div className='monetizationPlan pricepermonth'>
                                                <div className='setWidth'>
                                                    <p className="field-label">Yearly Subscription</p>
                                                </div>
                                                <div className='setFlex'>
                                                    <input type="text" name="pricePerYearOne" placeholder="0.00" className='firstInput' onChange={(e) => { handlePriceChange(e); }} value={monetization?.pricePerYearOne} />
                                                    <input type="text" name="pricePerMonthOne" className='secondInput' placeholder="USD" readOnly />
                                                </div>
                                            </div>
                                            { errorMessage?.pricePerYearOne &&
                                                <div className='error-message'>{errorMessage?.pricePerYearOne}</div>
                                            }
                                        </div>
                                        <div className="generalBlockItems">
                                            <p className="field-label">Description</p>
                                            <textarea className='generalArea' placeholder="Describe this subscription plan..." name="descriptionOne" onChange={(e) => { handleChange(e) }} value={monetization?.descriptionOne}></textarea>
                                            { errorMessage?.descriptionOne &&
                                                <div className='error-message'>{errorMessage?.descriptionOne}</div>
                                            }
                                        </div>
                                        <div className="generalBlockItems">
                                            <p className="field-label">Success text</p>
                                            <textarea className='generalArea' placeholder="Thank you for choosing to subscribe to my newsletter. With your subscription, you will receive regular updates and insights directly to your email, keeping you informed and engaged. " name="successTextOne" onChange={(e) => { handleChange(e) }} value={monetization?.successTextOne}></textarea>
                                            { errorMessage?.successTextOne &&
                                                <div className='error-message'>{errorMessage?.successTextOne}</div>
                                            }
                                        </div>
                                        <div className='generalBlockItems'>
                                            <p className='planNote'>
                                                <samp>Note: </samp>
                                                If you change the price of an existing subscription only new subscribers will be subject to the new rate once the price change has taken effect. Your current subscribers will continue with the plan they initially subscribed to.
                                            </p>
                                        </div>
                                    </div>
                                    <div className='deletePlan' onClick={()=>{handleDeletePlan('planOne')}}>
                                        <img src={deleteIcon} alt="deleteIcon" />
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                }
            </div>
            <div className="saveButtons">
                <button className={`settingsWhite ${cancelLoading ? 'btn_loading' : ''}`} onClick={(e) => clearAll()}>
                    {cancelLoading ?
                        <div className="settingPage-cancelLoader ">
                            <div className="ring-loader cancel"></div>
                        </div>
                        : "Cancel"}
                </button>
                <button className={`settingsBlue ${isLoading ? 'btn_loading' : ''}`} onClick={handleSubmit}>
                    {isLoading ?
                        <div className="settingPage-loader">
                            <div className="ring-loader"></div>
                        </div>
                        : "Save"
                    }
                </button>
            </div> 

            {invoices.length > 0 && 
                <div className="settingsMenu">
                    <p className={`settingMenu ${activeTab === 'Transactions' && 'active'}`} onClick={() => setActiveTab('Transactions')}>All Transactions</p>
                    {payouts.length > 0 && <p className={`settingMenu ${activeTab === 'Payouts' && 'active'}`} onClick={() => setActiveTab('Payouts')}>Payouts</p> }
                </div>
            }

            { (invoices.length > 0 && activeTab === 'Transactions') &&
                <div className="BillingTableWrapper monetizationBilling">
                    <div className="billingTable">
                        <div className="billingTableTop billingTableItem">                        
                            <div className="billingTableDate billingtableMonth" >
                                <p className="billingTopText">Date</p>
                            </div>
                            <div className="billingTableDate billingtableMonth" >
                                <p className="billingTopText">Type</p>
                            </div>
                            <div className="billingTableStatus" >
                                <p className="billingTopText textmt">Description</p>
                            </div>
                            <div className="billingTableAmount">
                                <p className="billingTopText">Amount</p>
                            </div>
                            <div className="billingTableStatus">
                                <p className="billingTopText">Destination</p>
                            </div>
                            <div className="billingTableStatus">
                                <p className="billingTopText">Status</p>
                            </div>
                            <div className="billingTableBorder">
                                <p className="billingTopText downloadtxt">Receipt</p>
                            </div>
                        </div>

                        <div className="billingTableItems">
                            {invoices.map((item, index) => (
                                <div className="billingTableTop billingTableItem" key={index}>
                                    <div>{dateFormatter(item.date, 'date', '', false, true)}</div>
                                    <div className='type'>{item?.type || '-'}</div>
                                    <div className='description'>{item?.description || '-'}</div>
                                    <div className={`${item.amount < 0 ? 'amount' : ''}`}>{item.amount < 0 ? `- ${currencies[item?.currency?.toUpperCase()]?.symbol_native || '$'}${Math.abs(item.amount)} ${item?.currency?.toUpperCase() || 'USD'}` : `${currencies[item?.currency?.toUpperCase()]?.symbol_native || '$'}${item.amount} ${item?.currency?.toUpperCase() || 'USD'}`}</div>
                                    <div className='destination'>{item?.destination || '-'}</div>
                                    <div className='status'>{item?.status || '-'}</div>
                                    <div className='action'>{item.receipt_url && <Link to={item.receipt_url} target="_blank" rel="noopener noreferrer">Download</Link>}</div>                                    
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            }

            { (payouts.length > 0 && activeTab === 'Payouts') &&
                <div className="BillingTableWrapper monetizationBilling">
                    <div className="billingTable">
                    <div className="billingTableTop billingTableItem">                        
                            <div className="billingTableDate billingtableMonth" >
                                <p className="billingTopText">Date</p>
                            </div>
                            <div className="billingTableDate billingtableMonth" >
                                <p className="billingTopText">Type</p>
                            </div>
                            <div className="billingTableStatus" >
                                <p className="billingTopText textmt">Description</p>
                            </div>
                            <div className="billingTableAmount">
                                <p className="billingTopText">Amount</p>
                            </div>
                            <div className="billingTableStatus">
                                <p className="billingTopText">Destination</p>
                            </div>
                            <div className="billingTableStatus">
                                <p className="billingTopText">Status</p>
                            </div>
                            <div className="billingTableBorder">
                                <p className="billingTopText downloadtxt">Receipt</p>
                            </div>
                        </div>

                        <div className="billingTableItems">
                            {payouts.map((item, index) => (
                                <div className="billingTableTop billingTableItem" key={index}>
                                    <div>{dateFormatter(item.date, 'date', '', false, true)}</div>
                                    <div className='type'>{item?.type || '-'}</div>
                                    <div className='description'>{item?.description || '-'}</div>
                                    <div className={`${item.amount < 0 ? 'amount' : ''}`}>{item.amount < 0 ? `- ${currencies[item?.currency?.toUpperCase()]?.symbol_native || '$'}${Math.abs(item.amount)} ${item?.currency?.toUpperCase() || 'USD'}` : `${currencies[item?.currency?.toUpperCase()]?.symbol_native || '$'}${item.amount} ${item?.currency?.toUpperCase() || 'USD'}`}</div>
                                    <div className='destination'>{item?.destination || '-'}</div>
                                    <div className='status'>{item?.status || '-'}</div>
                                    <div className='action'>{item.receipt_url && <Link to={item.receipt_url} target="_blank" rel="noopener noreferrer">Download</Link>}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            }
                
            {
                ((isDeletePlan || isDisconnectStripe || isPriceChangeOpen) && showPopup) && <MonetizationModal isDeletePlan={isDeletePlan} isDisconnectStripe={isDisconnectStripe} isPriceChange={isPriceChangeOpen} setPlanOneOpen={setPlanOneOpen} setMonetization={setMonetization} monetization={monetization} handleSaveData={handleSaveData} setDeletePlan={setDeletePlan} setIsPriceChanged={setIsPriceChangeOPen} setDisconnectStripe={setDisconnectStripe} isShouldCreateNew={isShouldCreateNew} handleAgreeBtn={handleAgreeBtn} />
            }

            {notification &&
                <NotificationMessage  openNotify={notification} setOpenNotify={setNotification} type={'success'} title={`Your monetization settings has been successfully updated!`} />
            }
            {stripeConnectNotify &&
                <NotificationMessage  openNotify={stripeConnectNotify} setOpenNotify={setStripeConnectNotify} type={'success'} title={`Your Stripe account has been successfully connected`} />
            }
            {stripeDisConnectNotify &&
                <NotificationMessage  openNotify={stripeDisConnectNotify} setOpenNotify={setStripeDisConnectNotify} type={'success'} title={`Your Stripe account has been successfully disconnected`} />
            }
            {googleConnect &&
                <NotificationMessage  openNotify={googleConnect} setOpenNotify={setGoogleConnect} type={'success'} title={`Your Google Adsense account has been successfully connected`} />
            }
            {googleDisConnect &&
                <NotificationMessage  openNotify={googleDisConnect} setOpenNotify={setGoogleDisConnect} type={'success'} title={`Your Google Adsense account has been successfully disconnected`} />
            }
            {(subscriberNotify && subscriberCount > 0) && (
                <NotificationMessage  openNotify={subscriberNotify} setOpenNotify={setSubscriberNotify} type={'success'} title={`${(subscriberCount === 1) ? `A subscriber was successfully added!`: `${subscriberCount} subscribers were successfully added!`}`} />
            )}
            {subscriberAddedNotify && (
                <NotificationMessage  openNotify={subscriberAddedNotify} setOpenNotify={setSubscriberAddedNotify} type={'success'} title={messages} name="liveNotification" />
            )}
        </>
    )
}