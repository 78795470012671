import React, { useCallback } from 'react';
import { BubbleMenu as BaseBubbleMenu } from '@tiptap/react';
import isColumnGripSelected from './utils';

import icons from '../../../../iconsList.json';

const TableColumnMenu = ({ editor, appendTo }) => {
  const shouldShow = useCallback(({ view, state, from }) => {
    if (!state) {
      return false;
    }
    return isColumnGripSelected({ editor, view, state, from: from || 0 });
  }, [editor]);

  const onAddColumnBefore = useCallback(() => {
    editor.chain().focus().addColumnBefore().run();
  }, [editor]);

  const onAddColumnAfter = useCallback(() => {
    editor.chain().focus().addColumnAfter().run();
  }, [editor]);

  const onDeleteColumn = useCallback(() => {
    editor.chain().focus().deleteColumn().run();
  }, [editor]);

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey="tableColumnMenu"
      updateDelay={0}
      tippyOptions={{
        appendTo: () => appendTo?.current,
        offset: [0, 15],
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
      }}
      shouldShow={shouldShow}
    >
      <div className='tablerow-menu'>

        <button className='tablerow-menu-item' onClick={onAddColumnBefore}>
            <div dangerouslySetInnerHTML={{ __html: icons['ArrowLeftToLine'] }} />
            Add column before
        </button>

        <button className='tablerow-menu-item' onClick={onAddColumnAfter}>
            <div dangerouslySetInnerHTML={{ __html: icons['ArrowRightToLine'] }} />
            Add column after
        </button>

        <button className='tablerow-menu-item' onClick={onDeleteColumn}>
            <div dangerouslySetInnerHTML={{ __html: icons['Trash'] }} />
            Delete column
        </button>
      </div>
    </BaseBubbleMenu>
  );
};

export default TableColumnMenu;
