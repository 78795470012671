import { useEffect, useRef, useState } from 'react';
import close from '../../images/Settings/close.svg'
const PreviewPopup = ({setShowPreviewPopup, showPreviewPopup, previewpage, tempData, featureImage, profileSlug}) => {
    const popupRef = useRef();
    const [tooltipVisible, setTooltipVisible] = useState(false);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
          if (showPreviewPopup && setShowPreviewPopup && popupRef?.current && !popupRef?.current.contains(e.target)) {
            setShowPreviewPopup(false)
          }
        };
    
        document.addEventListener("mousedown", checkIfClickedOutside);
    
        return () => {
          document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [showPreviewPopup]);

    const inputUrl = `https://jourmal-next-front.vercel.app/${profileSlug}/${tempData?.url_address}`;

    const handleCopy = () => {
        if (navigator?.clipboard?.writeText) {
            navigator.clipboard.writeText(inputUrl)
                .then(() => {
                    showTooltip();
                })
                .catch((err) => console.error("Failed to copy text to clipboard:", err));
        } else {
            const tempInput = document.createElement("input");
            tempInput.value = inputUrl;
            document.body.appendChild(tempInput);
            tempInput.select();
            tempInput.setSelectionRange(0, 99999);
            document.execCommand("copy");
            document.body.removeChild(tempInput);
    
            showTooltip();
        }
    }

    const showTooltip = () => {
        setTooltipVisible(true);
        setTimeout(() => setTooltipVisible(false), 2000);
    };

    const handleCancel = () => {
        setShowPreviewPopup(false)
    }
    return(
        <div className="welcomeModal previewModal" ref={popupRef}>    
        <div className="popupTop popupTopClose closePlan previewClose">
          <img src={close} onClick={() => { handleCancel() }} alt="Icon" />
        </div>
            {previewpage === "Page" &&
                <div className="previewImage">
                    <img src={tempData?.pageImage} alt="Preview Image"/>
                </div>
            }
            {(previewpage === "Newsletter") && (featureImage !== '') &&
                <div className="previewImage">
                    <img src={featureImage} alt="Preview Image"/>
                </div>
            }
            {previewpage === "Page" && 
                <p className="preview-title">Your page is live!</p>
            }
            {previewpage === "Newsletter" &&
                <p className="preview-title">Your newsletter has been sent!</p>
            }
            {previewpage === "Page" && 
                <p className="preview-description">
                    Your page has been successfully published on your web profile. You can go to the <b>navigation menu</b> in your settings and add it there to make it easily accessible.
                </p>
            }
            {previewpage === "Newsletter" &&
                <p className="preview-description">
                    Your newsletter has been sent to your subscribers. You can check its analytics and open rate on the analytics page. Remember, keeping your subscribers engaged is key to building a strong connection.
                </p>
            }
            {previewpage === "Page" && 
                <div className="preview-linkContent">
                    <label>Page link</label>
                    <div className="preview-linkBox">
                        <input type="text" name="pagelink" value={inputUrl} />
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleCopy} >
                            <g clip-path="url(#clip0_5706_684)">
                            <path d="M4.16675 12.4974C3.39018 12.4974 3.00189 12.4974 2.69561 12.3705C2.28723 12.2014 1.96277 11.8769 1.79362 11.4685C1.66675 11.1622 1.66675 10.774 1.66675 9.9974V4.33073C1.66675 3.39731 1.66675 2.9306 1.8484 2.57408C2.00819 2.26047 2.26316 2.00551 2.57676 1.84572C2.93328 1.66406 3.39999 1.66406 4.33341 1.66406H10.0001C10.7767 1.66406 11.1649 1.66406 11.4712 1.79093C11.8796 1.96009 12.2041 2.28454 12.3732 2.69292C12.5001 2.99921 12.5001 3.38749 12.5001 4.16406M10.1667 18.3307H15.6667C16.6002 18.3307 17.0669 18.3307 17.4234 18.1491C17.737 17.9893 17.992 17.7343 18.1518 17.4207C18.3334 17.0642 18.3334 16.5975 18.3334 15.6641V10.1641C18.3334 9.23064 18.3334 8.76393 18.1518 8.40741C17.992 8.09381 17.737 7.83884 17.4234 7.67905C17.0669 7.4974 16.6002 7.4974 15.6667 7.4974H10.1667C9.23333 7.4974 8.76662 7.4974 8.4101 7.67905C8.09649 7.83884 7.84153 8.09381 7.68174 8.40741C7.50008 8.76393 7.50008 9.23064 7.50008 10.1641V15.6641C7.50008 16.5975 7.50008 17.0642 7.68174 17.4207C7.84153 17.7343 8.09649 17.9893 8.4101 18.1491C8.76662 18.3307 9.23333 18.3307 10.1667 18.3307Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_5706_684">
                            <rect width="20" height="20" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                        {tooltipVisible && <div className="text-tooltip">URL Copied!</div>}
                    </div>
                </div>
            }
            <div className="welcomeModalbtn previewPopupButton" id="deleteMedia">
            {previewpage === "Page" && 
                <>
                    <button className="cancel" type="button" onClick={handleCancel}>Cancel</button>  
                    <button className="savePicture addSubscriberWidth" type="button" onClick={() => {
                        window.open(`https://reactdashboard.codeptsolutions.com/dashboard/settings/menu`);
                    }}>Open navigation menu</button>
                </>
                }
                {previewpage === "Newsletter" &&
                    <button className={`cancel preview-cancel closeNewsletter`} onClick={handleCancel}>Close</button>
                }
            </div>
        </div>
    )
}

export default PreviewPopup;