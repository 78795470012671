import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import './previewContact.css'
import axios from 'axios';
import Error from '../Error';
import { PreviewFooter } from '../PreviewFooter/PreviewFooter';
import { PreviewHeader } from '../PreviewHeader/PreviewHeader';
export const PreviewContact = (props) => {
    props.setActivePage("Preview Contact");
    const { id } = useParams();
    const user_id = JSON.parse(localStorage.getItem("userinfo"))?._id
    const rows = useState(6);
    const [contactDetail, setContactDetail] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [status, setStatus] = useState("")
    const fetchData = async () => {
        try {
            const responseHeroData = await axios.get(
                `${process.env.REACT_APP_API_URL}/front/contactherodata/${localStorage.getItem("userProfileSlug")}?preview=true`,
            )
            setContactDetail(responseHeroData?.data)
        } catch (error) {
            console.log(error, "error");
        }
    }
    useEffect(() => {
        fetchData()
    }, [])
    const handleSend = async () => {
        if (contactDetail?.feature_image !== "") {
            const updatedData = { ...contactDetail };
            setIsLoading(true);
            try {
                const res = await axios.post(
                    `${process.env.REACT_APP_API_URL}/user/updateContactpage/${user_id}`,
                    updatedData,
                )

                if (res.status === 200) {
                    setIsLoading(false);
                    setStatus('Published')
                }
            } catch (error) {
                console.error("Failed to send data:", error);
                setIsLoading(false);
            }
        }
    };

    const handleBack = () => {
        window.close();
    };
    return (
        <>
            {
                id === user_id ?
                    <>
                        <div className={`preview-newsletter__header ${props?.userMetaData?.theme === "light" ? 'light-view' : 'dark-view'} ${props?.userMetaData?.theme}`}>
                            <p>You are currently viewing a preview of your home page.</p>
                            <div className={`preview-buttons`}>
                                <div className={`newpageRightButtons newpageRightButtonsPreview ${props?.userMetaData?.theme === "light" ? 'light-newpageRightButtons' : 'dark-newpageRightButtons'}`} onClick={() => {
                                    handleSend()
                                }}>

                                    {
                                        isLoading ?
                                            <div className="updatebutton-loader publish-loader">
                                                <div className="ring-loader"></div>
                                            </div> :
                                            <button>{props?.userMetaData?.theme === 'light' ? <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.86659 14C9.34773 14 9.68854 13.5857 9.9358 12.9442L14.3129 1.51026C14.4332 1.20286 14.5 0.928878 14.5 0.701671C14.5 0.267303 14.2327 0 13.7983 0C13.5711 0 13.2971 0.0668258 12.9897 0.187112L1.4957 4.59093C0.934368 4.80477 0.5 5.14558 0.5 5.63341C0.5 6.24821 0.96778 6.45537 1.60931 6.64916L5.2179 7.74511C5.64558 7.87876 5.88616 7.86539 6.17351 7.59809L13.5043 0.748449C13.5912 0.668258 13.6914 0.681623 13.7582 0.741766C13.8251 0.808592 13.8317 0.908831 13.7516 0.995704L6.92864 8.35322C6.66802 8.62721 6.64797 8.85442 6.77494 9.30215L7.83747 12.8305C8.03795 13.5055 8.24511 14 8.86659 14Z" fill="white" />
                                            </svg> : <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.86659 14C9.34773 14 9.68854 13.5857 9.9358 12.9442L14.3129 1.51026C14.4332 1.20286 14.5 0.928878 14.5 0.701671C14.5 0.267303 14.2327 0 13.7983 0C13.5711 0 13.2971 0.0668258 12.9897 0.187112L1.4957 4.59093C0.934368 4.80477 0.5 5.14558 0.5 5.63341C0.5 6.24821 0.96778 6.45537 1.60931 6.64916L5.2179 7.74511C5.64558 7.87876 5.88616 7.86539 6.17351 7.59809L13.5043 0.748449C13.5912 0.668258 13.6914 0.681623 13.7582 0.741766C13.8251 0.808592 13.8317 0.908831 13.7516 0.995704L6.92864 8.35322C6.66802 8.62721 6.64797 8.85442 6.77494 9.30215L7.83747 12.8305C8.03795 13.5055 8.24511 14 8.86659 14Z" fill="black" />
                                            </svg>}Publish</button>
                                    }

                                </div>
                                <div className="preview-edit">
                                    <button onClick={() => { handleBack() }}>
                                        Back to edit
                                    </button>
                                </div>
                            </div>
                        </div>
                        <PreviewHeader profilePicture={contactDetail?.profilePicture} displayName={contactDetail?.displayName} theme={contactDetail?.theme} />
                        <main className={"main"}>
                            <div id="hero-and-body">
                                <section className={`contactSection ${props?.userMetaData?.theme}`}>
                                    <div className='hero-wrap'>
                                        <div className='hero-col hero-col-left'>
                                            <div className={`hero-content heroContent`}>
                                                <div className='page-content-inner'>
                                                    <h2 className='hero-title text-white'>Contact</h2>
                                                    <div className='page-content'>
                                                        <p className='hero-paragraph text-white'>{contactDetail?.pageText}</p>
                                                        <div className={`text-white contactContent`}>
                                                            <ul>
                                                                <li className="adress">
                                                                    <svg className={"iconContact"} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                        <path d="M10.0002 10.8337C11.3809 10.8337 12.5002 9.71437 12.5002 8.33366C12.5002 6.95295 11.3809 5.83366 10.0002 5.83366C8.61945 5.83366 7.50016 6.95295 7.50016 8.33366C7.50016 9.71437 8.61945 10.8337 10.0002 10.8337Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M10.0002 18.3337C13.3335 15.0003 16.6668 12.0156 16.6668 8.33366C16.6668 4.65176 13.6821 1.66699 10.0002 1.66699C6.31826 1.66699 3.3335 4.65176 3.3335 8.33366C3.3335 12.0156 6.66683 15.0003 10.0002 18.3337Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    </svg>
                                                                    <address className='contact-address'>
                                                                        <p className={"contactTitle"}>
                                                                            Address
                                                                        </p>
                                                                        <p className={`text-white contactBrief`}>{contactDetail?.publicAddress}</p>
                                                                    </address>
                                                                </li>
                                                                <li className="phone">
                                                                    <svg className={"iconContact"} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                        <path d="M6.98356 7.37779C7.56356 8.58581 8.35422 9.71801 9.35553 10.7193C10.3568 11.7206 11.4891 12.5113 12.6971 13.0913C12.801 13.1412 12.8529 13.1661 12.9187 13.1853C13.1523 13.2534 13.4392 13.2045 13.637 13.0628C13.6927 13.0229 13.7403 12.9753 13.8356 12.88C14.1269 12.5887 14.2726 12.443 14.4191 12.3478C14.9715 11.9886 15.6837 11.9886 16.2361 12.3478C16.3825 12.443 16.5282 12.5887 16.8196 12.88L16.9819 13.0424C17.4248 13.4853 17.6462 13.7067 17.7665 13.9446C18.0058 14.4175 18.0058 14.9761 17.7665 15.449C17.6462 15.6869 17.4248 15.9083 16.9819 16.3512L16.8506 16.4825C16.4092 16.9239 16.1886 17.1446 15.8885 17.3131C15.5556 17.5001 15.0385 17.6346 14.6567 17.6334C14.3126 17.6324 14.0774 17.5657 13.607 17.4322C11.0792 16.7147 8.69387 15.361 6.70388 13.371C4.7139 11.381 3.36017 8.99569 2.6427 6.46786C2.50919 5.99749 2.44244 5.7623 2.44141 5.41818C2.44028 5.03633 2.57475 4.51925 2.76176 4.18633C2.9303 3.88631 3.15098 3.66563 3.59233 3.22428L3.72369 3.09292C4.16656 2.65005 4.388 2.42861 4.62581 2.30833C5.09878 2.0691 5.65734 2.0691 6.1303 2.30832C6.36812 2.42861 6.58955 2.65005 7.03242 3.09291L7.19481 3.25531C7.48615 3.54665 7.63182 3.69231 7.72706 3.8388C8.08622 4.3912 8.08622 5.10336 7.72706 5.65576C7.63182 5.80225 7.48615 5.94791 7.19481 6.23925C7.09955 6.33451 7.05192 6.38214 7.01206 6.43782C6.87038 6.63568 6.82146 6.92256 6.88957 7.15619C6.90873 7.22193 6.93367 7.27389 6.98356 7.37779Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    </svg>
                                                                    <p className={"contactTitle"}>Phone Number</p>
                                                                    <a href="!#" className={`text-white contactBrief`} >{contactDetail?.phoneNumber}</a>
                                                                </li>
                                                                <li className="email-address">
                                                                    <svg className={"iconContact"} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                        <path d="M1.6665 5.83301L8.4706 10.5959C9.02158 10.9816 9.29707 11.1744 9.59672 11.2491C9.86142 11.3151 10.1383 11.3151 10.403 11.2491C10.7026 11.1744 10.9781 10.9816 11.5291 10.5959L18.3332 5.83301M5.6665 16.6663H14.3332C15.7333 16.6663 16.4334 16.6663 16.9681 16.3939C17.4386 16.1542 17.821 15.7717 18.0607 15.3013C18.3332 14.7665 18.3332 14.0665 18.3332 12.6663V7.33301C18.3332 5.93288 18.3332 5.23281 18.0607 4.69803C17.821 4.22763 17.4386 3.84517 16.9681 3.60549C16.4334 3.33301 15.7333 3.33301 14.3332 3.33301H5.6665C4.26637 3.33301 3.56631 3.33301 3.03153 3.60549C2.56112 3.84517 2.17867 4.22763 1.93899 4.69803C1.6665 5.23281 1.6665 5.93288 1.6665 7.33301V12.6663C1.6665 14.0665 1.6665 14.7665 1.93899 15.3013C2.17867 15.7717 2.56112 16.1542 3.03153 16.3939C3.56631 16.6663 4.26637 16.6663 5.6665 16.6663Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    </svg>
                                                                    <p className={"contactTitle"}>Email Address</p>
                                                                    <a href="!#" className={`text-white contactBrief`}>{contactDetail?.publicEmail}</a>
                                                                </li>
                                                            </ul>
                                                        </div>

                                                        <div className={"contactForm"}>
                                                            <h4 className={"contactFormTitle"}>Send a Message</h4>
                                                            <form className={"contactFormContent"}>
                                                                <div className={"formGroup"}>
                                                                    <div className={"formControl"}>
                                                                        <label htmlFor="first-name">Full Name</label>
                                                                        <input type="text" className={`firstName formField`} id="first-name" placeholder='Your name...' />
                                                                    </div>
                                                                    <div className={"formControl"}>
                                                                        <label htmlFor="last-name">Email Address</label>
                                                                        <input type="email" className={`emailAddress formField`} id="email-address" placeholder='Your email address...' />
                                                                    </div>
                                                                    <div className={"formControl"}>
                                                                        <label htmlFor="last-name">Phone Number (optional)</label>
                                                                        <input type="text" className={`phoneNumber formField`} id="phone-number" placeholder='Your phone number...' />
                                                                    </div>
                                                                </div>
                                                                <div className={"formControl"}>
                                                                    <label htmlFor="email">Message</label>
                                                                    <textarea rows={rows} type="message" className={`textarea formField`} id="message" placeholder='Write your message...' />
                                                                </div>
                                                                <div className={`formControl labelBtnWrap`}>
                                                                    <div className={"btnWrapper"}>
                                                                        <input type="submit" value="Send" className={"confirmSubscribe"} />
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='hero-col hero-col-right'>
                                            <div className='hero-image'>
                                                <img
                                                    src={contactDetail?.feature_imageurl}
                                                    alt="Contact page image"
                                                    fill={true}
                                                    quality={100}
                                                    priority
                                                />
                                                <div className="overlay"></div>
                                            </div>

                                        </div>
                                    </div>
                                </section>
                            </div>
                        </main>
                        <PreviewFooter profilePicture={contactDetail?.profilePicture} displayName={contactDetail?.displayName} theme={contactDetail?.theme} />
                    </> :
                    <Error menuDisabled={true} />
            }
        </>
    )
}
