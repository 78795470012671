import React from 'react'
import './previewFooter.css'
export const PreviewFooter = (props) => {
  return (
    <footer className={`footer-preview ${props?.theme}`}>
            <div className="footer-content">
                <div className='footerimg'>
                    <img
                        src={props?.profilePicture}
                        alt={props?.displayName}
                        width={55}
                        height={55}
                        quality={100}
                        priority
                    />
                    <div className="copyright">
                        <p>© {new Date().getFullYear()} {props?.displayName}.  All rights reserved</p>
                    </div>
                </div>
                <div className='divider'></div>
                <div className="footer-menu">
                    Terms & Privacy
                </div>
            </div>
        </footer>
  )
}

