// /src/components/ButtonBubbleMenu.js
import React, { useState, useEffect, useCallback } from 'react';
import { BubbleMenu } from '@tiptap/react';
import icons from '../../iconsList.json';

const ButtonBubbleMenu = ({ editor, appendTo }) => {
  const [editAttributes, setEditAttributes] = useState({
    title: 'Button',
    href: '#',
    align: 'center',
    width: 'auto',
  });

  useEffect(() => {
    if (editor) {
      const attrs = editor.getAttributes('button');
      setEditAttributes({
        ...attrs,
        align: attrs.align || 'center',
      });
    }
  }, [editor]);

  const shouldShow = useCallback(() => {
    if (editor.isActive('button')) {
      const defaultAlignment = editor.state.selection.node?.attrs?.align;
      setButtonAttribute("align", defaultAlignment);
      const defaultHref = editor.state.selection.node?.attrs?.href;
      setButtonAttribute("href", defaultHref);
      const defaultTitle = editor.state.selection.node?.attrs?.title;
      setButtonAttribute("title", defaultTitle);
      const defaultWidth = editor.state.selection.node?.attrs?.width;
      setButtonAttribute("width", defaultWidth);
    }
    return editor.isActive('button') && editor.isFocused;
  }, [editor]);

  const updateButtonAttributes = () => {
    if (editor) {
      editor.chain().focus().updateAttributes('button', editAttributes).run();
    }
  };

  const handleApplyChange = () => {
    if (editor && editor.isActive('button')) {
      editor.chain().focus().updateAttributes('button', editAttributes).run();
    }
  };

  const setButtonAttribute = (key, value) => {
    setEditAttributes((prev) => {
      const newAttrs = { ...prev, [key]: value };
      return newAttrs;
    });
  };

  return (
    <BubbleMenu 
      editor={editor} 
      shouldShow={shouldShow}
      updateDelay={0}
      tippyOptions={{
        zIndex: 99,
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
        appendTo: () => appendTo?.current,
      }}
    >
      <div className="bubble-toggle-menu button-box">
      <div className='alignment-box'>
          <label className='box-title'>Content Alignment</label>
          <div className='alignment-buttons'>
            <button
              className={`toolbar-button ${editAttributes.align === 'left' ? 'active' : ''}`}
              onClick={() => setButtonAttribute('align', 'left')}
            >
              <div dangerouslySetInnerHTML={{ __html: icons['AlignLeft'] }} />
            </button>
            <button
              className={`toolbar-button ${editAttributes.align === 'center' ? 'active' : ''}`}
              onClick={() => setButtonAttribute('align', 'center')}
            >
              <div dangerouslySetInnerHTML={{ __html: icons['AlignCenter'] }} />
            </button>
            <button
              className={`toolbar-button ${editAttributes.align === 'right' ? 'active' : ''}`}
              onClick={() => setButtonAttribute('align', 'right')}
            >
              <div dangerouslySetInnerHTML={{ __html: icons['AlignRight'] }} />
            </button>
          </div>
        </div>
        <label className="text-box">
          Button Text
          <input
            type="text"
            placeholder="Button Title"
            value={editAttributes.title}
            onChange={(e) => setEditAttributes({ ...editAttributes, title: e.target.value })}
          />
        </label>
        <div className='url-block'>
          <label className="text-box">
            Button URL
            <input
              type="text"
              placeholder="Button URL"
              className='button-url'
              value={editAttributes.href}
              onChange={(e) => setEditAttributes({ ...editAttributes, href: e.target.value })}
              />
          </label>
        </div>
        <div className='button-element submit-button'>
          <button onClick={handleApplyChange}>Apply</button>
        </div>
      </div>
    </BubbleMenu>
  );
};

export default ButtonBubbleMenu;
