import LinkEditorPanel from './LinkEditorPanel';

import icons from '../iconsList.json';

export const EditLinkPopover = ({ onSetLink, editLinkPopoverOpen, setEditLinkPopoverOpen }) => {
  
  const handleSetLink = (link, openInNewTab) => {
    onSetLink(link, openInNewTab);
    setEditLinkPopoverOpen(false);
  };

  return (
    <div style={{ position: 'relative' }}>
        <button className="toolbar-button" onClick={() => setEditLinkPopoverOpen(!editLinkPopoverOpen)}>
            <div dangerouslySetInnerHTML={{ __html: icons['Link'] }} />
        </button>
        {editLinkPopoverOpen && (
            <div
            style={{
                position: 'absolute',
                top: '100%',
                left: '-200px',
            }}
            >
            <LinkEditorPanel onSetLink={handleSetLink} />
            </div>
        )}
    </div>
  );
};
