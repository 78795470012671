import { Node, ReactNodeViewRenderer } from '@tiptap/react';
import BookmarkBlockView from './BookmarkBlockView';

export const BookmarkBlock = Node.create({
  name: 'bookmarkBlock',

  group: 'block',

  defining: true,

  isolating: true,

  addAttributes() {
    return {
      url: {
        default: '',
      },
      title: {
        default: '',
      },
      description: {
        default: '',
      },
      imageUrl: {
        default: '',
      },
      siteTitle: {
        default: '',
      },
      favicon: {
        default: '',
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
        getAttrs: (node) => {
          const element = node;
          
          // Extract data from corresponding class elements based on your BookmarkBlockView component
          const url = element.querySelector('a')?.getAttribute('href') || '';
          const title = element.querySelector('.linkDataTitle')?.innerText || '';
          const description = element.querySelector('.linkDataDiscripation')?.innerText || '';
          const imageUrl = element.querySelector('.previewImage')?.getAttribute('src') || '';
          const siteTitle = element.querySelector('.siteData p')?.innerText || '';
          const favicon = element.querySelector('.siteData img')?.getAttribute('src') || '';
  
          return {
            url,
            title,
            description,
            imageUrl,
            siteTitle,
            favicon,
          };
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      { 'data-type': this.name, class: 'bookmark-block link-content' },
      [
        'a',
        { href: HTMLAttributes.url, target: '_blank' },
        [
          'div',
          { class: 'textBlock linkBox' },
          [
            'div',
            { class: 'linkImageSection' },
            HTMLAttributes.imageUrl
              ? ['img', { class: 'previewImage', src: HTMLAttributes.imageUrl, alt: HTMLAttributes.title }]
              : '',
          ],
          [
            'div',
            { class: 'linkData' },
            [
              'div',
              {},
              [
                'p',
                { class: 'linkDataTitle' },
                HTMLAttributes.title || 'No Title',
              ],
              [
                'span',
                { class: 'linkDataDiscripation' },
                HTMLAttributes.description || 'No Description',
              ],
            ],
            HTMLAttributes.siteTitle
              ? [
                  'div',
                  { class: 'siteData' },
                  HTMLAttributes.favicon
                    ? ['img', { src: HTMLAttributes.favicon, alt: HTMLAttributes.siteTitle }]
                    : '',
                  ['p', {}, HTMLAttributes.siteTitle],
                ]
              : '',
            [
              'div',
              { class: 'siteData siteUrl' },
              ['p', {}, HTMLAttributes.url || 'No URL'],
            ],
          ],
        ],
      ],
    ];
  },

  addCommands() {
    return {
      setBookmarkBlock:
        (options) =>
          ({ commands }) => {
            return commands.insertContent({
              type: this.name,
              attrs: {
                title: options?.title || 'No Title',
                description: options?.description || 'No Description',
                imageUrl: options?.imageUrl || '',
                siteTitle: options?.siteTitle || '',
                favicon: options?.favicon || '',
                url: options?.url || '',
              },
            });
          },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(BookmarkBlockView);
  },
});
