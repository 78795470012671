import { Node, NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';

const SpotifyBlockView = (props) => {
    const { node } = props;
    
    return (
        <NodeViewWrapper className="spotify-node-wrapper" data-type='spotify'>
            <div className="spotifyEmbed" data-spotify-url={node.attrs.spotifyUrl} data-spotify-type={node.attrs.spotifyType}>
                <iframe
                    src={node.attrs.spotifyUrl}
                    width="100%"
                    height="500"
                    frameBorder="0"
                    allowTransparency="true"
                    allow="encrypted-media"
                    title="spotify"
                ></iframe>
            </div>
        </NodeViewWrapper>
    );
};

export const SpotifyBlock = Node.create({
    name: 'spotifyBlock',

    group: 'block',

    defining: true,

    isolating: true,

    addAttributes() {
        return {
            spotifyUrl: {
                default: '',
            },
            spotifyType: {
                default: '',
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: `div[data-type="${this.name}"]`,
                getAttrs: (element) => {
                    const spotifyUrl = element.getAttribute('data-spotify-url') || '';
                    const spotifyType = element.getAttribute('data-spotify-type') || '';
                    return {
                        spotifyUrl,
                        spotifyType
                    };
                },
            },
        ];
    },

    renderHTML({ node }) {
        return [
            'div',
            {
                'data-type': this.name,
                'data-spotify-url': node.attrs.spotifyUrl,
                'data-spotify-type': node.attrs.spotifyType,
                class: 'spotify-embed-wrapper spotify-block',
            },
            ['iframe', {
                src: node.attrs.spotifyUrl,
                width: '100%',
                height: '500',
                frameborder: '0',
                allowtransparency: 'true',
                allow: 'encrypted-media',
            }]
        ];
    },

    addCommands() {
        return {
            setSpotifyBlock:
                (options) =>
                    ({ commands }) => {
                        return commands.insertContent({
                            type: this.name,
                            attrs: {
                                spotifyUrl: options?.spotifyUrl || '',
                                spotifyType: options?.spotifyType || '',
                            },
                        });
                    },
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(SpotifyBlockView);
    },

});

