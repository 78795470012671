import { useCallback, useState } from 'react';
import { HexColorPicker } from 'react-colorful';


import icons from '../iconsList.json';
import ColorButton from './ColorButton';

const ColorPicker = ({ color, onChange, onClear }) => {

    const themeColors = ['#fb7185', '#fdba74', '#d9f99d', '#a7f3d0', '#a5f3fc', '#a5b4fc']

    const [colorInputValue, setColorInputValue] = useState(color || '');

    const handleColorUpdate = useCallback((event) => {
        setColorInputValue(event.target.value);
    }, []);

    const handleColorChange = useCallback(() => {
        const isCorrectColor = /^#([0-9A-F]{3}){1,2}$/i.test(colorInputValue);

        if (!isCorrectColor) {
        if (onChange) {
            onChange('');
        }
        return;
        }

        if (onChange) {
        onChange(colorInputValue);
        }
    }, [colorInputValue, onChange]);

    return (
        <div className="colorpicker-cover">
            <HexColorPicker className="w-full" color={color || ''} onChange={onChange} />
            <input
                type="text"
                className="colorpicker-input"
                placeholder="#000000"
                value={colorInputValue}
                onChange={handleColorUpdate}
                onBlur={handleColorChange}
            />
            <div className="colorpicker-buttons">
                {themeColors.map(currentColor => (
                <ColorButton
                    active={currentColor === color}
                    color={currentColor}
                    key={currentColor}
                    onColorChange={onChange}
                />
                ))}

                <button className={`toolbar-button`} onClick={onClear}>
                    <div dangerouslySetInnerHTML={{ __html: icons['Undo'] }} />
                </button>
            </div>
        </div>
    );
};

export default ColorPicker;
