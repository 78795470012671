import React, { useState, useEffect, useCallback } from 'react';
import { BubbleMenu } from '@tiptap/react';

const ProductBubbleMenu = ({ editor, appendTo }) => {
    const [editAttributes, setEditAttributes] = useState({
        showRating: false,
        rating: 5,
        showButton: false,
        buttonText: 'Buy Now',
        buttonUrl: '',
    });
    const [isLoopActive, setLoopActive] = useState(editAttributes?.showRating || false);
    const [isShowButton, setShowButton] = useState(editAttributes?.showButton || false);
    const [totalRating, setTotalRating] = useState(editAttributes?.rating || 5)
    const getNodeAttrs = useCallback(() => {
        const nodeAttrs = editor.state.selection.node?.attrs;
        if (nodeAttrs) {
            setEditAttributes((prev) => ({
                ...prev,
                showRating: nodeAttrs.showRating || false,
                rating: nodeAttrs.rating || 5,
                showButton: nodeAttrs.showButton || false,
                buttonText: nodeAttrs.buttonText || 'Buy Now',
                buttonUrl: nodeAttrs.buttonUrl || '',
            }));
            setLoopActive(nodeAttrs.showRating)
            setShowButton(nodeAttrs.showButton)
        }
    }, [editor]);
    const shouldShow = useCallback(() => {
        if (editor.isActive('product')) {
            getNodeAttrs();
        }
        return editor.isActive('product') && editor.isFocused ;
    }, [editor]);
    const setButtonAttribute = (key, value) => {
        setEditAttributes((prev) => {
            const newAttrs = { ...prev, [key]: value };
            return newAttrs;
        });
    };
    const toggleLoop = () => {
        const newValue = !isLoopActive;
        setLoopActive(newValue);
        setButtonAttribute("showRating", newValue);
    };
    const toggleForButton = () => {
        const newValue = !isShowButton;
        setShowButton(newValue);
        setButtonAttribute("showButton", newValue);
    };
    const handleApplyChange = () => {
        if (editor) {
            editor.chain().focus().updateAttributes("product", editAttributes).run();
        }
    };
    const rating = ["★", "★", "★", "★", "★"]
    const handleRatingClick = (rating) => {
        setEditAttributes((prev) => ({ ...prev, rating }));
        setTotalRating(rating);
    };
    return (
        <BubbleMenu
            editor={editor}
            shouldShow={shouldShow}
            updateDelay={0}
            tippyOptions={{
                zIndex: 99,
                popperOptions: {
                    modifiers: [{ name: 'flip', enabled: false }],
                },
                appendTo: () => appendTo?.current,
            }}
        >

            <div className="bubble-toggle-menu button-box image-bubble-menu  product">
                <div className='product-bubble-block'>
                    <div className='button-wrapp'>
                        <label class="toggle-button-label rating-label">Rating
                            <button aria-checked={isLoopActive}
                                onClick={toggleLoop} className={`toggle-button ${isLoopActive ? 'checked' : 'unchecked'}`} type="button" role="switch" >
                                <span className={`toggle-slider ${isLoopActive ? 'translate-x-4' : 'translate-x-0'}`}></span>
                            </button>
                        </label>
                        {
                            editAttributes?.showRating &&
                            <>
                                <div className='rating'>
                                  <span
                                   className={`rate ${1 <= totalRating ? 'highlighted' : ''}`}
                                   onClick={() => handleRatingClick(1)}
                                   style={{ cursor: 'pointer'}}
                                   >
                                    <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#F2AE40">
                                        <path d="M17.75 6.69221L11.5925 5.95272L8.99671 0.25L6.40096 5.95272L0.25 6.69221L4.7975 10.9626L3.59491 17.125L8.99671 14.0538L14.4051 17.125L13.2025 10.9626L17.75 6.69221Z"/>
                                    </svg>
                                  </span>
                                  <span
                                   className={`rate ${2 <= totalRating ? 'highlighted' : ''}`}
                                   onClick={() => handleRatingClick(2)}
                                   style={{ cursor: 'pointer'}}
                                   >
                                    <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#F2AE40">
                                        <path d="M17.75 6.69221L11.5925 5.95272L8.99671 0.25L6.40096 5.95272L0.25 6.69221L4.7975 10.9626L3.59491 17.125L8.99671 14.0538L14.4051 17.125L13.2025 10.9626L17.75 6.69221Z"/>
                                    </svg>
                                  </span>
                                  <span
                                   className={`rate ${3 <= totalRating ? 'highlighted' : ''}`}
                                   onClick={() => handleRatingClick(3)}
                                   style={{ cursor: 'pointer'}}
                                   >
                                    <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#F2AE40">
                                        <path d="M17.75 6.69221L11.5925 5.95272L8.99671 0.25L6.40096 5.95272L0.25 6.69221L4.7975 10.9626L3.59491 17.125L8.99671 14.0538L14.4051 17.125L13.2025 10.9626L17.75 6.69221Z"/>
                                    </svg>
                                  </span>
                                  <span
                                   className={`rate ${4 <= totalRating ? 'highlighted' : ''}`}
                                   onClick={() => handleRatingClick(4)}
                                   style={{ cursor: 'pointer'}}
                                   >
                                    <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#F2AE40">
                                        <path d="M17.75 6.69221L11.5925 5.95272L8.99671 0.25L6.40096 5.95272L0.25 6.69221L4.7975 10.9626L3.59491 17.125L8.99671 14.0538L14.4051 17.125L13.2025 10.9626L17.75 6.69221Z"/>
                                    </svg>
                                  </span>
                                  <span
                                   className={`rate ${5 <= totalRating ? 'highlighted' : ''}`}
                                   onClick={() => handleRatingClick(5)}
                                   style={{ cursor: 'pointer'}}
                                   >
                                    <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#F2AE40">
                                        <path d="M17.75 6.69221L11.5925 5.95272L8.99671 0.25L6.40096 5.95272L0.25 6.69221L4.7975 10.9626L3.59491 17.125L8.99671 14.0538L14.4051 17.125L13.2025 10.9626L17.75 6.69221Z"/>
                                    </svg>
                                  </span>
                                </div>
                            </>
                        }
                    </div>
                    <div className='button-wrapp'>
                        <label class="toggle-button-label">Button
                            <button aria-checked={isShowButton}
                                onClick={toggleForButton} className={`toggle-button ${isShowButton ? 'checked' : 'unchecked'}`} type="button" role="switch" >
                                <span className={`toggle-slider ${isShowButton ? 'translate-x-4' : 'translate-x-0'}`}></span>
                            </button>
                        </label>
                    </div>
                </div>
                {
                    editAttributes?.showButton &&
                    <>
                        <label className="text-box">
                            Button Text
                            <input
                                type="text"
                                placeholder="Button Title"
                                value={editAttributes.buttonText}
                                onChange={(e) => setEditAttributes({ ...editAttributes, buttonText: e.target.value })}
                            />
                        </label>
                        <div className='url-block'>
                            <label className="text-box">
                                Button URL
                                <input
                                    type="text"
                                    placeholder="Button URL"
                                    className='button-url'
                                    value={editAttributes.buttonUrl}
                                    onChange={(e) => setEditAttributes({ ...editAttributes, buttonUrl: e.target.value })}
                                />
                            </label>
                        </div>
                    </>
                }
                <div className='button-element publication-submit-button'>
                    <button onClick={handleApplyChange}>Apply</button>
                </div>
            </div>
        </BubbleMenu>
    );
};

export default ProductBubbleMenu;
