import { mergeAttributes, Node } from '@tiptap/core';
import { Plugin } from '@tiptap/pm/state';
import { Decoration, DecorationSet } from '@tiptap/pm/view';
import { getCellsInColumn, isRowSelected, selectRow } from './utils';

const TableCell = Node.create({
  name: 'tableCell',

  content: 'block+', // Do not allow table in table

  tableRole: 'cell',

  isolating: true,

  addOptions: () => ({
    HTMLAttributes: {},
  }),

  parseHTML: () => [{ tag: 'td' }],

  renderHTML: ({ HTMLAttributes }) => [
    'td',
    mergeAttributes(TableCell.options.HTMLAttributes, HTMLAttributes),
    0,
  ],

  addAttributes: () => ({
    colspan: {
      default: 1,
      parseHTML: (element) => parseInt(element.getAttribute('colspan') || '1', 10),
    },
    rowspan: {
      default: 1,
      parseHTML: (element) => parseInt(element.getAttribute('rowspan') || '1', 10),
    },
    // colwidth: {
    //   default: null,
    //   parseHTML: (element) => {
    //     const colwidth = element.getAttribute('colwidth');
    //     return colwidth ? [parseInt(colwidth, 10)] : null;
    //   },
    // },
    style: {
      default: null,
    },
  }),

  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          decorations: (state) => {
            const { doc, selection } = state;
            const decorations = [];
            const cells = getCellsInColumn(0)(selection);

            if (cells) {
              cells.forEach(({ pos }, index) => {
                decorations.push(
                  Decoration.widget(pos + 1, () => {
                    const rowSelected = isRowSelected(index)(selection);
                    const classNames = [
                      'grip-row',
                      rowSelected ? 'selected' : '',
                      index === 0 ? 'first' : '',
                      index === cells.length - 1 ? 'last' : '',
                    ].join(' ');

                    const grip = document.createElement('a');
                    grip.className = classNames;
                    grip.addEventListener('mousedown', (event) => {
                      event.preventDefault();
                      event.stopImmediatePropagation();
                      this.editor.view.dispatch(selectRow(index)(this.editor.state.tr));
                    });

                    return grip;
                  }),
                );
              });
            }

            return DecorationSet.create(doc, decorations);
          },
        },
      }),
    ];
  },
});

export default TableCell;
