import { Node, mergeAttributes } from '@tiptap/core';

export const Button = Node.create({
  name: 'button',

  group: 'block',
  atom: true,

  addAttributes() {
    return {
      title: {
        default: 'Button',
      },
      href: {
        default: '',
      },
      align: {
        default: 'center',
      },
      width: {
        default: 'auto',
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-type="button"]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    // Set up classes based on attributes
    const alignmentClass = `align-${HTMLAttributes.align}`;
    const widthClass = HTMLAttributes.width === 'full' ? 'width-full' : 'width-auto';
  
    return [
      'div',
      mergeAttributes({ 'data-type': 'button', class: 'button-block' }, HTMLAttributes),
      [
        'div',
        { class: `button-element ${alignmentClass} ${widthClass}` }, // Add default class 'button-element'
        [
          'a', // Use <a> tag for proper link handling
          {
            href: HTMLAttributes.href || '#', // Add fallback for href
            target: '_blank', // Open in new tab
            rel: 'noopener noreferrer', // Secure way to open in new tab
            class: 'button-link',
          },
          HTMLAttributes.title, // Display button text
        ],
      ],
    ];
  },

  addCommands() {
    return {
      setButton:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
      },
      updateButton:
        (options) =>
        ({ commands }) => {
          return commands.updateAttributes(this.name, options);
      },
      isActive: () => ({ commands }) => {
        return commands.isActive(this.name);
      },
    };
  },

  addNodeView() {
    return ({ node, getPos, editor }) => {
      const wrapper = document.createElement('div');
      wrapper.setAttribute('data-type', 'button');
  
      const buttonWrapper = document.createElement('div');
      const alignmentClass = `align-${node.attrs.align}`;
      const widthClass = node.attrs.width === 'full' ? 'width-full' : 'width-auto';
      buttonWrapper.className = `button-element ${alignmentClass} ${widthClass}`;
  
      // Create <a> element to properly handle the link
      const link = document.createElement('a');
      link.innerHTML = node.attrs.title;
      link.href = node.attrs.href || '#';
      link.target = '_blank'; // Open in a new tab
      link.rel = 'noopener noreferrer'; // For security
  
      buttonWrapper.appendChild(link);
      wrapper.appendChild(buttonWrapper);
  
      return {
        dom: wrapper,
        contentDOM: buttonWrapper,
        update: (updatedNode) => {
          if (updatedNode.attrs !== node.attrs) {
            link.innerHTML = updatedNode.attrs.title;
            link.href = updatedNode.attrs.href || '#';
  
            const newAlignmentClass = `align-${updatedNode.attrs.align}`;
            const newWidthClass = updatedNode.attrs.width === 'full' ? 'width-full' : 'width-auto';
            buttonWrapper.className = `button-element ${newAlignmentClass} ${newWidthClass}`;
          }
          return true;
        },
      };
    };
  },
});
