import { Node, ReactNodeViewRenderer } from '@tiptap/react';
import { NodeViewWrapper } from '@tiptap/react';
import { useEffect, useRef, useState } from 'react';

const decodeHtml = (htmlString) => {
    let decodedStr = htmlString;
    while (decodedStr.includes('&lt;') || decodedStr.includes('&gt;') || decodedStr.includes('&amp;') || decodedStr.includes('&quot;') || decodedStr.includes('&#039;')) {
        decodedStr = decodedStr
            .replace(/&lt;/g, "<")
            .replace(/&gt;/g, ">")
            .replace(/&amp;/g, "&")
            .replace(/&quot;/g, '"')
            .replace(/&#039;/g, "'");
    }
    return decodedStr;
};

const OtherNodeView = ({ node, editor, getPos }) => {
    const dataRef = useRef(null);

    const [urlLink, setUrlLink] = useState(node.attrs.embedHtml || '');
    const [isLoading, setIsLoading] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(!!node.attrs.embedHtml);
    const [errorMessage, setErrorMesssage] = useState("");

    const handleInputChange = (e) => {
        const trimmedValue = e.target.value.trim();
        setUrlLink(trimmedValue);
        setErrorMesssage("");
    };

    const handleKeyPress = async (e) => {
        if (e.key === 'Enter') {
            try {
                setIsLoading(true);
                if (urlLink !== '') {
                    const embedHtml = urlLink; // Set the iframe HTML as it is

                    editor.commands.setOtherEmbeds({ embedHtml });

                    setErrorMesssage("");
                    setIsDataFetched(true);
                    setIsLoading(false);
                } else {
                    setErrorMesssage('Please enter a valid URL or iframe code');
                }
            } catch (error) {
                setIsLoading(false);
                setErrorMesssage("The URL or iframe code is not valid");
                console.error('Failed to process URL or iframe code:', error);
            }
        }
    };

    useEffect(() => {
        if (isDataFetched && node.attrs.embedHtml) {
            const decodedHtml = decodeHtml(node.attrs.embedHtml);

            dataRef.current.innerHTML = decodedHtml;

            // Load Twitter's widgets.js script if iframe or blockquote was fetched
            const script = document.createElement('script');
            script.src = 'https://platform.twitter.com/widgets.js';
            script.async = true;
            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
            };
        }
    }, [isDataFetched, node.attrs.embedHtml]);

    return (
        <NodeViewWrapper>
            <div data-type='other' className={`other-node-wrapper`} data-drag-handle>
                <div className='other-urlInput'>
                    {isLoading ? (
                        <div className='linkbutton-loader loader-buttonHeight'>
                            <div className='ring-loader'></div>
                        </div>
                    ) : (
                        <div className={`${isDataFetched ? 'none' : 'show'}`}>
                            <svg width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.8346 5.25H1.16797M8.16797 10.2083L9.6263 8.75L8.16797 7.29167M5.83464 7.29167L4.3763 8.75L5.83464 10.2083M1.16797 4.55L1.16797 9.45C1.16797 10.4301 1.16797 10.9201 1.35871 11.2945C1.52649 11.6238 1.7942 11.8915 2.12349 12.0593C2.49783 12.25 2.98788 12.25 3.96797 12.25H10.0346C11.0147 12.25 11.5048 12.25 11.8791 12.0593C12.2084 11.8915 12.4761 11.6238 12.6439 11.2945C12.8346 10.9201 12.8346 10.4301 12.8346 9.45V4.55C12.8346 3.56991 12.8346 3.07986 12.6439 2.70552C12.4761 2.37623 12.2084 2.10852 11.8791 1.94074C11.5048 1.75 11.0147 1.75 10.0346 1.75L3.96797 1.75C2.98788 1.75 2.49783 1.75 2.12349 1.94074C1.7942 2.10852 1.52649 2.37623 1.35871 2.70552C1.16797 3.07986 1.16797 3.56991 1.16797 4.55Z" stroke="#0A0A0A" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>

                            <textarea
                                className='urlInput embedCode'
                                type='text'
                                placeholder='Enter a code and get content'
                                value={urlLink}
                                onChange={handleInputChange}
                                onKeyPress={handleKeyPress}
                            />
                            {errorMessage && <p className="error-message">{errorMessage}</p>}
                        </div>
                    )}
                    <div ref={dataRef} className="embed-code" />
                </div>
            </div>
        </NodeViewWrapper>
    )
}

export const OtherEmbeds = Node.create({
    name: 'other',

    isolating: true,
    defining: true,
    group: 'block',
    draggable: true,
    selectable: true,
    inline: false,

    addAttributes() {
        return {
            embedHtml: {
                default: '',
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: `div[data-type="${this.name}"]`,
                getAttrs: (dom) => ({
                    embedHtml: dom.innerHTML,
                }),
            },
        ];
    },

    renderHTML({ node }) {
        return [
            'div',
            { 'data-type': this.name },
            node.attrs.embedHtml || '',  // Render the embedded content's HTML
        ];
    },

    addCommands() {
        return {
            setOtherEmbeds:
                (attrs) =>
                    ({ commands }) =>
                        commands.insertContent({
                            type: this.name,
                            attrs,
                        }),
        }
    },

    addNodeView() {
        return ReactNodeViewRenderer(OtherNodeView);
    },
});
