import { Node, ReactNodeViewRenderer } from '@tiptap/react';
import { NodeViewWrapper } from '@tiptap/react'

const OtherLinkBlockView = ({node}) => {

    return (
        <NodeViewWrapper>
            <div contentEditable={false} className='link-content'>
                <div className="textBlock linkBox">
                    <div className='linkImageSection'>
                        <img className="previewImage" src={node.attrs.imageUrl} alt={node.attrs.title} />
                    </div>
                    <div className="linkData">
                        <div>
                            <p className="linkDataTitle">{node.attrs.title}</p>
                            <span className="linkDataDiscripation">{node.attrs.description}</span>
                        </div>
                          {node.attrs.siteTitle &&
                              <div className='siteData'>
                                  {node.attrs.favicon &&
                                      <img src={node.attrs.favicon} alt={node.attrs.siteTitle} />
                                  }
                                  <p>{node.attrs.siteTitle}</p>
                              </div>
                          }
                          <div className='siteData siteUrl'>
                              <p>{node.attrs.url}</p>
                          </div>
                    </div>
                 </div>
            </div>
        </NodeViewWrapper>
    )
}
export const OtherLinkBlock = Node.create({
    name: 'otherLinkBlock',
  
    group: 'block',

    defining: true,

    isolating: true,
  
    addAttributes() {
      return {
        title: {
          default: '',
        },
        description: {
          default: '',
        },
        imageUrl: {
          default: '',
        },
      };
    },
  
    parseHTML() {
      return [
        {
          tag: `div[data-type="${this.name}"]`,
          getAttrs: (node) => {
            const element = node;
            return {
              title: element.querySelector('.bookmark-title')?.innerText || '',
              description: element.querySelector('.bookmark-description')?.innerText || '',
              imageUrl: element.querySelector('.bookmark-image img')?.getAttribute('src') || '',
              siteTitle: element.querySelector('.bookmark-siteTitle')?.innerText || '',
              favicon: element.querySelector('.bookmark-favicon img')?.getAttribute('src') || '',
              url: element.querySelector('.bookmark-url a')?.getAttribute('href') || '',
            };
          }
        },
      ];
    },
  
    renderHTML({ HTMLAttributes }) {
      return [
        'div',
        { 'data-type': this.name, class: 'link-content other-link-block' },
        [
          'a',
          { href: HTMLAttributes.url, target: '_blank' },
          [
            'div',
            { class: 'textBlock linkBox' },
            [
              'div',
              { class: 'linkImageSection' },
              HTMLAttributes.imageUrl
                ? ['img', { class: 'previewImage', src: HTMLAttributes.imageUrl, alt: HTMLAttributes.title }]
                : '',
            ],
            [
              'div',
              { class: 'linkData' },
              [
                'div',
                {},
                [
                  'p',
                  { class: 'linkDataTitle' },
                  HTMLAttributes.title || 'No Title',
                ],
                [
                  'span',
                  { class: 'linkDataDiscripation' },
                  HTMLAttributes.description || 'No Description',
                ],
              ],
              HTMLAttributes.siteTitle
                ? [
                    'div',
                    { class: 'siteData' },
                    HTMLAttributes.favicon
                      ? ['img', { src: HTMLAttributes.favicon, alt: HTMLAttributes.siteTitle }]
                      : '',
                    ['p', {}, HTMLAttributes.siteTitle],
                  ]
                : '',
              [
                'div',
                { class: 'siteData siteUrl' },
                ['p', {}, HTMLAttributes.url || 'No URL'],
              ],
            ],
          ],
        ],
      ];
    },
  
    addCommands() {
      return {
        setOtherLinkBlock:
          (options) =>
          ({ commands }) => {
            return commands.insertContent({
              type: this.name,
              attrs: {
                title: options?.title || 'No Title',
                description: options?.description || 'No Description',
                imageUrl: options?.imageUrl || '',
              },
            });
          },
      };
    },
  
    addNodeView() {
      return ReactNodeViewRenderer(OtherLinkBlockView);
    },
  });
  