import { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import '.././App.css';
import search from '.././images/search.svg';
import clear from '.././images/delete.svg';
import down from '.././images/down_arrow.svg';
import top from '.././images/arrow_top.svg';
import PlusIcon from '.././images/plus-icon.svg'
import Pagination from '../Components/Pagination';
import SubsListItem from '../Components/SubsListItem';
import category from '.././images/category.svg'
import axios from 'axios';
import NoResultsSubscriber from '../Components/NoResultsSubscriber';
import CategoriesDropdown from '../Components/CategoriesDropdown';
import DeleteSubscriberModel from '../Components/Modal/DeleteSubscriberModel';
import AddSubscriberModel from '../Components/Modal/AddSubscriberModel';
import NotificationMessage from '../Components/NotificationMessage';

function Subscribers(props) {

    // Set active page
    props.setActivePage('Subscribers');
    const isFreePlan = props?.billingDetails?.subscriptions?.plan === "free_plan" ? true : false;

    // State declarations
    const ref = useRef();
    const [activeFilter, setActiveFilter] = useState('');
    const [pages, setPages] = useState([]);
    const [searchInput, setSearchInput] = useState('');    
    const [emailSort, setEmailSort] = useState(false);
    const [dateSort, setDateSort] = useState(false);
    const [itemToDelete, setItemToDelete] = useState('');
    const [originalData, setOriginalData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalPage, setTotalPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedCategory, setSelectedCategory] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [categorySelect, setCategorySelect] = useState(false)
    const [allSelected, setAllSelected] = useState(false)
    const [selectedItems, setSelectedItems] = useState([])
    const [hasPaidSubscription, setHasPaidSubscription] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [showMultiNotify, setShowMultiNotify] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [existEmail, setExistEmail] = useState([])
    const [validateEmail, setValidateMail] = useState("")
    const [isAddSubscriberLoading, setAddSubscriberIsLoading] = useState(false);
    const [subscriberNotify, setSubscriberNotify] = useState(false)
    const [multiSubscriberNotify, setMultiSubscriberNotify] = useState(false)
    const [newMails, setNewMails] = useState([])
    // const [selectPlan, setSelectPlan] = useState("")
    const [selectedCategoryPlan, setSelectedCategoryPlan] = useState({ value: 'freePlan', label: 'Free subscription' });
    const [verifySubscriberNotify, setVerifySubscriberNotify] = useState(false);
    const [subscriberCount, setSubscriberCount]= useState(0)
    const [showDeleteModel, setShowDeleteModel] = useState(false);

    // Fetch subscriber data from the API
    const user_id = JSON.parse(localStorage.getItem("userinfo"))?._id
    const [messages, setMessages] = useState("");
    const [subscriberAddedNotify, setSubscriberAddedNotify] = useState(false);

    useEffect(() => {
        const webSocket = new WebSocket('ws://localhost:8080');
    
        webSocket.onopen = () => {
        //   console.log('Connected to WebSocket server');

        };
    
        webSocket.onmessage = (event) => {
            const parsedData = JSON.parse(event.data);
            if(parsedData?.author_id === user_id){
                if(parsedData?.message !== "" && parsedData?.message !== undefined){
                    setSubscriberAddedNotify(true);
                    setMessages(parsedData?.message);
                }
            }
        };
    
        return () => {
            webSocket.close();
        };
    }, [messages]);
    useEffect(() => {
        fetchData();
    }, [user_id, currentPage, searchInput, activeFilter,selectedCategory]);
    
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(false)
            try {
                setIsLoading(true)
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/subscriber/list/${user_id}`);
                const value = response?.data?.planName?.map((item) => item?.planName).filter((planName) => planName);

                if (value && value.length > 0) {
                    setCategoryList(value); // Set the list if there are valid planNames
                } else {
                    setCategoryList(["Free Plan"]); // Default to "Free Plan" if no valid plans
                }
                setIsLoading(false)
            } catch (error) {
                setIsLoading(false)
                console.error(error, "error");
            }
        };
        fetchData();
    }, [user_id]);
    
    // useEffect(() => {
    //     if(selectedCategoryPlan?.value === "planOne"){
    //         setSelectPlan(props?.userMetaData?.subsPlans?.planOne?.planNameOne)
    //     }
    //     if(selectedCategoryPlan?.value === "freePlan"){
    //         setSelectPlan(props?.userMetaData?.subsPlans?.freePlan?.planName)
    //     }
    // }, [newMails])
    
    useEffect(() => {
        searchPage();
    }, [searchInput, originalData, activeFilter]);

    useEffect(() => {
        setCurrentPage(1);
    }, [activeFilter,selectedCategory]);

    const userData = async () =>{
        try{
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/user/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
            )
            setSubscriberCount(response?.data?.subscriberCount)
            if(response?.data?.subscriberCount > 0){
                setVerifySubscriberNotify(true)
            }
        }catch(error){
            console.log(error, "error");  
        }
    }
    useEffect(()=>{
        userData()
    },[])

    const fetchData = async () => {
        const search = searchInput.toLowerCase();
        setIsLoading(false)
        try {
            setIsLoading(true)
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/subscriber/list/${user_id}?page=${currentPage}&filter=${activeFilter}&search=${search}&planName=${selectedCategory}`);
            setOriginalData(response?.data?.result || []);
            setTotalPage(response?.data?.totalPages || 1)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.error(error, "error");
        }
    };

    function searchPage() {
        const input = searchInput.toLowerCase();
        const filteredData = input === '' 
          ? originalData 
          : originalData.filter(element => element?.email?.toLowerCase().startsWith(input));
        setPages(filteredData);
    }

    useEffect(() => {
        var id = itemToDelete;
        var result = [];
        pages?.forEach(element => {
            if (element.id === id) {
                return false
            } else {
                result.push(element)
            }
        });
        setPages(result)

    }, [itemToDelete])


    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (e.target != null && showDeleteModel && (e.target.classList.contains('overlay'))) {
                setShowDeleteModel(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [showDeleteModel]);

    const closeDeleteModel = () => {
        setShowDeleteModel(false);
        document.body.classList.remove('hidden-message');
    }

    const handleDelete = () => {
        if (selectedItems.length > 0) {
            setShowDeleteModel(true);
            document.body.classList.add('hidden-message');
        }else {
            setShowDeleteModel(false);
            document.body.classList.remove('hidden-message');
        }
    }

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (categorySelect && ref.current && !ref.current.contains(e.target)) {
                setCategorySelect(false);
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [categorySelect]);
    const [statusSort, setStatusSort] = useState(false)

    function sortByStatus() {
        var result;

        if (statusSort === true) {
            result = [...pages].sort((a, b) => (a.emailVerification - b.emailVerification))
        } else {
            result = [...pages].sort((a, b) => -1 * a.emailVerification - (b.emailVerification))
        }

        setPages(result)
    }

    const [planSort, setPlanSort] = useState(false)

    function sortByplan() {
        var result;
        if(planSort === true){
            result = [...pages].sort((a, b) => a.planName.localeCompare(b.planName))
        } else{
            result = [...pages].sort((a, b) => -1 * a.planName.localeCompare(b.planName))
        }
        setPages(result)
    }

    function sortByEmail() {
        var result;
        if (emailSort === true) {
            result = [...pages].sort((a, b) => a.email.localeCompare(b.email))
        } else {
            result = [...pages].sort((a, b) => -1 * a.email.localeCompare(b.email))
        }

        setPages(result)
    }

    function sortByDate() {
        var result = [];

        if (dateSort === false) {

            result = [...pages].sort(function (a, b) {
                var now = moment();

                var dateA = moment(a.createdAt).format('ll');
                var daysA = moment(dateA).diff(now, 'Days');

                var dateB = moment(b.createdAt).format('ll');
                var daysB = moment(dateB).diff(now, 'Days');
                return daysA - daysB;
            })
        } else {
            result = [...pages].sort(function (a, b) {
                var now = moment();

                var dateA = moment(a.createdAt).format('ll');
                var daysA = moment(dateA).diff(now, 'Days');

                var dateB = moment(b.createdAt).format('ll');
                var daysB = moment(dateB).diff(now, 'Days');
                return daysB - daysA;
            })
        }
        setPages(result)
    }

    const handleCheckboxChange = (itemId) => {
        setSelectedItems((prevSelectedItems) => {
            let updatedSelectedItems;
            if (prevSelectedItems.includes(itemId)) {
                updatedSelectedItems = prevSelectedItems.filter((id) => id !== itemId);
            } else {
                updatedSelectedItems = [...prevSelectedItems, itemId];
            }
            
            // Check if any selected item has planOne
            const hasPaid = updatedSelectedItems.some(id => pages.find(page => page._id === id && page.plan === 'planOne'));
            setHasPaidSubscription(hasPaid);
            
            setAllSelected(updatedSelectedItems.length === pages.length);
            return updatedSelectedItems;
        });
    };

    const handleSelectAll = () => {
        if (allSelected) {
            setSelectedItems([]);
            setHasPaidSubscription(false);
        } else {
            const allItemIds = pages.map((item) => item._id).filter((itemId) => itemId);
            setSelectedItems(allItemIds);
    
            const hasPlanOne = pages.some((item) => item.plan === 'planOne');
            setHasPaidSubscription(hasPlanOne);
        }
        setAllSelected(!allSelected);
    };

    const [showSubcriberPopup,setShowSubcriberPopup] = useState(false);

    function handleOpenAddSubcriberPopup() {
        setShowSubcriberPopup(true)
        setErrorMessage("")
        setExistEmail([])
        setValidateMail("")
        setAddSubscriberIsLoading(false)
        setSelectedCategoryPlan({ value: 'freePlan', label: 'Free subscription' })
    }

    return (
        <section className='dashboard subs'>
            <div className="container">
                <div className="dashboardTop">
                    <div className="dashboardTopText">
                        <div className='dashboardTopLeft'>
                            <p>Subscribers</p>
                            <p>Track, manage and forecast your customers and orders.</p>
                        </div>
                        <button className="pagesbtn add-subcriber-btn" onClick={handleOpenAddSubcriberPopup}>
                            <img src={PlusIcon}  className="plusIcon" />
                            <span className="pagesbtnTitel">Add subscribers</span>
                        </button>
                    </div>
                </div>

                <div className="pagesSorting">
                <div className='pagesSortingButtons'>
                        <button className={(activeFilter === '') ? 'pagesSortingActive' : ''} onClick={e => {setActiveFilter(''); } }>All Subscribers</button>
                        { ! isFreePlan &&
                            <>                            
                            <button className={(activeFilter === 'freeSubscribers') ? 'pagesSortingActive' : ''} onClick={e => {setActiveFilter('freeSubscribers'); }}>Free Subscribers</button>
                            <button className={(activeFilter === 'paidSubscribers') ? 'pagesSortingActive' : ''} onClick={e => setActiveFilter('paidSubscribers')}>Paid Subscribers</button>
                            <button className={(activeFilter === 'monthlySubscribers') ? 'pagesSortingActive' : ''} onClick={e => setActiveFilter('monthlySubscribers')}>Monthly Subscribers</button>
                            <button className={(activeFilter === 'yearlySubscribers') ? 'pagesSortingActive' : ''} onClick={e => setActiveFilter('yearlySubscribers')}>Yearly Subscribers</button>
                            <button className={(activeFilter === 'trialSubscribers') ? 'pagesSortingActive' : ''} onClick={e => setActiveFilter('trialSubscribers')}>Trial Subscribers</button>
                            </>
                        }
                    </div>

                    <div className='pagesSearch'>
                        <div className="pageSearchInput">
                            <input type="text" name="pages_search" id="pages_search" placeholder={'Search'} value={searchInput} onChange={e => setSearchInput(e.target.value)} />
                            <img src={search} alt="Search" />
                        </div>
                        
                        <div className="publicationsCategoryButton" ref={ref}>
                            <button onClick={e => setCategorySelect(!categorySelect)}><img src={category} alt="Category" />Plans {(selectedCategory.length > 0) ? `(${selectedCategory.length})` : ''}</button>

                            {
                                (categorySelect === true) ? <CategoriesDropdown categories={categoryList} selected={selectedCategory} changeCategory={setSelectedCategory} /> : ''
                            }
                        </div>

                        <div className='deleteIcon' onClick={() => handleDelete()}>
                            <button className='deleteTooltip'>Delete</button>
                            <img className='deleteIconImage' src={clear} alt="Clear Input" />
                        </div>

                    </div>
                </div>

                <div className="pagesTableWrapper">
                    <div className={(pages.length > 0) ? 'pagesTable' : 'pagesTable tableBottomRounded'}>
                        <div className="pagesTableTop subTable subTableTop ">
                            <div>
                                <input type={'checkbox'} checked={(allSelected) ? true : false} onChange={handleSelectAll} />
                                <div onClick={e => {
                                    setEmailSort(!emailSort)
                                    sortByEmail()
                                }}>Email<img src={(emailSort === false) ? down : top} alt="Down" /></div>
                                </div>

                            <div onClick={e => {
                                setStatusSort(!statusSort)
                                sortByStatus()
                            }}>Status <img src={(statusSort === false) ? down : top} alt="Down"  /></div>
                            <div onClick={e => {setPlanSort(!planSort); sortByplan()}}>Plan <img src={(planSort === false) ? down : top} alt="Down"  /></div>
                            <div onClick={e => {
                                setDateSort(!dateSort)
                                sortByDate()
                            }}>Date <img src={(dateSort === false) ? down : top} alt="Down" /></div>
                            <div>Actions</div>
                        </div>

                        {isLoading ? 
                            <div className="loader-container">
                                <div className="loader"></div>
                            </div>
                            :
                            (pages?.length > 0)
                                ? pages?.map((item, index) => (
                                    <SubsListItem allSelected={allSelected} selectedItems={selectedItems} setSelectedItems={setSelectedItems} handleCheckboxChange={handleCheckboxChange} setItemToDelete={setItemToDelete} key={index} index={index} data={item} fetchData={fetchData} setShowNotification={setShowNotification} />
                                ))
                                : <NoResultsSubscriber />
                        }

                    </div>
                </div>
                {
                    ( totalPage > 1 ) && <Pagination totalPage={totalPage} currentPage={currentPage} setCurrentPage={setCurrentPage} /> 
                }
                {showDeleteModel &&
                    <>
                        <div className="overlay"></div>
                        <DeleteSubscriberModel isOpen={showDeleteModel} onClose={closeDeleteModel} subsciberIds={selectedItems} hasPaidSubscription={hasPaidSubscription} fetchData={fetchData} setShowNotification={setShowNotification} setShowMultiNotify={setShowMultiNotify}/>
                    </>
                }
                {showSubcriberPopup &&
                    <>
                        <div className="overlay"></div>
                        <AddSubscriberModel setShowSubcriberPopup={setShowSubcriberPopup} showSubcriberPopup={showSubcriberPopup} errorMessage={errorMessage} setErrorMessage={setErrorMessage} existEmail={existEmail} setExistEmail={setExistEmail} validateEmail={validateEmail} setValidateMail={setValidateMail} isAddSubscriberLoading={isAddSubscriberLoading} setAddSubscriberIsLoading={setAddSubscriberIsLoading} userMetaData={props?.userMetaData} setSubscriberNotify={setSubscriberNotify} setMultiSubscriberNotify={setMultiSubscriberNotify} setNewMails={setNewMails} newMails={newMails} setSelectedCategory={setSelectedCategoryPlan} setSelectedCategoryPlan={setSelectedCategoryPlan } selectedCategory={selectedCategoryPlan}/>
                    </>
                }
                {subscriberNotify && (
                    <NotificationMessage  openNotify={subscriberNotify} setOpenNotify={setSubscriberNotify} type={'success'} title={`Invitation to subscribe sent to ${newMails[0]} `} />
                )}
                {multiSubscriberNotify && (
                    <NotificationMessage  openNotify={multiSubscriberNotify} setOpenNotify={setMultiSubscriberNotify} type={'success'} title={`Invitation to subscribe sent to ${newMails[0]} and ${newMails.length - 1} more`} />
                )}
                {showNotification && (
                    <NotificationMessage  openNotify={showNotification} setOpenNotify={setShowNotification} type={'success'} title={`A subscriber was successfully deleted!`} />
                )}
                {subscriberAddedNotify && (
                    <NotificationMessage  openNotify={subscriberAddedNotify} setOpenNotify={setSubscriberAddedNotify} type={'success'} title={messages} name="liveNotification"/>
                )}
                {showMultiNotify && (
                    <NotificationMessage  openNotify={showMultiNotify} setOpenNotify={setShowMultiNotify} type={'success'} title={`${selectedItems.length} subscribers were successfully deleted!`} />
                )}
                {(verifySubscriberNotify && subscriberCount > 0) && (
                    <NotificationMessage  openNotify={verifySubscriberNotify} setOpenNotify={setVerifySubscriberNotify} type={'success'} title={`${(subscriberCount === 1) ? `A subscriber was successfully added!`: `${subscriberCount} subscribers were successfully added!`}`} />
                )}
            </div>
        </section>
    );
}

export default Subscribers;
