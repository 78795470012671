import { Node } from '@tiptap/core';

export const QuoteCaption = Node.create({
  name: 'quoteCaption',

  group: 'block',

  content: 'text*',

  defining: true,

  isolating: true,

  parseHTML() {
    return [
      {
        tag: 'figcaption',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['figcaption', HTMLAttributes, 0];
  },

  addKeyboardShortcuts() {
    return {
      Enter: ({ editor }) => {
        const {
          state: {
            selection: { $from, empty },
          },
        } = editor;

        // Check if selection is empty and current node type is `quoteCaption`
        if (!empty || $from.parent.type !== this.type) {
          return false;
        }

        // Check if the cursor is at the end of the `quoteCaption` node
        const isAtEnd = $from.parentOffset === $from.parent.nodeSize - 2;

        if (!isAtEnd) {
          return false;
        }

        // Insert a new paragraph at the end of the `quoteCaption` node
        const pos = editor.state.selection.$from.end();

        return editor.chain().focus(pos).insertContentAt(pos, { type: 'paragraph' }).run();
      },
    };
  },
});

export default QuoteCaption;
