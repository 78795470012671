import { Node, mergeAttributes } from '@tiptap/core';

const Column = Node.create({
  name: 'column',
  content: 'block+',
  isolating: true,

  addAttributes: () => ({
    position: {
      default: '',
      parseHTML: element => element.getAttribute('data-position'),
      renderHTML: attributes => ({ 'data-position': attributes?.position || 'right', class: `column-block column-${attributes?.position || 'right'}` }),
    },
  }),

  renderHTML: ({ HTMLAttributes }) => [
    'div', 
    mergeAttributes(HTMLAttributes, { 'data-type': 'column' }), 
    0
  ],

  parseHTML: () => [
    {
      tag: 'div[data-type="column"]',
    },
  ],
});

export default Column;
