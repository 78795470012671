import React, { useState, useCallback, useMemo } from 'react';

const useLinkEditorState = ({ initialUrl = '', initialOpenInNewTab = false, onSetLink,setShowEdit , setImageUrl, setImageUrlTarget, setButtonAttribute}) => {
  const [url, setUrl] = useState(initialUrl);
  const [openInNewTab, setOpenInNewTab] = useState(initialOpenInNewTab);
  
  const onChange = useCallback(event => {
    setUrl(event.target.value);
  }, []);

  const isValidUrl = useMemo(() => /^(\S+):(\/\/)?\S+$/.test(url), [url]);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      if (isValidUrl) {
        onSetLink(url, openInNewTab);
        setImageUrl(url)
        setImageUrlTarget(openInNewTab)
        setShowEdit(true)
        setButtonAttribute("imagelink", url)
        setButtonAttribute("target", openInNewTab)

      }
    },
    [url, isValidUrl, openInNewTab, onSetLink]
  );

  return {
    url,
    setUrl,
    openInNewTab,
    setOpenInNewTab,
    onChange,
    handleSubmit,
    isValidUrl,
  };
};

const ImageLinkEditorPreview = ({ onSetLink, initialOpenInNewTab = false, initialUrl = '' ,setShowEdit, setImageUrl, setImageUrlTarget, setButtonAttribute}) => {
  const state = useLinkEditorState({ onSetLink, initialOpenInNewTab, initialUrl,setShowEdit, setImageUrl, setImageUrlTarget, setButtonAttribute });

  const toggleOpenInNewTab = () => {
    state.setOpenInNewTab(prev => !prev);
  };

  return (
    <div className="bubble-toggle-menu link-box">
      <form onSubmit={state.handleSubmit}>
        <label className="url-box">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-link w-4 h-4 flex-none text-black dark:text-white">
            <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
            <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
          </svg>
          <input
            type="url"
            placeholder="Enter URL"
            value={state.url}
            onChange={state.onChange}
          />
        </label>
        <button className='setlinkBtn' type='submit' disabled={!state.isValidUrl}>Set Link</button>
      </form>
      <div>
        <label className="toggle-button-label">
          Open in new tab
          <button
            className={`toggle-button toggle-button-image ${state.openInNewTab ? 'checked' : 'unchecked'}`}
            type="button"
            role="switch"
            aria-checked={state.openInNewTab}
            onClick={toggleOpenInNewTab}
          >
            <span className={`toggle-slider ${state.openInNewTab ? 'translate-x-4' : 'translate-x-0'}`}></span>
          </button>
        </label>
      </div>
    </div>
  );
};

export default ImageLinkEditorPreview;
