import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';


import '.././App.css';

import ChangePlan from './Settings/ChangePlan';

import currencies from '../utils/currency.json'

import iconLock from '../images/locked.svg'
import WhatsApp from '.././images/whatsapp-icon.png'
import chat from '.././images/chat.png'
import mail from '.././images/gmail-icon.png'
import sms from '.././images/sms.png'
import messanger from '.././images/fb_messenger.svg'

import { formatNumber, formattedDate } from '../utils/functions';
import { TotalEarningChart } from './TotalEarningChart';
import { ChangePlanSuccessModel } from './Modal/ChangePlanSuccessModel';


function Boxes(props) {
    const [activatedPlan, setActivatedPlan] = useState('free_plan');
    const [isChangePlanOpen, setIsChangePlanOpen] = useState(false);
    const [openSuccessPlanPopup, setOpenSuccessPlanPopup] = useState(false)
    const stripeBalance = props.stripeBalance;
   
    const handleChangePlanPopup = () => {
        if( activatedPlan === 'free_plan' ) {
            setIsChangePlanOpen(true); 
        }
    };

    useEffect(() => {
        setActivatedPlan(props.planDetails?.plan || 'free_plan')
    }, [props.planDetails]);

    return (
        <>
            <div className="dashboardBoxes dashboard-wrap">
                <div className='dashboardUserbox dashboardUserboxFlex'>
                    <span className=''>
                        <p className='dashUserStatus '>Total Earnings</p>
                        <h2>{currencies[stripeBalance?.currency?.toUpperCase()]?.symbol_native || '$'}{(formatNumber(stripeBalance.totalEarnings > 0 ? stripeBalance.totalEarnings/100 : 0))} {stripeBalance?.currency?.toUpperCase() || 'USD'}</h2>
                    </span>
                    
                    <TotalEarningChart />

                    { activatedPlan === 'free_plan' && 
                        <div className='overlayLock' onClick={handleChangePlanPopup}>
                            <img  src={iconLock} alt="lock-icon" />
                        </div>
                    }
                </div>
                
                <div className='dashboardUserbox'>
                    <p className='dashUserStatus'>Future Payouts</p>
                    <h2>{currencies[stripeBalance?.currency?.toUpperCase()]?.symbol_native || '$'}{(formatNumber(stripeBalance.futureBalance > 0 ? stripeBalance.futureBalance/100 : 0))} {stripeBalance?.currency?.toUpperCase() || 'USD'}</h2>
                    {(stripeBalance.futureBalance > 0 && stripeBalance.expectedDate) && 
                        <span className='graphPercent payoutDate'>
                            {formattedDate(stripeBalance.expectedDate)}
                        </span>
                    }
                    
                    { activatedPlan === 'free_plan' && 
                        <div className='overlayLock' onClick={handleChangePlanPopup}>
                            <img  src={iconLock} alt="lock-icon" />
                        </div>
                    }
                </div>
                <div className='dashboardPremium'>
                    <div className="dashboardPremiumLeft">
                        { (activatedPlan !== 'personal_assistant_plan') && 
                            <>
                                <p className='dashUser'>Need help updating? Get a profile assistant</p>
                                <div className="dashPremLink">
                                    <a href="#" onClick={e => (setIsChangePlanOpen(true))}> Upgrade</a>
                                </div>
                            </>
                        } 
                        { activatedPlan === 'personal_assistant_plan' &&
                            <>
                                <p className='dashUser'>Let us know if you need any assistant:</p>
                                <div className='dashboard-iconList'>
                                    <Link to="#"><img src={WhatsApp} alt="whatsApp_icon" /></Link>
                                    {/* <Link to="#"><img src={messanger} alt="messanger_icon" /></Link> */}
                                    <Link to="#"><img src={sms} alt="sms_icon" /></Link>
                                    <Link to="#"><img src={mail} alt="mail_icon" /></Link>
                                    <Link to="#"><img src={chat} alt="chat_icon" /></Link>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
            {isChangePlanOpen &&
                <ChangePlan setIsChangePlanOpen={setIsChangePlanOpen} isChangePlanOpen={isChangePlanOpen} planDetails={props.planDetails} paymentMethods={props.paymentMethods} isStipeConnected={props.isStipeConnected} updateData={props.fetchBillingData} setOpenSuccessPlanPopup={setOpenSuccessPlanPopup} openSuccessPlanPopup={openSuccessPlanPopup}/>
            }
            {
                openSuccessPlanPopup && 
                <ChangePlanSuccessModel setOpenSuccessPlanPopup={setOpenSuccessPlanPopup} openSuccessPlanPopup={openSuccessPlanPopup} plan={props?.planDetails?.plan}/>
            }
        </>
    );
}

export default Boxes;
