import { useState } from 'react';
import { BubbleMenu } from '@tiptap/react'

import icons from '../iconsList.json';

import {ContentTypes} from './ContentTypes';
import TextmenuCommands from './TextmenuCommands';
import ContentTypePicker from './ContentTypePicker';
import FontSizePicker from './FontSizePicker';
import { EditLinkPopover } from './EditLinkPopover';
import ColorPicker from './Colorpicker';
import AIDropdown from './AIDropdown';

const TextMenu = ({ editor }) => {
  const commands = TextmenuCommands(editor)
  const blockOptions = ContentTypes(editor);
  

  const [editLinkPopoverOpen, setEditLinkPopoverOpen] = useState(false);
  const [highlightPopoverOpen, setHighlightPopoverOpen] = useState(false);
  const [colorPopoverOpen, setColorPopoverOpen] = useState(false);
  const [moreOptionsPopoverOpen, setMoreOptionsPopoverOpen] = useState(false);
  const [isOpenAIDropdown, setIsOpenAIDropdown] = useState(false);
  const [isOpenContentTypePicker, setIsOpenContentTypePicker] = useState(false);
  const [isOpenFontSizePicker, setIsOpenFontSizePicker] = useState(false)

  const isTextSelected = ({ editor }) => {
    const { state: { doc, selection: { empty, from, to } } } = editor;

    if (empty || !editor.isEditable || !doc.textBetween(from, to).length) {
      return false;
    }
    return true;
  };

  const shouldShow = ({ editor, view, state }) => {
    if (!view || !editor) return false;

    if (editor.isActive('link')) {
      return false;
    }

    const { from } = state.selection;
    const domAtPos = view.domAtPos(from).node;
    const nodeDOM = view.nodeDOM(from);
    const node = nodeDOM || domAtPos;

    return isTextSelected({ editor });
  };

  const toggleEditLinkPopover = () => {
    setEditLinkPopoverOpen(prev => {
      const newValue = !prev;
      // Close other popovers
      setHighlightPopoverOpen(false);
      setColorPopoverOpen(false);
      setMoreOptionsPopoverOpen(false);
      setIsOpenAIDropdown(false)
      setIsOpenContentTypePicker(false)
      setIsOpenFontSizePicker(false)
      return newValue;
    });
  };

  const toggleHighlightPopover = () => {
    setHighlightPopoverOpen(prev => {
      const newValue = !prev;
      // Close other popovers
      setEditLinkPopoverOpen(false);
      setColorPopoverOpen(false);
      setMoreOptionsPopoverOpen(false);
      setIsOpenAIDropdown(false)
      setIsOpenContentTypePicker(false)
      setIsOpenFontSizePicker(false)
      return newValue;
    });
  };

  const toggleColorPopover = () => {
    setColorPopoverOpen(prev => {
      const newValue = !prev;
      // Close other popovers
      setEditLinkPopoverOpen(false);
      setHighlightPopoverOpen(false);
      setMoreOptionsPopoverOpen(false);
      setIsOpenAIDropdown(false)
      setIsOpenContentTypePicker(false)
      setIsOpenFontSizePicker(false)
      return newValue;
    });
  };

  const toggleMoreOptionsPopover = () => {
    setMoreOptionsPopoverOpen(prev => {
      const newValue = !prev;
      // Close other popovers
      setEditLinkPopoverOpen(false);
      setHighlightPopoverOpen(false);
      setColorPopoverOpen(false);
      setIsOpenAIDropdown(false)
      setIsOpenContentTypePicker(false)
      setIsOpenFontSizePicker(false)
      return newValue;
    });
  }

  return (
    <BubbleMenu
      tippyOptions={{
        popperOptions: { placement: 'top-start' },
        onHidden: () => {
          setEditLinkPopoverOpen(false)
          setHighlightPopoverOpen(false)
          setColorPopoverOpen(false)
          setMoreOptionsPopoverOpen(false)
          setIsOpenAIDropdown(false)
          setIsOpenContentTypePicker(false)
          setIsOpenFontSizePicker(false)
        },
     }}
      editor={editor}
      pluginKey="textMenu"
      updateDelay={100}
      shouldShow={shouldShow}
    >
      <div className="bubble-menu">
        <ContentTypePicker options={blockOptions} isOpen={isOpenContentTypePicker} setIsOpen={setIsOpenContentTypePicker} />
        <FontSizePicker onChange={commands.onSetFontSize} value={editor.getAttributes('textStyle')?.fontSize || undefined} isOpen={isOpenFontSizePicker} setIsOpen={setIsOpenFontSizePicker} />

        <div className='divider'></div>

        <button className={`toolbar-button ${editor.isActive('bold') && 'active'}`} onClick={commands.onBold}>
          <div dangerouslySetInnerHTML={{ __html: icons['Bold'] }} />
        </button>
        <button className={`toolbar-button ${editor.isActive('italic') && 'active'}`} onClick={commands.onItalic}>
          <div dangerouslySetInnerHTML={{ __html: icons['Italic'] }} />
        </button>
        <button className={`toolbar-button ${editor.isActive('underline') && 'active'}`} onClick={commands.onUnderline}>
          <div dangerouslySetInnerHTML={{ __html: icons['Underline'] }} />
        </button>
        <button className={`toolbar-button ${editor.isActive('strike') && 'active'}`} onClick={commands.onStrike}>
          <div dangerouslySetInnerHTML={{ __html: icons['Strikethrough'] }} />
        </button>
        <button className={`toolbar-button ${editor.isActive('code') && 'active'}`} onClick={commands.onCode}>
          <div dangerouslySetInnerHTML={{ __html: icons['Code'] }} />
        </button>
        <button className={`toolbar-button`} onClick={commands.onCodeBlock}>
          <div dangerouslySetInnerHTML={{ __html: icons['Code2'] }} />
        </button>

        <EditLinkPopover onSetLink={commands.onLink} editLinkPopoverOpen={editLinkPopoverOpen} setEditLinkPopoverOpen={toggleEditLinkPopover} />

        {/* Highlight Popover */}
        <div className="relative">
          <button className={`toolbar-button ${editor.getAttributes('highlight')?.color && 'active'}`} onClick={toggleHighlightPopover}>
            <div dangerouslySetInnerHTML={{ __html: icons['Highlighter'] }} />
          </button>
          {highlightPopoverOpen && (
            <div className="colorpicker-wrapper">
              <ColorPicker
                color={editor.getAttributes('highlight')?.color}
                onChange={commands.onChangeHighlight}
                onClear={commands.onClearHighlight}
              />
            </div>
          )}
        </div>

        {/* Text Color Popover */}
        <div className="relative">
          <button className={`toolbar-button ${editor.getAttributes('textStyle')?.color && 'active'}`} onClick={toggleColorPopover}>
            <div dangerouslySetInnerHTML={{ __html: icons['Palette'] }} />
          </button>
          {colorPopoverOpen && (
            <div className="colorpicker-wrapper">
              <ColorPicker
                color={editor.getAttributes('textStyle')?.color}
                onChange={commands.onChangeColor}
                onClear={commands.onClearColor}
              />
            </div>
          )}
        </div>

        {/* More Options Popover */}
        <div className="relative">
          <button className={`toolbar-button`} onClick={toggleMoreOptionsPopover}>
            <div dangerouslySetInnerHTML={{ __html: icons['MoreVertical'] }} />
          </button>
          {moreOptionsPopoverOpen && (
            <div className="moreoptions-wrapper">
              <button className={`toolbar-button ${editor.isActive('subscript') && 'active'}`} onClick={commands.onSubscript}>
                <div dangerouslySetInnerHTML={{ __html: icons['Subscript'] }} />
              </button>
              <button className={`toolbar-button ${editor.isActive('superscript') && 'active'}`} onClick={commands.onSuperscript}>
                <div dangerouslySetInnerHTML={{ __html: icons['Superscript'] }} />
              </button>
              <div className='divider'></div>
              <button className={`toolbar-button ${editor.isActive({ textAlign: 'left' }) && 'active'}`} onClick={commands.onAlignLeft}>
                <div dangerouslySetInnerHTML={{ __html: icons['AlignLeft'] }} />
              </button>
              <button className={`toolbar-button ${editor.isActive({ textAlign: 'center' }) && 'active'}`} onClick={commands.onAlignCenter}>
                <div dangerouslySetInnerHTML={{ __html: icons['AlignCenter'] }} />
              </button>
              <button className={`toolbar-button ${editor.isActive({ textAlign: 'right' }) && 'active'}`} onClick={commands.onAlignRight}>
                <div dangerouslySetInnerHTML={{ __html: icons['AlignRight'] }} />
              </button>
              <button className={`toolbar-button ${editor.isActive({ textAlign: 'justify' }) && 'active'}`} onClick={commands.onAlignJustify}>
                <div dangerouslySetInnerHTML={{ __html: icons['AlignJustify'] }} />
              </button>
            </div>
          )}
        </div>

      </div>
    </BubbleMenu>
  );
};

export default TextMenu;
