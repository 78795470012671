import React, { useState, useEffect, useCallback } from 'react';
import { BubbleMenu } from '@tiptap/react';

const FileBubbleMenu = ({ editor, appendTo }) => {
    const shouldShow = useCallback(() => {
        return editor.isActive('fileBlock') && editor.isFocused;
    }, [editor]);

    const deleteNode = () => {
        editor.chain().setMeta('fileBlock', true).deleteSelection().run();
    };

    return (
        <BubbleMenu
            editor={editor}
            shouldShow={shouldShow}
            updateDelay={0}
            tippyOptions={{
                zIndex: 99,
                popperOptions: {
                    modifiers: [{ name: 'flip', enabled: false }],
                },
                appendTo: () => appendTo?.current,
            }}
        >
            <div className="bubble-toggle-menu button-box image-bubble-menu file-bubble-menu">
                <div className='image-bubble-block file-bubble'>
                    <button onClick={deleteNode} className="full-display-button">
                        <svg width="20" height="20" stroke="currentColor" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.33333 3.4974V3.03073C9.33333 2.37733 9.33333 2.05064 9.20617 1.80107C9.09432 1.58155 8.91584 1.40307 8.69632 1.29122C8.44676 1.16406 8.12006 1.16406 7.46667 1.16406H6.53333C5.87994 1.16406 5.55324 1.16406 5.30368 1.29122C5.08416 1.40307 4.90568 1.58155 4.79383 1.80107C4.66667 2.05064 4.66667 2.37733 4.66667 3.03073V3.4974M5.83333 6.70573V9.6224M8.16667 6.70573V9.6224M1.75 3.4974H12.25M11.0833 3.4974V10.0307C11.0833 11.0108 11.0833 11.5009 10.8926 11.8752C10.7248 12.2045 10.4571 12.4722 10.1278 12.64C9.75347 12.8307 9.26342 12.8307 8.28333 12.8307H5.71667C4.73657 12.8307 4.24653 12.8307 3.87218 12.64C3.5429 12.4722 3.27518 12.2045 3.10741 11.8752C2.91667 11.5009 2.91667 11.0108 2.91667 10.0307V3.4974" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                </div>
            </div>
        </BubbleMenu>
    );
};

export default FileBubbleMenu;
