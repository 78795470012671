import { Node, ReactNodeViewRenderer } from '@tiptap/react';
import BookmarkInsertNodeView from './BookmarkInsertComponent';

export const Bookmark = Node.create({
  name: 'bookmark',

  isolating: true,
  defining: true,
  group: 'block',
  draggable: true,
  selectable: true,
  inline: false,

  addAttributes() {
    return {
      url: {
        default: '',
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML() {
    return ['div', { 'data-type': this.name, class: 'bookmark-block' }]
  },

  addCommands() {
    return {
      setBookmark:
        () =>
        ({ commands }) =>
          commands.insertContent(`<div data-type="${this.name}"></div>`),
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(BookmarkInsertNodeView);
  },
});
