
import { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion'
import { Link, useNavigate } from 'react-router-dom';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import axios from 'axios';
import Cookies from 'js-cookie';

import AddNewDropdown from './AddNewDropdown';
import '.././App.css';

/* IMAGES */
import logo from '../../src/images/logo.png'
import plus from '.././images/plus-icon.svg'
import sideLogout from '.././images/icon-logout.svg'
import avatar from '.././images/profile-user.svg'
import giftIcon from ".././images/gift-icon.svg";
import sendIcon from ".././images/send-icon.svg";
import down from ".././images/Settings/down.svg";
import iconLock from ".././images/locked.svg"

import Home from '../../src/images/sidebar/icon-home.svg';
import Profile from '../../src/images/sidebar/icon-myprofile.svg';
import Pages from '../../src/images/sidebar/icon-pages.svg';
import Publication from '../../src/images/sidebar/icon-publications.svg';
import Newsletters from '../../src/images/sidebar/icon-newsletters.svg';
import Category from '../../src/images/sidebar/icon-tags.svg';
import Subscribers from '../../src/images/sidebar/icon-subscribers.svg';
import Media from '../../src/images/sidebar/icon-media.svg';
import Analiytics from '../../src/images/sidebar/icon-analytics.svg';
import ProfileAssistant from '../../src/images/sidebar/icon-profileassistant.png';
import settings from '../../src/images/sidebar/icon-settings.svg'
import ChangePlan from './Settings/ChangePlan';
import link from ".././images/link.svg"
import MenuDropdown from './MenuDropdown';
import { InvitationModel } from './Modal/InvitationModel';
import { ChangePlanSuccessModel } from './Modal/ChangePlanSuccessModel';
import Notification from './Notification';
import NotificationMessage from './NotificationMessage';


function Menu(props) {
    const navigate = useNavigate();
    const ref = useRef();
    const refMobile = useRef();
    const hamburgerRef = useRef();
    const stickymenu = useRef();
    let menuRef = useRef();
    const addRef = useRef()

    const [addTabActive, setAddTabActive] = useState(false)
    const [userInfoActive, setUserInfoActive] = useState(false)
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [profileSlug, setProfileSlug] = useState("");
    const [mobileMenuActive, setMobileMenuActive] = useState(false)
    const [isLoading, setIsLoading] = useState(false); 
    const [invitateTo, setInvitateTo] = useState('');
    const [invitationError, setInvitationError] = useState(false);
    const [invitationMessage, setInvitationMessage] = useState('');
    const [invitationNotification, setInvitationNotification] = useState(false);
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
    const [isChangePlanOpen, setIsChangePlanOpen] = useState(false);

    const [avatarImage, setAvatarImage] = useState(avatar);
    const [showStickyMenu, setShowStickyMenu] =useState(false);
    const [mobileStickyMenu, setMobileStickyMenu] = useState(false)
    const [userMetaData, setUserMetaData] = useState({})
    const [isOpenInvitationPopup, setOpenInvitationPopup] = useState(false)
    const [openNotify, setOpenNotify] = useState(false);
    const [openSuccessPlanPopup, setOpenSuccessPlanPopup] = useState(false)
    const [sendInvitation, setSendInvitation] = useState(false)
    mobileMenuActive ? disableBodyScroll(document) : enableBodyScroll(document)

    useEffect(() => {
        if (userInfoActive === true) {
            setMobileMenuActive(false)
        } else if (mobileMenuActive === true) {
            setUserInfoActive(false)
        }
    }, [mobileMenuActive, userInfoActive]);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            const classListToCheck = ['menuList', 'menuRight', 'menuListItem', 'logo-hamburger-menu', 'sidebar-text-box', 'user-action', 'container'];


            // if (addTabActive && ref.current && !ref.current.contains(e.target) ) {
            //     setAddTabActive(false);
            // }
            if (addTabActive && ref.current && !ref.current.contains(e.target) && classListToCheck.some(className => e.target.classList.contains(className))) {
                setAddTabActive(false);
            }

            if ((userInfoActive) && ref.current && !ref.current.contains(e.target)) {
                // setAddTabActive(false);
                setUserInfoActive(false);
            }

            if ((addTabActive || userInfoActive) && refMobile.current && !refMobile.current.contains(e.target)) {
                setAddTabActive(false);
                setUserInfoActive(false);
            }

            // Close menu when we click outside
            if (mobileMenuActive === true && !hamburgerRef.current.contains(e.target) && !menuRef.current.contains(e.target)) {
                setMobileMenuActive(false);
                const myNav = document.getElementById("myNav");
                myNav.classList.remove("show-menu")
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [addTabActive, userInfoActive, mobileMenuActive]);


    const handleAddClass = (value) => {
        setMobileMenuActive(value)
        if (value === true) {
            const myNav = document.getElementById("myNav");
            myNav.classList.add("show-menu")
        }
        if (value === false) {
            const myNav = document.getElementById("myNav");
            myNav.classList.remove("show-menu")
        }
    }

    const [userData, setuserData] = useState({});

    const toConvertUppercase = (string) => {
        const words = string.split(" ");

        const capitalizedWords = words.map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1)
        );
        const capitalizedString = capitalizedWords.join(" ");
        return capitalizedString;
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/user/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
                )

                const fetchUserData = response?.data?.result?.user;
                const userData = response?.data?.result?.user;
                const userMeta = response?.data?.result?.userMeta;
                const userMediaData = response?.data?.result?.userMediaData;
                
                localStorage.setItem('userTimeZone', userMeta.timeZone);
                localStorage.setItem('userProfileSlug', userMeta.profileSlug);

                const firstName = toConvertUppercase(userData?.firstName);
                const lastName = toConvertUppercase(userData?.lastName);

                if(userMeta?.profileMedia?.cropedImage) {
                    setAvatarImage(userMediaData.profileMeida.cropedMedia.url)
                }

                setFirstName(firstName);
                setLastName(lastName);
                setProfileSlug(userMeta.profileSlug);
                setuserData(fetchUserData);
                setUserMetaData(userMeta)

            } catch (error) {
                console.log(error, "error");
            }
        }
        fetchData();
    }, [sendInvitation]);

    const token = localStorage.getItem("userinfo");

    useEffect(() => {
        if (!token) {
            navigate('/login');
        }
    }, []);


    const erorBlocks = document.querySelectorAll(".erorBlock");

    if (erorBlocks.length > 0) {
        const menuListItems = document.querySelectorAll(".menuListItem");
        menuListItems.forEach((menuListItem) => {
            menuListItem.classList.add("error");
        });
    }

    const handleLogout = () => {
        localStorage.removeItem("userinfo");
        localStorage.removeItem("isCompleted");
        localStorage.removeItem("isInvited");
        localStorage.removeItem("userProfileSlug");
        sessionStorage.removeItem("userId");
        window.location.href = '/login';
        Cookies.remove('user_id');  
    }

    const handleHamburgerClick = () => {
        props.toggleMenu();
        props.setHover(false);

        setAddTabActive(false); 
        setUserInfoActive(false);
        setMobileStickyMenu(false)
    };

    const handleAddNew = () => {
        if( props.menuActive ) {
            props.toggleMenu();
            props.setHover(false);
        }

        setAddTabActive(!addTabActive); 
        setUserInfoActive(false);
    }

    const handleMenuItemClick = () => {
        props.resetMenuActive();
    };

    const handleSubMenuClick = () => {
        props.resetMenuActive();
        setIsSubMenuOpen(!isSubMenuOpen);
    };

    const handleInvitateToChange = (event) => {
        setIsLoading(false);
        setInvitationError(false);
        setInvitationMessage('');
        setInvitateTo(event.target.value);
    }

    const handleInvitateToKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSendInvitation();
        }
    }

    const handleSendInvitation = async () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        setIsLoading(true);

        setInvitationError(false);
        setInvitationMessage('');

        if (!emailPattern.test(invitateTo)) {
            setInvitationError(true)
            setInvitationMessage('Please enter a valid email address.');
            setIsLoading(false);
            return;
        }

        const data = {
            email: invitateTo,
            name: `${firstName}  ${lastName}`,
            invitedFrom: JSON.parse(localStorage.getItem("userinfo"))?._id
        };

        await axios.put(`${process.env.REACT_APP_API_URL}/user/sendinvitation`, data)
            .then((response) => { 
                if( response.status === 200 ) {
                    setInvitationMessage(`<b>Invite Sent!</b> Sent to ${invitateTo}`);
                    setInvitationNotification(true);
                    setOpenInvitationPopup(false)
                    setSendInvitation(true)
                } else {
                    setInvitationError(true)
                    setInvitationMessage(response.data.message);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setInvitationError(true)
                setInvitationMessage(error.messager);
                setIsLoading(false);
            });
    }
    useEffect (()=>{
        const checkIfClickedOutside = (e) => {
            if (showStickyMenu && stickymenu.current && !stickymenu.current.contains(e.target)) {
                setShowStickyMenu(false);
            }
            
        }
        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };

    },[showStickyMenu])  
    useEffect (()=>{
        const checkIfClickedOutside = (e) => {
            
            if (addRef && addRef.current && !addRef.current.contains(e.target)) {
                setAddTabActive(false);
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };

    },[addTabActive])
    return (
        <>
            <div className="logo-hamburger-menu mobile-view">
                <Link className='logotype' to={'/'}><img src={logo} alt="Logotype" />
                    <span>&nbsp;Sark</span>
                </Link>
                <div className='mobile-menuRight'>
                    <div className="addNew" ref={addRef}>
                        <button className={(addTabActive === true) ? 'buttonActive' : ''} onClick={(e) => { handleAddNew() }}><img src={plus} alt="Icon" /><span>&nbsp;&nbsp;Add new</span></button>
                        {
                            (addTabActive === true) ? <AddNewDropdown setClose={setAddTabActive} /> : ''
                        } 
                    </div>
                    <Link className='websiteLink personalLink' to={`https://jourmal-next-front.vercel.app/${profileSlug}`} target="_blank">
                        <img src={link} alt="link" />
                        <span>Personal Website</span>
                    </Link>
                    <div className='dropDown' ref={stickymenu}>
                        <div className= "avatarVisible menuDropdown" onClick={()=>{setShowStickyMenu(!showStickyMenu)}}>
                            <div className='sidebar-user'>
                                <img className='avatar' src={avatarImage} alt={{firstName}} />
                            </div>
                            <img src={down} alt='down' />
                        </div>
                        {showStickyMenu && 
                            <MenuDropdown userData={userData} lastName={lastName} firstName={firstName} setShowStickyMenu={setShowStickyMenu} showStickyMenu={showStickyMenu} toggleMenu={props?.toggleMenu} planDetails={props?.billingDetails?.subscriptions} setShowUpgradeModel={setIsChangePlanOpen} setOpenInvitationPopup={setOpenInvitationPopup} setInvitateTo={setInvitateTo} setInvitationMessage={setInvitationMessage}/>
                        }
                    </div>
                    <button className='hamburger-menu' onClick={handleHamburgerClick} >
                        <span></span>
                    </button>
                </div> 
            </div>
            <nav id='myNav'>
                <div className="container menuWrapper">
                    <div className="logo-hamburger-menu desktop-view">
                        <Link className='logotype' to={'/'}><img src={logo} alt="Logotype" />
                            <span>&nbsp;Sark</span>
                        </Link>
                        <button className='hamburger-menu' onClick={handleHamburgerClick} >
                            <span></span>
                        </button>
                    </div>

                    {/* <div className="menuRight addNew" ref={ref}>
                        <button className={(addTabActive === true) ? 'buttonActive' : ''} onClick={(e) => { setAddTabActive(!addTabActive); setUserInfoActive(false) }}><img src={plus} alt="Icon" /> <span>&nbsp;&nbsp;Add new</span></button>
                        {
                            (addTabActive === true) ? <AddNewDropdown setClose={setAddTabActive} /> : ''
                        }
                    </div> */}
                    <div className='menu-wrapperBox'>
                        <ul className='menuList'>
                            <ul className='menuData' >
                                <li>
                                    <Link className={(props.activePage === 'Home') ? 'menuListItem menuItemActive' : 'menuListItem'} to={'/dashboard/home'} onClick={handleMenuItemClick}>
                                        <img src={Home} className="home-icon" alt="home-icon" />
                                        <span>Home</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link className={(props.activePage === '') ? 'menuListItem menuItemActive' : 'menuListItem'} to={`https://jourmal-next-front.vercel.app/${profileSlug}`} target="_blank" rel="noopener noreferrer" onClick={handleMenuItemClick}>
                                        <img src={Profile} alt="Icon" />
                                        <span>My Profile</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link className={props.activePage === 'Page' ? 'menuListItem menuItemActive' : 'menuListItem'} to={'/dashboard/pages'} onClick={handleMenuItemClick}>
                                        <img src={Pages} className="pages-icon" alt="pages-icon" />
                                        <span>Pages</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link className={(props.activePage === 'Publication') ? 'menuListItem menuItemActive' : 'menuListItem'} to={'/dashboard/publications'} onClick={handleMenuItemClick}>
                                        <img src={Publication} className="publication-icon" alt="publication-icon" />
                                        <span>Publications</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link className={(props.activePage === 'Newsletter') ? 'menuListItem menuItemActive' : 'menuListItem'} to={'/dashboard/newsletters'} onClick={handleMenuItemClick}>
                                        <img src={Newsletters} className="newsletters-icon" alt="newsletters-icon" />
                                        <span>Newsletters</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link className={(props.activePage === 'Categories') ? 'menuListItem menuItemActive' : 'menuListItem'} to={'/dashboard/categories'} onClick={handleMenuItemClick}>
                                        <img src={Category} className="category-icon" alt="categories-icon" />
                                        <span>Categories</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link className={(props.activePage === 'Subscribers') ? 'menuListItem menuItemActive' : 'menuListItem'} to={'/dashboard/subscribers'} onClick={handleMenuItemClick}>
                                        <img src={Subscribers} className="subscribers-icon" alt="subscribers-icon" />
                                        <span>Subscribers</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link className={(props.activePage === 'MediaGallery') ? 'menuListItem menuItemActive' : 'menuListItem'} to={'/dashboard/media-library'} onClick={handleMenuItemClick}>
                                        <img src={Media} className="media-icon" alt="media-icon" />
                                        <span>Media Library</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link className={(props.activePage === 'Analytics' || props.activePage == 'Contact-Analytics' || props.activePage == 'monetization-analytics') ? 'menuListItem menuItemActive' : 'menuListItem'} onClick={handleSubMenuClick}>
                                        <img src={Analiytics} className="analiytics-icon" alt="analiytics-icon" />
                                        <span>Analytics</span>
                                        <div className='analiytics-down-icon'>
                                            <img src={down} alt="analiytics-icon" />
                                        </div>
                                    </Link>
                                    {isSubMenuOpen && (
                                        <div className='analytics-submenu'>
                                            <ul className="submenu-inner">
                                            <li>
                                                <Link className={(props.activePage == 'Contact-Analytics')? 'active-submenu' : ''} to={'/dashboard/analytics'}>Content Analytics</Link>
                                            </li>
                                            <li>
                                                {(props?.billingDetails?.subscriptions?.plan === "free_plan") ? 
                                                    <>
                                                        <div className='overlayMenu' onClick={()=>{setIsChangePlanOpen(true); props.resetMenuActive();}}>
                                                            <div className='overlayLock overlayLock-menu overlayLock-submenu'>
                                                                <img className='submenu-lock'  src={iconLock} alt="lock-icon" />
                                                            </div>
                                                            <Link className={(props.activePage === 'monetization-analytics') ? '' : 'settingMenu'} >Monetization Analytics</Link>
                                                        </div>
                                                    
                                                    </>
                                                    :
                                                    <Link className={(props.activePage == 'monetization-analytics')? 'active-submenu ' : ''} to={'/dashboard/monetization-analytics'}>Monetization Analytics</Link>
                                                }
                                            </li>
                                        </ul>
                                        </div>
                                    )}
                                </li>
                                    {
                                        userMetaData?.planDetail?.plan === "personal_assistant_plan" && 
                                            <li>
                                                <Link className={(props.activePage === 'ProfileAssistant') ? 'menuListItem menuItemActive' : 'menuListItem'} to={'/dashboard/profile-assistant'} onClick={handleMenuItemClick}>
                                                    <img src={ProfileAssistant} className="analiytics-icon" alt="analiytics-icon" />
                                                    <span>Profile Assistant</span>
                                                </Link>
                                            </li>
                                    }
                                <li>
                                    <Link className={(props.activePage === 'Settings') ? 'menuListItem menuItemActive' : 'menuListItem'} to={'/dashboard/settings'} onClick={handleMenuItemClick}>
                                        <img src={settings} className="analiytics-icon" alt="analiytics-icon" />
                                        <span>Settings</span>
                                    </Link>
                                </li>
                            </ul>
                        </ul>

                        <div>
                            <div className='invite-box-user'>
                                <div className='invite-text-box'>
                                    <h4>Invite to Persone</h4>
                                    <h6>Who else would benefits from a personal website?</h6>
                                    <div className='invite-mail'>
                                        <img className="invite-mail-icon" src={giftIcon} alt="mail" />
                                        <input className="" type="text" placeholder="Type an email address..." value={invitateTo} onChange={handleInvitateToChange} onKeyPress={handleInvitateToKeyPress} />
                                        {! isLoading && 
                                            <img className="send-mail-icon" onClick={handleSendInvitation} src={sendIcon} alt="send mail" />
                                        }
                                        {isLoading && 
                                            <div className="ring-loader dark"></div>
                                        }
                                    </div>
                                    { invitationMessage && 
                                        <p className={invitationError ? 'error-message' : 'success-message'} dangerouslySetInnerHTML={{ __html: invitationMessage }} ></p>
                                    }
                                </div>
                            </div>

                            <div className='user-action' ref={ref}>
                                <div className="menuRight">
                                    <div className="userProfile">
                                        <div className={`avatarVisible ${userInfoActive === true ? 'active' : ''}`} >
                                            <div className='sidebar-user'>
                                                <img className='avatar' src={avatarImage} alt={{firstName}} />
                                            </div>
                                            <div className='sidebar-user-content'>
                                                <p className="sidebar-user-name"> {firstName}  {lastName} </p>
                                                <p className="sidebar-user-mail">{userData.email}</p>
                                            </div>
                                        </div>
                                        <div className="sidebar-logout desktop-view">
                                            <img className='icon-logout' src={sideLogout} alt="icon-logout" onClick={handleLogout} />
                                        </div>
                                        <div className="mobile-view">
                                            <img src={down} alt='down' onClick={()=>{setMobileStickyMenu(!mobileStickyMenu)}} />
                                            {mobileStickyMenu && 
                                                <MenuDropdown userData={userData} lastName={lastName} firstName={firstName} setShowStickyMenu={setShowStickyMenu} showStickyMenu={showStickyMenu} toggleMenu={props?.toggleMenu} planDetails={props?.billingDetails?.subscriptions} setShowUpgradeModel={setIsChangePlanOpen} setOpenInvitationPopup={setOpenInvitationPopup} setInvitateTo={setInvitateTo} setInvitationMessage={setInvitationMessage}/>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>                   
                    <motion.div
                        className='mobileMenu'
                        initial={{ opacity: 1, height: 0 }}
                        animate={{
                            opacity: 1,
                            height: (mobileMenuActive === true) ? "auto" : 0,
                        }}
                        transition={{ duration: 0.3 }}
                        ref={menuRef}
                    >
                        <div className="mobileMenuContent">
                            <ul className='mobileMenuLinks'>
                                <Link to={'/dashboard/home'} onClick={e => handleAddClass(false)}>Home</Link>
                                <Link to={'/dashboard/pages'} onClick={e => handleAddClass(false)}>Pages</Link>
                                <Link to={'/dashboard/publications'} onClick={e => handleAddClass(false)}>Publications</Link>
                                <Link to={'/dashboard/subscribers'} onClick={e => handleAddClass(false)}>Subscribers</Link>
                                <Link to={'/dashboard/media-gallery'} onClick={e => handleAddClass(false)}>Media Library</Link>
                                <Link to={'/dashboard/newsletters'} onClick={e => handleAddClass(false)}>Newsletter</Link>
                                <Link to={'/dashboard/categories'} onClick={e => handleAddClass(false)}>Categories</Link>
                                <Link to={'/dashboard/analytics'} onClick={e => handleAddClass(false)}>Analytics</Link>
                            </ul>
                        </div>
                    </motion.div>
                </div>
            </nav>
            {isChangePlanOpen && 
                <ChangePlan isChangePlanOpen={isChangePlanOpen} setIsChangePlanOpen={setIsChangePlanOpen}  planDetails={props?.billingDetails?.subscriptions} paymentMethods={props?.paymentMethods} isStipeConnected={props?.isStipeConnected || false} updateData={props?.updateData} isExpired={props?.isExpired} setOpenSuccessPlanPopup={setOpenSuccessPlanPopup} openSuccessPlanPopup={openSuccessPlanPopup}/>
            }
             { isOpenInvitationPopup && 
                <InvitationModel setOpenInvitationPopup={setOpenInvitationPopup} isOpenInvitationPopup={isOpenInvitationPopup} handleInvitateToChange={handleInvitateToChange} handleInvitateToKeyPress={handleInvitateToKeyPress} handleSendInvitation={handleSendInvitation} invitateTo={invitateTo} isLoading={isLoading} invitationError={invitationError} invitationMessage={invitationMessage} setIsLoading={setIsLoading} setInvitateTo={setInvitateTo} setInvitationError={setInvitationError} setInvitationMessage={setInvitationMessage} userMetaData={userMetaData}/>
             }
             { openSuccessPlanPopup && 
                <ChangePlanSuccessModel setOpenSuccessPlanPopup={setOpenSuccessPlanPopup} openSuccessPlanPopup={openSuccessPlanPopup} plan={props?.billingDetails?.subscriptions?.plan}/>
            }
            { invitationNotification && 
                <NotificationMessage 
                openNotify={invitationNotification} 
                setOpenNotify={setInvitationNotification} 
                type={'success'} 
                title={`Invitation sent to ${invitateTo}`} />
            
            }
        </>
    );
}

export default Menu;
