import { Node, ReactNodeViewRenderer } from '@tiptap/react';
import { NodeViewWrapper } from '@tiptap/react';
import { useState } from 'react';

const YouTubeNodeView = ({ editor, getPos }) => {
    const [videoUrl, setVideoUrl] = useState('');
    const [isValidUrl, setIsValidUrl] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMesssage] = useState("");

    const handleInputChange = (e) => {
        const trimmedValue = e.target.value.trim();
        setVideoUrl(trimmedValue);
		    setIsValidUrl(false);

      if (trimmedValue.length > 0) {
          const urlPattern = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/).+$/;
          const isUrlValid = urlPattern.test(trimmedValue);
          setIsValidUrl(isUrlValid);
      }
    };

    const handleKeyPress = (e) => {
      if(isValidUrl){
        if (e.key === 'Enter') {
          try{
            setIsLoading(true)
            if(videoUrl !== '') {
              const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
              const youtubePattern = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/).+$/;
              
                if(youtubePattern.test(videoUrl) && urlPattern.test(videoUrl)) {
                    editor.chain().focus().setYouTubeBlock({videourl: videoUrl,caption: '',}).run();
                    setIsLoading(false)
                    setErrorMesssage('')
                  } else {
                    setErrorMesssage('The URL is not valid')
                  }
              }
            } catch(error) {
              setIsLoading(false);
              setErrorMesssage("The URL is not valid")
              console.error('Failed to fetch URL data:', error);
            } finally {
              setIsLoading(false);
            }
          }
      } else {
        setErrorMesssage("The URL is not valid");
      }
    };

    return (
        <NodeViewWrapper>
            <div data-type='youtube' className={`youtube-node-wrapper`} data-drag-handle>
              <div className='youtube-urlInput'>
              {isLoading ? (
                <div className='linkbutton-loader loader-buttonHeight'>
                  <div className='ring-loader'></div>
                </div>
              ) : (
                <>
                  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10Z' fill='#FE0000'/>
                    <path d='M14.75 7.5625C14.625 7.125 14.3125 6.8125 13.875 6.6875C13.125 6.5 9.9375 6.5 9.9375 6.5C9.9375 6.5 6.8125 6.5 6 6.6875C5.5625 6.8125 5.25 7.125 5.125 7.5625C5 8.375 5 10 5 10C5 10 5 11.625 5.1875 12.4375C5.3125 12.875 5.625 13.1875 6.0625 13.3125C6.8125 13.5 10 13.5 10 13.5C10 13.5 13.125 13.5 13.9375 13.3125C14.375 13.1875 14.6875 12.875 14.8125 12.4375C15 11.625 15 10 15 10C15 10 15 8.375 14.75 7.5625ZM9 11.5V8.5L11.625 10L9 11.5Z' fill='white'/>
                  </svg>
                  <input className='urlInput' type='text' placeholder='Paste YouTube URL to add embedded content' value={videoUrl} onChange={handleInputChange} onKeyPress={(e) => { handleKeyPress(e) }} />
                  {
                    errorMessage && 
                    <p className="error-message" >{errorMessage}</p>
                  }
                  </>
              )}
              </div>
            </div>
        </NodeViewWrapper>
    )
};

export const YouTubeEmbeds = Node.create({
  name: 'youtube',

  isolating: true,
  defining: true,
  group: 'block',
  draggable: true,
  selectable: true,
  inline: false,


  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML() {
    return ['div', { 'data-type': this.name, class: 'youtube-block' }]
  },

  addCommands() {
    return {
      setYoutubeEmbeds:
        () =>
        ({ commands }) =>
          commands.insertContent(`<div data-type="${this.name}"></div>`),
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(YouTubeNodeView);
  },
});
