import { useState, useCallback } from 'react'
import icons from '../iconsList.json';

const FONT_SIZES = [
  { label: 'Smaller', value: '12px' },
  { label: 'Small', value: '14px' },
  { label: 'Medium', value: '' },
  { label: 'Large', value: '18px' },
  { label: 'Extra Large', value: '24px' },
]

const FontSizePicker = ({ onChange, value, isOpen, setIsOpen }) => {
  const currentValue = FONT_SIZES.find(size => size.value === value)
  const currentSizeLabel = currentValue?.label.split(' ')[0] || 'Medium'

  const selectSize = useCallback(size => {
    onChange(size)
    setIsOpen(false)
  }, [onChange])

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div style={{ position: 'relative' }}>
        <button className="toolbar-button" onClick={toggleDropdown}>
            {currentSizeLabel}
            <div dangerouslySetInnerHTML={{ __html: icons['ChevronDown'] }} />
        </button>   

        {isOpen && (
            <div className="content-type-menu">
            {FONT_SIZES.map(size => (
                <button
                key={`${size.label}_${size.value}`}
                className={`content-type-menu-item ${(value === size.value) && 'active'}`}
                onClick={() => selectSize(size.value)}
                >
                <span style={{ fontSize: size.value }}>{size.label}</span>
                </button>
            ))}
            </div>
        )}
    </div>
  )
}

export default FontSizePicker
