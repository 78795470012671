import { useMemo } from 'react';

import icons from '../iconsList.json';

// Type guard functions
const isOption = (option) => option.type === 'option';
const isCategory = (option) => option.type === 'category';

// ContentTypePicker component
const ContentTypePicker = ({ options,isOpen,setIsOpen }) => {

  // Find the currently active option
  const activeItem = useMemo(
    () => options.find(option => option.type === 'option' && option.isActive()),
    [options]
  );

  return (
    <div className="relative">
        <button
            onClick={() => setIsOpen(prev => !prev)}
            className="toolbar-button"
        >
            <div dangerouslySetInnerHTML={{ __html: icons[(activeItem?.type === 'option' && activeItem.icon) || 'Pilcrow'] }} />
            <div dangerouslySetInnerHTML={{ __html: icons['ChevronDown'] }} />
        </button>

      {/* Dropdown content */}
      {isOpen && (
        <div className="content-type-menu">
          {options.map(option => {
            if (isOption(option)) {
              return (
                <button
                key={option.id}
                className={`content-type-menu-item ${option.isActive() && 'active'}`}
                onClick={() => {
                    option.onClick();
                    setIsOpen(false); // Close dropdown after selection
                }} 
                >
                    <div dangerouslySetInnerHTML={{ __html: icons[option.icon] }} />
                    {option.label}
                </button>
              );
            } else if (isCategory(option)) {
              return (
                <div className="content-type-menu-title" key={option.id}>{option.label}</div>
              );
            }
          })}
        </div>
      )}
    </div>
  );
};

export default ContentTypePicker;
