import React, { useEffect, useState, useRef } from "react";
import SortableList, { SortableItem } from 'react-easy-sort'
import { arrayMoveImmutable } from 'array-move'
import axios from "axios";

import Loader from "../../Components/Loader/Loader";
import EditMediaPopup from "../../Components/EditMediaPopup"

import fileImageIcon from "../../images/file-image-icon.svg";
import closeIcon from "../../images/x-close.svg";

function ImagesPublication(props) {

    const { dbPageTitle, editorContent, validateTitle, validateEditorContent, setValidateTitle, setValidateEditorContent, setdbPageTitle, setEditorContent, setShowUploadMediaPopup, setUploadMediaPopupFor, setUploadMediaPopupTab, selectedImageFileData, setSelectedImageFileData } = props;

    const pageTitleRef = useRef(null);
    const pageContentRef = useRef(null);

    const [isDragging, setIsDragging] = useState(false);

    const [validateImage, setValidateImage] = useState(false);
    const [validateFileImage, setValidateFileImage] = useState(false);
    const [isImageDragging, setIsImageDragging] = useState(false);

    const [selectedImageGallery, setSelectedImageGallery] = useState([]);
    const [imageGalleryloadingStates, setimageGalleryLoadingStates] = useState([]);
    const [loadingFiles, setloadingFiles] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [editedItem, setEditedItem] = useState('');

    useEffect(() => {
        const handleResize = () => {
            if( pageTitleRef.current ) {
                pageTitleRef.current.style.height = '40px';
                pageTitleRef.current.style.height = pageTitleRef.current.scrollHeight + 2 + 'px';
            }
            
            if( pageContentRef.current ) {
                pageContentRef.current.style.height = '130px';

                var extraSpace = 2;

                if (pageContentRef.current.scrollHeight > 130) {
                    extraSpace = 20;
                }

                pageContentRef.current.style.height = pageContentRef.current.scrollHeight + extraSpace + 'px';
            }
        };

        handleResize();

        // Attach event listener for window resize
        window.addEventListener('resize', handleResize);


        // Cleanup: Remove event listener on component unmount
        return () => {
        window.removeEventListener('resize', handleResize);
        };

    }, [dbPageTitle, editorContent]);

    useEffect(() => {
        if(selectedImageFileData && selectedImageFileData.length > 0) {
            setSelectedImageGallery((prevSelectedImageGallery) => {
                // Filter out duplicates by checking if itemId already exists in the gallery
                const newItems = selectedImageFileData.filter(
                    (item) => !prevSelectedImageGallery.some((galleryItem) => galleryItem._id === item._id)
                );
    
                // Add new items to the beginning of the gallery
                return [...newItems, ...prevSelectedImageGallery];
            });
        }

    }, [selectedImageFileData]);

    const handlePageTitleInput = (e) => {
        e.target.style.height = '40px';
        e.target.style.height = e.target.scrollHeight + 2 + 'px';

        setdbPageTitle(e.target.value);
        if (e.target.value.trim().length > 0) {
            setValidateTitle(false);
        }
    }

    const handlePageContentcInput = (e) => {
        e.target.style.height = '130px';

        var extraSpace = 2;

        if (e.target.scrollHeight > 130) {
            extraSpace = 20;
        }

        e.target.style.height = e.target.scrollHeight + extraSpace + 'px';

        setEditorContent(e.target.value);
        if (e.target.value.trim().length > 0) {
            setValidateEditorContent(false);
        }
    }


    const handleFileDragOver = (e) => {
        e.preventDefault();
        if (!isDragging) {
            setIsImageDragging(true);
        }
    }

    const handleFileDragLeave = (e) => {
        e.preventDefault();
        setIsImageDragging(false);
    };

    const onSortEnd = (oldIndex, newIndex) => {
        // setSelectedImageFileData(arrayMoveImmutable(selectedImageFileData, oldIndex, newIndex));

        setSelectedImageFileData((imgObj) => arrayMoveImmutable(imgObj, oldIndex, newIndex));
      };

    const handleGalleryImageDrop = async (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        const maxSizeInBytes = 128 * 1024 * 1024; // 128MB
        const allowedFileTypes = ['image/jpeg', 'image/png', 'image/jpg'];

        let uploadedMediaId = 1;

        for (const file of files) {

            if (!allowedFileTypes.includes(file.type)) {
                console.log(`File ${file.name} is not an allowed type. Only JPG, JPEG, and PNG are allowed.`);
                continue; // Skip this file
            }

            if (file.size > maxSizeInBytes) {
                console.log(`File ${file.name} exceeds the maximum size of 128MB.`);
                continue; // Skip this file
            }

            const formData = new FormData();
            formData.append('files', file); // Append each file individually

            const uploadMedia = {
                _id: uploadedMediaId,
                name: file.name,
                description: '',
                alt: file.name,
                type: file.type,
                size: file.size,
                object_id: "",
                key: "",
                url: "",
                createdAt: "",
                isLoading: true
            }

            uploadedMediaId = uploadedMediaId + 1;

            setSelectedImageFileData((prevMedia) => [uploadMedia, ...prevMedia]);

            try {
                const res = await axios.post(
                    `${process.env.REACT_APP_API_URL}/user/uploadMedia/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
                // Update the uploadMedia object with the response data
                setSelectedImageFileData((prevMedia) =>
                    prevMedia.map((media) =>
                        media._id === uploadMedia._id ? { ...media, ...res.data.result[0], isLoading: false } : media
                    )
                );
            } catch (error) {
                console.log(error);
            }

            setIsImageDragging(false);
        }
    };

    const removeGalleryImage = (id) => {
        const updatedData = selectedImageFileData.filter(item => item._id !== id);
        setSelectedImageFileData(updatedData);
    };

    const handleEditClick = (item) => {
        setShowPopup(true)
        setEditedItem(item);
    };

    return (
        <>
            <div className="newpageLeftRow textInfoBlock">
                <h2>
                    Upload Images
                </h2>
                <p>
                    Upload images for your visitors to view directly on your web profile. For those seeking to showcase a collection, we also support the creation of a gallery, where multiple images can be uploaded to form a cohesive visual narrative.
                </p>
                <p>
                    We want to ensure that your audience enjoys a seamless, in-browser viewing experience. This not only keeps them engaged but also guarantees that your images are displayed in the exact manner you envisaged. Whether it's a single snapshot or an array of photographs, your work will be presented in its best light.
                </p>
                <div className="newpageLeftRow newpageCard linkDataBox newpagefile">
                    <div className="linkData">
                        <p className="label" >Publication title</p>
                        <textarea id="linkDataTitle" className="linkDataTitle" placeholder="Type title..." ref={pageTitleRef} onChange={(e) => handlePageTitleInput(e)} value={dbPageTitle}></textarea>
                        {validateTitle && (
                            <p className="validateError">The field is not filled</p>
                        )}
                        <p className="label">Description</p>
                        <textarea id="linkDataDiscripation" placeholder="Write a short description about the file..." className="linkDataDiscripation" ref={pageContentRef} onChange={(e) => handlePageContentcInput(e)} value={editorContent}></textarea>
                        {validateEditorContent && (
                            <p className="validateError">The field is not filled</p>
                        )}
                    </div>
                </div>
            </div>
            <div className={`newpageLeftRow textBlock ulpoadImageBlock ${loadingFiles ? 'uploadRunning' : ''}`}>
                <div className={isImageDragging ? `draging fileUploadBox` : `fileUploadBox`}
                    onDragOver={handleFileDragOver}
                    onDragLeave={handleFileDragLeave}
                    onDrop={handleGalleryImageDrop}
                >
                    <div
                        className="uploadFileBlock"
                        onClick={() =>{
                            setShowUploadMediaPopup(true);
                            setUploadMediaPopupFor('images-file');
                            setUploadMediaPopupTab('upload');
                        }}
                    >
                        <div className="featureImgMiddleContent">
                            <img className="fileUploadCover_Img" src={fileImageIcon} alt="FeaturedImage" />
                            <p className="newPageImageUploadText">
                                <span>Upload</span> or <span onClick={(e) => {e.stopPropagation(); setShowUploadMediaPopup(true); setUploadMediaPopupFor('images-file'); setUploadMediaPopupTab('media');}}>Open Image Library</span>
                                <br />
                                JPG, PNG (max. 128MB)
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {validateImage === true && (
                <p className="validateError">Some selected files have invalid type or size. Please choose valid images (jpg, png) files within 128MB.</p>
            )}
            {validateFileImage === true && (
                <p className="validateError">The field is not filled</p>
            )}

            <SortableList onSortEnd={onSortEnd} className={`uploadGrid media-grid-layout ${loadingFiles ? 'uploadRunning' : ''}`} draggedItemClassName="dragged">                
                { selectedImageFileData && selectedImageFileData.map((media) => (
                    <SortableItem key={media._id}>                        
                        <div className='uploadItem' >
                            <div className="uploadCard">
                                {media?.isLoading ? (
                                    <div className="uploadPicture">
                                        <Loader />
                                    </div>
                                ) : 
                                    <div className="uploadPicture picture">
                                        <div className="uploadThumbnail">
                                            <div className="uploadPictureCenter">
                                                <img src={media.url} alt={media.name} />
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="uploadContent">
                                {! media?.isLoading && (
                                    <>
                                        <img className="uploadClose" src={closeIcon} alt="icon-close" onClick={() => removeGalleryImage(media._id)} />
                                        <p className="uploadDetails" onClick={() => handleEditClick(media)}>Details</p>
                                    </>
                                )}
                                </div>
                            </div>
                        </div>
                    </SortableItem>
                ))}
            </SortableList>
            {showPopup &&
                <EditMediaPopup setShowPopup={setShowPopup} item={editedItem} userMedia={selectedImageFileData} setUserMedia={setSelectedImageFileData} />
            }
        </>
    )
}

export default ImagesPublication;