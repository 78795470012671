import { Node, ReactNodeViewRenderer } from '@tiptap/react';
import { NodeViewWrapper } from '@tiptap/react';
import { useState } from 'react';

const VimeoNodeView = ({ node, editor, getPos }) => {
    const [videoUrl, setVideoUrl] = useState('');
    const [isValidUrl, setIsValidUrl] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMesssage] = useState("");

    const handleInputChange = (e) => {
        const trimmedValue = e.target.value.trim();
        setVideoUrl(trimmedValue);
        setIsValidUrl(false);

        if (trimmedValue.length > 0) {
            const vimeoPattern = /(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/[^/]+\/videos\/|)(\d+)(?:\/)?(?:\?.*)?$/;
            const isUrlValid = vimeoPattern.test(trimmedValue);
            setIsValidUrl(isUrlValid);
        }
    };

    const handleKeyPress = (e) => {
        if (isValidUrl) {
            if (e.key === 'Enter') {
                try {
                    if (videoUrl !== '') {
                        const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
                        const vimeoPattern = /(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/[^/]+\/videos\/|)(\d+)(?:\/)?(?:\?.*)?$/;

                        if (vimeoPattern.test(videoUrl) && urlPattern.test(videoUrl)) {
                            setIsValidUrl(true);

                            const currentNodePos = getPos();
                            // editor.chain().setMeta('hideDragHandle', true).setNodeSelection(currentNodePos).deleteSelection().run()

                            editor.chain().focus().setVimeoBlock({ videourl: videoUrl, caption: '' }).run();
                            setIsLoading(false);
                            setErrorMesssage('');
                        } else {
                            setErrorMesssage('The URL is not valid');
                        }
                    }
                } catch (error) {
                    setIsLoading(false);
                    setErrorMesssage("The URL is not valid");
                    console.error('Failed to fetch URL data:', error);
                } finally {
                    setIsLoading(false);
                }
            }
        } else {
            setErrorMesssage("The URL is not valid");
        }
    };

    return (
        <NodeViewWrapper>
            <div data-type='vimeo' className='vimeo-node-wrapper' data-drag-handle>
                <div className='vimeo-urlInput'>
                    {isLoading ? (
                        <div className='linkbutton-loader loader-buttonHeight'>
                            <div className='ring-loader'></div>
                        </div>
                    ) : (
                        <>
                            <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path d='M0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10Z' fill='#1AB7EA' />
                                <path d='M5 7.99651L5.4375 8.55901C5.4375 8.55901 6.25 7.87151 6.5625 8.24651C6.8125 8.55901 7.9375 12.559 8.25 13.309C8.5625 13.934 9.4375 14.809 10.375 14.184C11.3125 13.559 14.4375 10.934 15 7.74651C15.5625 4.62151 11.25 5.24651 10.8125 7.99651C11.875 7.30901 12.5 8.24651 11.875 9.37151C11.3125 10.434 10.75 11.184 10.5 11.184C10.25 11.184 10 10.434 9.6875 9.18401C9.375 7.80901 9.375 5.43401 8 5.74651C6.6875 5.99651 5 7.99651 5 7.99651Z' fill='white' />
                            </svg>
                            <input
                                className='urlInput'
                                type='text'
                                placeholder='Paste Vimeo URL to add embedded content'
                                value={videoUrl}
                                onChange={handleInputChange}
                                onKeyPress={handleKeyPress}
                            />
                            {errorMessage && 
                                <p className="error-message">{errorMessage}</p>
                            }
                        </>
                    )}
                </div>
            </div>
        </NodeViewWrapper>
    )
}

export const VimeoEmbeds = Node.create({
    name: 'vimeo',

    isolating: true,
    defining: true,
    group: 'block',
    draggable: true,
    selectable: true,
    inline: false,

    parseHTML() {
        return [
            {
                tag: `div[data-type="${this.name}"]`,
            },
        ];
    },

    renderHTML() {
        return ['div', { 'data-type': this.name, class: 'vimeo-block' }];
    },

    addCommands() {
        return {
            setVimeoEmbeds:
                () =>
                    ({ commands }) =>
                        commands.insertContent(`<div data-type="${this.name}"></div>`),
        }
    },

    addNodeView() {
        return ReactNodeViewRenderer(VimeoNodeView);
    },
});
